import * as Yup from "yup";

export const inviteClientSchema = Yup.object({
  FirstName: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter your first name"),
  LastName: Yup.string().min(2).max(25).required("Please enter your last name"),
  CompanyName: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter your company name"),
  Email: Yup.string().email().required("Please enter your email"),
  Role: Yup.string().min(2).max(25).required("Please enter your role"),
});
