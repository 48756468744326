import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CandlestickChartOutlinedIcon from "@mui/icons-material/CandlestickChartOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import LocalCafeOutlinedIcon from "@mui/icons-material/LocalCafeOutlined";
import MoneyOffOutlinedIcon from "@mui/icons-material/MoneyOffOutlined";
import {
  Avatar,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Moment from "moment";
import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import TextSkeleton from "../../shared/components/Skeletons/TextSkeleton";
import ApprovalStages from "./ApprovalStages";
import { getApporovalDetails } from "./Approvals.helper";

type ApprovalDetailsProps = {};

export const leaveSummaryIcons = (leaveType) => {
  let leaveIcon: any = "";
  switch (leaveType) {
    case "Causal Leave":
      leaveIcon = (
        <LocalCafeOutlinedIcon sx={{ color: "#fff", height: 18, width: 18 }} />
      );
      break;
    case "Earned Leave":
      leaveIcon = (
        <EventAvailableOutlinedIcon
          sx={{ color: "#fff", height: 18, width: 18 }}
        />
      );
      break;
    case "Optional Leave":
      leaveIcon = (
        <EventAvailableOutlinedIcon
          sx={{ color: "#fff", height: 18, width: 18 }}
        />
      );
      break;
    case "Sick Leave":
      leaveIcon = (
        <CandlestickChartOutlinedIcon
          sx={{ color: "#fff", height: 18, width: 18 }}
        />
      );
      break;
    case "Leave Without Pay":
      leaveIcon = (
        <MoneyOffOutlinedIcon sx={{ color: "#fff", height: 18, width: 18 }} />
      );
      break;
    case "Maternity Leave":
      leaveIcon = (
        <EventNoteOutlinedIcon sx={{ color: "#fff", height: 18, width: 18 }} />
      );
      break;
    case "Bereavement Leave":
      leaveIcon = (
        <CandlestickChartOutlinedIcon
          sx={{ color: "#fff", height: 18, width: 18 }}
        />
      );
      break;
    default:
      leaveIcon = (
        <LocalCafeOutlinedIcon sx={{ color: "#fff", height: 18, width: 18 }} />
      );
  }
  return leaveIcon;
};

const ApprovalDetails: React.FunctionComponent<ApprovalDetailsProps> = (
  props,
) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const isMobile = useMediaQuery(`(max-width: 760px)`);

  useEffect(() => {
    getApporovalDetails(dispatch, { id: id });
  }, [dispatch, id]);

  const {
    isApprovalDetailLoading,
    approvalDetail,
    approvalDetailError,
    leavesSummary,
  } = useSelector(
    //@ts-ignore
    (state) => state.approval,
  );

  const redirectBack = () => {
    if (location?.state?.filterItems !== undefined) {
      navigate("/leave", {
        state: { filterItems: location?.state?.filterItems },
      });
    } else {
      navigate("/leave");
    }
  };

  return (
    <>
      <Grid container component="main">
        <Container maxWidth="xl">
          <Box mt={1} mb={1} sx={{ backgroundColor: "#CCD5FC" }}>
            <Grid container spacing={2} alignItems="center">
              {isMobile ? (
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", p: 2 }}
                    alignItems={"center"}
                  >
                    <a onClick={redirectBack} style={{ color: "#000" }}>
                      <ArrowBackIosIcon sx={{ ml: 2, mr: 1 }} />
                    </a>
                    <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/1.jpg"
                      sx={{
                        display: "inline-flex",
                        mx: 1,
                        width: 32,
                        height: 32,
                      }}
                    />
                    <div style={{ marginLeft: 10 }}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {approvalDetail?.employeeName}
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: "normal" }}>
                        {`${Moment(approvalDetail?.from, "DD/MM/YYYY").format(
                          "ll (ddd)",
                        )} to ${Moment(approvalDetail?.to, "DD/MM/YYYY").format(
                          "ll (ddd)",
                        )}`}
                      </Typography>
                    </div>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    sx={{ display: "flex", p: 2 }}
                    alignItems={"center"}
                  >
                    <a onClick={redirectBack} style={{ color: "#000" }}>
                      <ArrowBackIosIcon sx={{ ml: 2, mr: 1 }} />
                    </a>
                    {isApprovalDetailLoading ? (
                      <Grid item xs={6}>
                        <TextSkeleton />
                      </Grid>
                    ) : (
                      <Typography variant="h5" sx={{ fontWeight: "normal" }}>
                        {`${approvalDetail?.approvalStatus} leave request:`}
                        <Avatar
                          alt="Remy Sharp"
                          src={approvalDetail?.employeeProfilePhoto}
                          sx={{ display: "inline-flex", mx: 1 }}
                        />
                        {approvalDetail?.employeeName}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    sx={{ display: "flex", p: 2 }}
                    justifyContent="flex-end"
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "normal", mr: 2 }}
                    >
                      {`${Moment(approvalDetail?.from, "DD/MM/YYYY").format(
                        "ll (ddd)",
                      )} to ${Moment(approvalDetail?.to, "DD/MM/YYYY").format(
                        "ll (ddd)",
                      )}`}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>

          <Box py={2} mt={1} mb={1}>
            <Card>
              <CardContent sx={{ ml: 2, p: 2 }} style={{ paddingBottom: 16 }}>
                <ApprovalStages approvalDetail={approvalDetail} />
              </CardContent>
            </Card>
          </Box>

          <Grid container spacing={2} mb={2}>
            <Grid item xs={12}>
              <Card sx={{ mb: 1 }}>
                <CardContent>
                  <Grid container spacing={2}>
                    {!isMobile ? (
                      <>
                        <Grid item xs={3}>
                          <Typography variant="subtitle1">
                            <strong>Employee Id</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="subtitle1">
                            <strong>Date</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="subtitle1">
                            <strong>Leave type</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="subtitle1">
                            <strong>Session</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography>{approvalDetail.employee_ID}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography>{`${Moment(
                            approvalDetail?.from,
                            "DD/MM/YYYY",
                          ).format("ll (ddd)")} to ${Moment(
                            approvalDetail?.to,
                            "DD/MM/YYYY",
                          ).format("ll (ddd)")}`}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography>{approvalDetail.leavetype}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography>{`${
                            approvalDetail.daysTaken >= 1
                              ? "Full Day"
                              : "Half Day"
                          }`}</Typography>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={6}>
                          <Typography variant="subtitle1">
                            <strong>Employee Id</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{approvalDetail.employee_ID}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="subtitle1">
                            <strong>Date</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{`${Moment(
                            approvalDetail?.from,
                            "DD/MM/YYYY",
                          ).format("ll (ddd)")} to ${Moment(
                            approvalDetail?.to,
                            "DD/MM/YYYY",
                          ).format("ll (ddd)")}`}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="subtitle1">
                            <strong>Leave type</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{approvalDetail.leavetype}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="subtitle1">
                            <strong>Session</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>
                            {`${
                              approvalDetail.daysTaken >= 1
                                ? "Full Day"
                                : "Half Day"
                            }`}{" "}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid
            xs={12}
            container
            spacing={isMobile ? 0 : 2}
            rowSpacing={isMobile ? 2 : 0}
            mb={4}
          >
            {leavesSummary?.map((leaves, index) => {
              return (
                <Grid item xs={12} md={4} key={index}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2} sx={{ mt: -3, mb: 3 }}>
                        <Grid item xs={9}>
                          <Typography variant="subtitle1">
                            {leaves.name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          justifyContent={"flex-end"}
                          display={"flex"}
                        >
                          <div
                            style={{
                              background: leaves.color,
                              height: 32,
                              width: 32,
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {leaveSummaryIcons(leaves.name)}
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            Credited Balance
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          justifyContent={"flex-end"}
                          display={"flex"}
                        >
                          <Typography>{leaves.creditedBalance}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2">Leave taken</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          justifyContent={"flex-end"}
                          display={"flex"}
                        >
                          <Typography>{leaves.leavesTaken}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            Closing balance
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          justifyContent={"flex-end"}
                          display={"flex"}
                        >
                          <Typography>{leaves.closingBalance}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            Currently booked
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          justifyContent={"flex-end"}
                          display={"flex"}
                        >
                          <Typography>{leaves.currentlyBooked}</Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Grid>
    </>
  );
};

export default ApprovalDetails;
