import { CircularProgress, Container, Grid, Typography } from "@mui/material";
import { FC, memo, useEffect, useState } from "react";
import { getOdooToken } from "../../services/Shop";

const Shop: FC = () => {
  const [authToken, setAuthToken] = useState("");
  const [loading, setLoading] = useState(false);

  const getTokenFromOdoo = async () => {
    setLoading(true);
    try {
      const response = await getOdooToken();
      const parsedResponse = JSON.parse(response);
      const parsedResult = JSON.parse(parsedResponse.result);
      setAuthToken(parsedResult.data.token);
    } catch (e) {
      console.log(e, "Error fetching token");
    }
    setLoading(false);
  };

  useEffect(() => {
    getTokenFromOdoo();
  }, []);

  return (
    <Container
      sx={{
        marginTop: "-16px",
        marginLeft: "-28px",
        height: "calc(100vh - 72px)",
        width: "calc(100vw - 245px)",
      }}
    >
      {loading && (
        <Grid
          container
          item
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            height: "calc(100vh - 72px)",
            width: "calc(100vw - 245px)",
          }}
        >
          <Typography color={"#6B7280"}>
            <CircularProgress color="inherit"></CircularProgress>
          </Typography>
        </Grid>
      )}
      {!loading && authToken && (
        <iframe
          style={{
            height: "calc(100vh - 72px)",
            width: "calc(100vw - 242px)",
            border: 0,
          }}
          title="Odoo Shop"
          src={`${process.env.REACT_APP_ODOO_SHOP_URL}?token=${authToken}`}
        ></iframe>
      )}
    </Container>
  );
};

export default memo(Shop);
