import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { styles } from "./PeopleStyle";

import { Avatar, Box, Container, Grid, Typography } from "@mui/material";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import TextSkeleton from "../../shared/components/Skeletons/TextSkeleton";
import { userAccess } from "../../utils/CommonFunction";
import EditMemberForm from "./EditMemberForm";
import { getMemberById, imageReturnFromZoho } from "./People.helper";

const EditMember = (props) => {
  const classes = styles();
  const location: any = useLocation();

  const dispatch = useDispatch();

  let [editForm, setEditForms] = useState<boolean>(false);
  const {
    memberID,
    clients,
    edit = false,
    isPatonaTeamMember = true,
  } = location.state;

  useEffect(() => {
    if (!_.isUndefined(memberID)) {
      getMemberById(memberID, isPatonaTeamMember, dispatch);
      if (edit) {
        setEditForms(true);
      }
    }
  }, [memberID]);

  const { isMemLoading, details } = useSelector(
    //@ts-ignore
    (state) => state.people,
  );

  return (
    <>
      <Grid container component="main">
        <Container maxWidth="xl">
          <>
            {!_.isNull(details) && (
              <>
                <Box
                  className={
                    userAccess().client
                      ? "page-header-pink"
                      : "page-header-blue"
                  }
                  sx={{ display: "flex" }}
                >
                  <Grid item md={8}>
                    {isMemLoading ? (
                      <TextSkeleton />
                    ) : (
                      <Typography
                        variant="h5"
                        sx={{
                          display: "flex",
                          textTransform: "capitalize",
                          alignItems: "center",
                          fontSize: "18px",
                        }}
                      >
                        <Link className={classes.back_icon} to="/team-members">
                          <ArrowBackIosNewIcon />
                        </Link>
                        <Avatar
                          alt={`${details.firstName} ${details.lastName}`}
                          src={
                            details.profilePhotoURL
                              ? imageReturnFromZoho(details.profilePhotoURL)
                              : ""
                          }
                          sx={{ marginRight: 1 }}
                        />
                        {`${details.firstName} ${details.lastName}`}
                      </Typography>
                    )}
                  </Grid>
                </Box>

                <EditMemberForm
                  isMemLoading={isMemLoading}
                  editForm={true}
                  setEditForms={setEditForms}
                  isPatonaTeamMember={isPatonaTeamMember}
                />
              </>
            )}
          </>
        </Container>
      </Grid>
    </>
  );
};

export default EditMember;
