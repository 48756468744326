import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStyles } from "./styles";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { patonaAdmin } from "../../../Constants";
import RowSkeleton from "../../../shared/components/Skeletons/RowSkeleton";
import TextSkeleton from "../../../shared/components/Skeletons/TextSkeleton";
import { STRINGS } from "../../../Strings";
import { getList } from "../../Users/User.helper";
import { getAllClientAdminsList } from "../Client.helper";

type CompanyProps = {
  accountManagerId?: string;
  id: number;
  name: string;
  shortCode: string;
  webSite: string;
  address: string;
  countryCode: string;
  stateCode: string;
  city: string;
  pinCode: string;
  acn: string;
  description: string;
  logoImageFileName: string;
  logoImageFileURL: string;
  logoImage: any;
  isActive: boolean;
};
type CompanyDetailsProps = {
  details: CompanyProps;
  userList: Array<any>;
  isUserListLoading: boolean;
};

const CompanyDetails = (props: CompanyDetailsProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let [loading, setLoading] = useState(true);

  const { clientAdminLoading, clientAdmins } = useSelector(
    //@ts-ignore
    (state) => state.client,
  );

  useEffect(() => {
    getList(dispatch, "getActiveUsers", 1, patonaAdmin, 50);
    getAllClientAdminsList(dispatch, props?.details?.id);
  }, [props?.details?.id]);

  const [clientDetails, setClientDetails] = React.useState<CompanyProps>();

  useEffect(() => {
    setClientDetails(props.details);
    setLoading(false);
  }, []);

  useEffect(() => {
    setClientDetails(props.details);
  }, [props.details]);

  const accountManager = props.userList.find(
    (user) => user.userId === clientDetails?.accountManagerId,
  );
  return (
    <>
      {clientDetails != null && props && (
        <>
          <Box className={classes.display_card}>
            {clientDetails.logoImageFileName && (
              <Grid container spacing={2} mb={4}>
                <Grid item xs={6}>
                  <Typography className={classes.company_title_text}>
                    {STRINGS.CompanyLogo}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {loading ? (
                    <TextSkeleton />
                  ) : (
                    <img
                      className={classes.user_logo}
                      src={clientDetails.logoImageFileURL}
                      alt={clientDetails.logoImageFileName}
                    />
                  )}
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2} mb={4}>
              <Grid item xs={6}>
                <Typography className={classes.company_title_text}>
                  {STRINGS.CompanyName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {loading ? (
                  <TextSkeleton />
                ) : (
                  <Typography variant="body2">{clientDetails.name}</Typography>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={4}>
              <Grid item xs={6}>
                <Typography className={classes.company_title_text}>
                  {STRINGS.ClientCode}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {loading ? (
                  <TextSkeleton />
                ) : (
                  <Typography variant="body2">
                    {clientDetails.shortCode}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={4}>
              <Grid item xs={6}>
                <Typography className={classes.company_title_text}>
                  {STRINGS.CompanyAddress}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {loading ? (
                  <TextSkeleton />
                ) : (
                  <Typography variant="body2">
                    {clientDetails.address}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={6}>
                <Typography className={classes.company_title_text}>
                  {STRINGS.Website}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {loading ? (
                  <TextSkeleton />
                ) : (
                  <Typography variant="body2">
                    {clientDetails.webSite}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={6}>
                <Typography className={classes.company_title_text}>
                  {STRINGS.ACN}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {loading ? (
                  <TextSkeleton />
                ) : (
                  <Typography variant="body2">{clientDetails.acn}</Typography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={6}>
                <Typography className={classes.company_title_text}>
                  Account Manager
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {props.isUserListLoading || loading ? (
                  <TextSkeleton />
                ) : (
                  <Typography variant="body2">
                    {accountManager
                      ? `${accountManager?.userName} (${accountManager?.email})`
                      : null}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12}>
                <Typography className={classes.company_title_text}>
                  {STRINGS.AboutCompany}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <TextSkeleton />
                ) : (
                  <Typography variant="body2">
                    {clientDetails.description}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
          {/* client admin users         */}
          <Box className={classes.display_card}>
            <Typography className={classes.company_title_Heading}>
              {STRINGS.Users}
            </Typography>

            {clientAdminLoading ? (
              <RowSkeleton rowHeight={100}></RowSkeleton>
            ) : (
              <Grid
                container
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {clientAdmins?.length > 0 &&
                  clientAdmins?.map((row, index) => {
                    return (
                      <Grid
                        key={index}
                        container
                        spacing={2}
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{ mt: index > 2 ? 2 : 0 }}
                        className={classes.admin_users_card}
                      >
                        <Grid item xs={12}>
                          <Typography className={classes.company_title_text}>
                            {row?.firstName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: -2 }}>
                          {loading ? (
                            <TextSkeleton />
                          ) : (
                            <Box sx={{ display: "flex" }}>
                              <Typography variant="body2">{`Admin | `}</Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  color: row?.isActive ? "#00E08C" : "#E61A1A",
                                  ml: 1,
                                }}
                              >
                                {row?.isActive
                                  ? "Active user"
                                  : "Inactive user"}
                              </Typography>
                            </Box>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.company_title_text}>
                            {"Email id"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: -2 }}>
                          {loading ? (
                            <TextSkeleton />
                          ) : (
                            <Typography variant="body2">
                              {row?.emailAddress}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <Typography className={classes.company_title_text}>
                            {"Contact number"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: -2 }}>
                          {loading ? (
                            <TextSkeleton />
                          ) : (
                            <Typography variant="body2">
                              {row?.phoneNumber}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.company_title_text}>
                            {"Address"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 2, mt: -2 }}>
                          {loading ? (
                            <TextSkeleton />
                          ) : (
                            <Typography variant="body2">
                              {row?.address}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
                {clientAdmins?.length === 0 && (
                  <Typography variant="body2">{"No user found"}</Typography>
                )}
              </Grid>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default CompanyDetails;
