import { Avatar, Button, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { STRINGS } from "../../../Strings";
import { styles } from "../../../modules/Hire/HireStyle";
import api from "../../../services/BaseApi";
import TextSkeleton from "../Skeletons/TextSkeleton";

const Timeline = ({ showComment, setShowComment, selectedCandidate }) => {
  const classes = styles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Array<any>>([]);

  //@ts-ignore
  const { isCommentAdded } = useSelector((state) => state.hire);

  const getAllCandidateActivity = useCallback(async () => {
    if (selectedCandidate?.candidate.id) {
      setLoading(true);
      try {
        const response = await api.post(
          process.env.REACT_APP_RECRUITLY_URL +
            "getAllCandidateActivityComment",
          {
            CandidateId: selectedCandidate.candidate.id,
          },
        );
        setData(response?.data?.data || []);
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    }
    setLoading(false);
  }, [selectedCandidate]);
  useEffect(() => {
    if (isCommentAdded)
      setTimeout(() => {
        getAllCandidateActivity();
      }, 4000);
  }, [getAllCandidateActivity, isCommentAdded]);

  useEffect(() => {
    if (selectedCandidate?.candidate?.id) {
      getAllCandidateActivity();
    }
  }, [getAllCandidateActivity, selectedCandidate]);

  const renderImage = (action) => {
    if (action === "message") {
      return (
        <img
          className={classes.timelineIcon}
          src="/images/comment.png"
          alt="icon"
        />
      );
    } else if (action === "applied") {
      return (
        <img
          className={classes.timelineIcon}
          src="/images/applied.png"
          alt="icon"
        />
      );
    } else if (action === "disqualified") {
      return (
        <img
          className={classes.timelineIcon}
          src="/images/Disqualify.png"
          alt="icon"
        />
      );
    } else if (action === "auto-disqualified") {
      return (
        <img
          className={classes.timelineIcon}
          src="/images/Disqualify.png"
          alt="icon"
        />
      );
    } else if (action === "assessment") {
      return (
        <img
          className={classes.timelineIcon}
          src="/images/assessment.png"
          alt="icon"
        />
      );
    } else if (action === "candidate_sourced") {
      return (
        <img
          className={classes.timelineIcon}
          src="/images/candidate_sourced.png"
          alt="icon"
        />
      );
    } else if (action === "email") {
      return (
        <img
          className={classes.timelineIcon}
          src="/images/email.png"
          alt="icon"
        />
      );
    } else if (action === "hired") {
      return (
        <img
          className={classes.timelineIcon}
          src="/images/hired.png"
          alt="icon"
        />
      );
    } else if (action === "interview") {
      return (
        <img
          className={classes.timelineIcon}
          src="/images/interview.png"
          alt="icon"
        />
      );
    } else if (action === "offer_letter") {
      return (
        <img
          className={classes.timelineIcon}
          src="/images/offer_letter.png"
          alt="icon"
        />
      );
    } else if (action === "partner_interview") {
      return (
        <img
          className={classes.timelineIcon}
          src="/images/partner_interview.png"
          alt="icon"
        />
      );
    } else if (action === "reverted") {
      return (
        <img
          className={classes.timelineIcon}
          src="/images/reverted.png"
          alt="icon"
        />
      );
    } else if (action === "sourced") {
      return (
        <img
          className={classes.timelineIcon}
          src="/images/sourced.png"
          alt="icon"
        />
      );
    } else {
      return (
        <img
          className={classes.timelineIcon}
          src="/images/comment.png"
          alt="icon"
        />
      );
    }
  };

  return data.length > 0 ? (
    <Box sx={{ mt: 2 }} width={"100%"}>
      <Box
        sx={{
          alignContent: "center",
          backgroundColor: "#ffffff",
          minHeight: 56,
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
        }}
      >
        <Typography variant="subtitle2" component="div" px={3}>
          {STRINGS.Timeline}
        </Typography>
        <Divider />
      </Box>
      <Box
        sx={{
          alignContent: "center",
          backgroundColor: "#ffffff",
          p: 2,
          borderBottomRightRadius: 8,
          borderBottomLeftRadius: 8,
        }}
      >
        {loading ? (
          <TextSkeleton />
        ) : (
          <Box mt={1} mb={1}>
            {!showComment &&
              data?.slice(0, 2).map((timeline) => {
                return (
                  <Box>
                    <Box sx={{ display: "flex" }}>
                      {renderImage(timeline.action)}
                      <Avatar
                        sx={{ width: 24, height: 24 }}
                        alt={timeline.member}
                        src="/static/images/avatar/1.jpg"
                      />
                      {!_.isEmpty(timeline?.journalFrom) && (
                        <Typography variant="subtitle2" sx={{ ml: 1 }}>
                          {timeline.journalFrom.label}
                        </Typography>
                      )}
                      <Typography
                        variant="caption"
                        sx={{ color: "#707D84", ml: 1 }}
                      >
                        {timeline.journalPrettyDate}
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 1, mb: 2 }}>
                      <div
                        style={{
                          fontSize: "12px",
                          lineHeight: "16px",
                          fontWeight: "400",
                          paddingLeft: 25,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: timeline.journalMessage || "",
                        }}
                      />
                      <Divider />
                    </Box>
                  </Box>
                );
              })}
            {showComment &&
              data?.map((timeline) => {
                return (
                  <Box>
                    <Box sx={{ display: "flex" }}>
                      {renderImage(timeline.action)}
                      <Avatar
                        sx={{ width: 24, height: 24 }}
                        alt={timeline.member}
                        src="/static/images/avatar/1.jpg"
                      />
                      {!_.isEmpty(timeline?.journalFrom) && (
                        <Typography variant="subtitle2" sx={{ ml: 1 }}>
                          {timeline.journalFrom.label}
                        </Typography>
                      )}
                      <Typography
                        variant="caption"
                        sx={{ color: "#707D84", ml: 1 }}
                      >
                        {`${timeline.journalPrettyDate}`}
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 1, mb: 2 }}>
                      <div
                        style={{
                          fontSize: "12px",
                          lineHeight: "16px",
                          fontWeight: "400",
                          paddingLeft: 25,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: timeline.journalMessage || "",
                        }}
                      />
                      <Divider />
                    </Box>
                  </Box>
                );
              })}
            {!showComment && data?.length > 2 && (
              <Button
                variant="text"
                onClick={() => {
                  setShowComment(true);
                }}
              >
                {"Expand list"}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  ) : null;
};

export default Timeline;
