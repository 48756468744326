import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Avatar,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { resetSubmitFeedbackInfo } from "../../../redux/Performance/slices/employeeSlice";
import { sagaActions } from "../../../redux/sagaActions";
import FeedbackButtons from "../common/FeedbackButtons";
import FeedbackMenu from "../common/menu/FeedbackMenu";
import SubmitFeedbackModal from "../common/modal/SubmitFeedbackModal";
import { userAccess } from "../../../utils/CommonFunction";
import { teamMember } from "../../../Constants";
import Search from "../../../shared/components/Search/Search";
import { usePermissions } from "../../../utils/permission";

const PerformanceDetailHeader = () => {
  const isMobile = useMediaQuery("(max-width:1000px)");
  const dispatch = useDispatch();
  const empId = useParams().id;
  const navigate: any = useNavigate();
  const location: any = useLocation();
  const { Team_Member } = usePermissions();

  const [showModal, setShowModal] = useState(false);

  const goBack = () => {
    let customState: any = null;
    if (location.state !== null && location.state.pageSize !== undefined) {
      customState = {
        pageSize: location.state.pageSize,
        pageNumber: location.state.pageNumber,
      };
    }

    navigate("/performance", {
      state: customState,
    });
  };

  const { employeeId, employeeName, employeeType, isLoading } = useSelector(
    (state: any) => state.performance.employee,
  );

  const handleSubmissionModalClose = (e: any, status: string | undefined) => {
    if (empId && status === "success") {
      const initialStartDate = moment()
        .subtract(12, "months")
        .startOf("month")
        .toDate();
      const initialEndDate = moment().endOf("month").toDate();
      dispatch({
        type: sagaActions.FETCH_EMPLOYEE_PERFORMANCE,
        payload: {
          employeeId: empId,
          feedbackFrom: initialStartDate,
          feedbackTo: initialEndDate,
          feedbackType: 0,
        },
      });

      dispatch({
        type: sagaActions.FETCH_EMPLOYEE_PERFORMANCE_GRAPH,
        payload: {
          employeeId: empId,
        },
      });
    }
    dispatch(resetSubmitFeedbackInfo());
    setShowModal(false);
  };

  return (
    <>
      {userAccess().isTeamMember ? (
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={goBack}
          >
            <ArrowBackIosNewIcon />
          </IconButton>

          {!isLoading && (
            <div style={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  {
                    <Search
                      placeHolder="Search by name, client or job title"
                      isDisabled={true}
                      sx={{
                        "input:disabled": {
                          cursor: "not-allowed",
                        },
                      }}
                    />
                  }
                </Grid>
              </Grid>
            </div>
          )}

          {isLoading && (
            <div style={{ flexGrow: 1 }}>
              <Stack spacing={1} width={200}>
                <Skeleton variant="rounded" width={180} height={12} />
                <Skeleton variant="rounded" width={110} height={12} />
              </Stack>
            </div>
          )}

          {isMobile && <FeedbackMenu onSubmit={() => setShowModal(true)} />}
          {!isMobile && <FeedbackButtons onSubmit={() => setShowModal(true)} />}
        </Toolbar>
      ) : (
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={goBack}
          >
            <ArrowBackIosNewIcon />
          </IconButton>

          <Avatar sx={{ marginRight: 2 }}>
            {employeeName[0] ? employeeName[0] : "NA"}
          </Avatar>

          {!isLoading && (
            <div style={{ flexGrow: 1 }}>
              <Typography variant="h6" color="inherit" noWrap>
                {employeeName}
              </Typography>

              <Typography
                component="span"
                variant="body2"
                color="textSecondary"
              >
                {employeeId} {employeeType ? `(${employeeType})` : ""}
              </Typography>
            </div>
          )}

          {isLoading && (
            <div style={{ flexGrow: 1 }}>
              <Stack spacing={1} width={200}>
                <Skeleton variant="rounded" width={180} height={12} />
                <Skeleton variant="rounded" width={110} height={12} />
              </Stack>
            </div>
          )}

          {isMobile && <FeedbackMenu onSubmit={() => setShowModal(true)} />}
          {!isMobile && <FeedbackButtons onSubmit={() => setShowModal(true)} />}
        </Toolbar>
      )}

      <SubmitFeedbackModal
        open={showModal}
        onClose={handleSubmissionModalClose}
      />
    </>
  );
};

export default PerformanceDetailHeader;
