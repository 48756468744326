import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { FC, memo, useCallback, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { useCreateDocumentMutation } from "../../redux/Documents/documentSlice";
import { useAddCandidateMutation } from "../../redux/Hire/candidateSlice";
import FormikInput from "../../shared/components/FormikInput";
import { CloseIcon } from "../../shared/components/Icons";
import { userAccess } from "../../utils/CommonFunction";
import { CANDIDATE_PIPELINE_CODE_MAP } from "./CandidateListBody";
import UploadResume from "./UploadResume";

type AddNewCandidateModalProps = {
  open: boolean;
  closeModal: () => void;
  jobReference: string;
  clientCode: string;
};

const initialValuesForm = {
  fullName: "",
  email: "",
  mobile: "",
  countryName: "",
  pipelineId: "",
  status: "",
};

const validationSchema = yup.object({
  fullName: yup.string().required("First name is required"),

  email: yup.string().email().required("Email is required"),
  mobile: yup
    .string()
    .matches(/^[0-9]+$/, "Must only contain number")
    .min(8, "Must be greater than 8 digits")
    .max(14, "Must be less than 14 digits")
    .required("Required"),
  countryName: yup.string(),
});

const AddNewCandidateModal: FC<AddNewCandidateModalProps> = ({
  open,
  closeModal,
  jobReference,
  clientCode,
}) => {
  const candidateId = useRef<string | null>(
    `man-cand-${Date.now()}-uid-${userAccess().userId}`,
  );
  const [addCandidateApi, { isLoading, isError, isSuccess }] =
    useAddCandidateMutation();
  const [
    createDocumentApi,
    {
      isSuccess: createDocumentSuccess,
      isLoading: createDocumentLoading,
      isError: createDocumentError,
    },
  ] = useCreateDocumentMutation();

  const [textCv, setTextCv] = useState("");
  const [fileType, setFileType] = useState("");
  const dispatch = useDispatch();
  const alert = useAlert();

  useEffect(() => {
    if (open && !candidateId.current) {
      candidateId.current = `man-cand-${Date.now()}-uid-${userAccess().userId}`;
    }
    if (!open) candidateId.current = null;
  }, [open]);

  const createDocument = useCallback(
    (data) => {
      if (textCv && candidateId.current) {
        createDocumentApi({
          fileType: fileType,
          ownerType: "Client_Admin",
          ownerId: candidateId.current,
          ownerClientId: userAccess().ClientId,
          createdBy: userAccess().userId,
          createdAt: new Date(),
          userId: candidateId.current,
          title: `Resume-${data.fullName}`,
          url: textCv.split("?")[0],
          type: "client",
          roleCanView:
            "TMF_Admin,Teamified_Admin,Employer,Employer_Hiring_Manager,Employer_Finance,Client_Admin,Patona_Admin",
          roleCanEdit: "TMF_Admin",
          roleCanDelete: "TMF_Admin,Patona_Admin,Teamified_Admin",
          userName: data.fullName,
        });
      }
    },
    [createDocumentApi, fileType, textCv],
  );

  const setTextCVLink = useCallback((link, type) => {
    setTextCv(link);
    setFileType(type);
  }, []);

  useEffect(() => {
    if (isError) {
      alert.error("Something went wrong");
    }
  }, [alert, isError]);

  useEffect(() => {
    if (isSuccess) {
      closeModal();
      alert.success("Candidate added successfully");
    }
  }, [alert, closeModal, isSuccess]);

  const submitForm = useCallback(
    (data) => {
      if (candidateId) {
        addCandidateApi({
          ...data,
          jobReference,
          candidateId: candidateId.current,
          rejected: false,
          status: CANDIDATE_PIPELINE_CODE_MAP[data.pipelineId],
          textCv,
          client: clientCode,
        });
        createDocument(data);
      }
    },
    [addCandidateApi, clientCode, createDocument, jobReference, textCv],
  );

  return (
    <Dialog open={open} onClose={closeModal} maxWidth="xl">
      <DialogTitle
        fontSize={"32px"}
        justifyContent={"center"}
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Typography fontSize={"24px"} lineHeight={"32px"} fontWeight={700}>
          Add new candidate
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          px: 4,
          py: 2,
          width: "50vw",
        }}
      >
        <Formik
          initialValues={initialValuesForm}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={submitForm}
        >
          {(formikProps) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <FormikInput
                    label="Full name*"
                    name="fullName"
                    formikProps={formikProps}
                    options={undefined}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormikInput
                    label="Email*"
                    name="email"
                    formikProps={formikProps}
                    options={undefined}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikInput
                    label="Phone*"
                    name="mobile"
                    formikProps={formikProps}
                    options={undefined}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikInput
                    label="Interview Stage*"
                    name="pipelineId"
                    className="common-select-dropdown"
                    formikProps={formikProps}
                    options={[
                      {
                        name: "Screened by Talent Team",
                        value: "SOURCED",
                      },
                      {
                        name: "Shortlist",
                        value: "SHORTLIST",
                      },
                      {
                        name: "Client Endorsement",
                        value: "CV_SENT",
                      },
                      {
                        name: "Client Interview",
                        value: "INTERVIEW",
                      },
                      {
                        name: "Offer",
                        value: "OFFER",
                      },
                      {
                        name: "Candidate Started",
                        value: "PLACED",
                      },
                    ]}
                    inputType="select"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <UploadResume
                    id={candidateId.current}
                    setTextCVLink={setTextCVLink}
                  ></UploadResume>
                </Grid>
              </Grid>

              <Box
                display="flex"
                justifyContent="flex-end"
                sx={{ mt: 3, mb: 2 }}
              >
                <Button
                  variant="text"
                  sx={{ mt: 2 }}
                  disabled={isLoading}
                  onClick={() => {
                    closeModal();
                    formikProps.resetForm();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={isLoading}
                  variant="contained"
                  sx={{ mt: 2 }}
                >
                  {isLoading ? (
                    <CircularProgress
                      color="inherit"
                      size={20}
                    ></CircularProgress>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default memo(AddNewCandidateModal);
