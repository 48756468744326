import { Box, Button, Grid, Typography } from "@mui/material";
import Opportunities from "../../Opportunities/Opportunities";
import { useNavigate } from "react-router-dom";

const OpportunityContainer = () => {
  const navigate = useNavigate();
  return (
    <Grid item sm={12} xs={12} mt={-3}>
      <Box sx={{ backgroundColor: "white", borderRadius: 2, pb: 3 }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pt={1}
        >
          <Typography fontSize="18px" fontWeight="600" pl={1}>
            Opportunities
          </Typography>
          <Button onClick={() => navigate("/opportunities")}>View all</Button>
        </Box>
        <Opportunities dashboard={true} />
      </Box>
    </Grid>
  );
};

export default OpportunityContainer;
