import Moment from "moment";
import { Dispatch } from "@reduxjs/toolkit";
import { sagaActions } from "../../redux/sagaActions";
import { rowPerPage } from "../../Constants";
import _ from "lodash";
import { daysAgoFromDate } from "../../utils/CommonFunction";
import { invoiceStatus } from "../../services/Invoice";

export const getPendingInvoiceList = async (dispatch: Dispatch<any>) => {
  try {
    const payload = {
      load: 0,
    };
    dispatch({ type: sagaActions.FETCH_PENDING_INVOICE, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getPaidInvoiceList = async (dispatch: Dispatch<any>) => {
  try {
    const payload = {
      load: 0,
      statusList: [invoiceStatus.PAID],
    };
    dispatch({ type: sagaActions.FETCH_PAID_INVOICE, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getPendingSelectPageRowList = async (
  unitsPerPage = rowPerPage,
  dispatch: Dispatch<any>,
) => {
  try {
    const payload = {
      load: 0,
      unitsPerPage,
    };
    dispatch({ type: sagaActions.FETCH_PENDING_INVOICE, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getPendingSortRowList = async (
  orderBy = "",
  isDesc = true,
  dispatch: Dispatch<any>,
) => {
  try {
    const payload = {
      orderBy,
      isDesc,
    };
    dispatch({ type: sagaActions.FETCH_PENDING_INVOICE, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getPendingSearchList = async (
  searchText: any = "",
  dispatch: Dispatch<any>,
) => {
  try {
    const payload = {
      searchText,
    };
    dispatch({ type: sagaActions.FETCH_PENDING_INVOICE, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getPaidSearchList = async (
  searchText: any = "",
  dispatch: Dispatch<any>,
) => {
  try {
    const payload = {
      searchText,
      statusList: [invoiceStatus.PAID],
    };
    dispatch({ type: sagaActions.FETCH_PAID_INVOICE, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getSelectPendingList = async (
  page,
  unitsPerPage = rowPerPage,
  dispatch: Dispatch<any>,
) => {
  try {
    const payload = {
      page,
      unitsPerPage,
    };
    dispatch({ type: sagaActions.FETCH_PENDING_INVOICE, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getSelectPaidList = async (
  page,
  unitsPerPage = rowPerPage,
  dispatch: Dispatch<any>,
) => {
  try {
    const payload = {
      page,
      unitsPerPage,
      statusList: [invoiceStatus.PAID],
    };
    dispatch({ type: sagaActions.FETCH_PAID_INVOICE, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getPaidSelectPageRowList = async (
  unitsPerPage = rowPerPage,
  dispatch: Dispatch<any>,
) => {
  try {
    const payload = {
      load: 0,
      unitsPerPage,
      statusList: [invoiceStatus.PAID],
    };
    dispatch({ type: sagaActions.FETCH_PAID_INVOICE, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getPaidnvoiceList = async (dispatch: Dispatch<any>) => {
  try {
    const payload = {
      load: 0,
      unitsPerPage: 8,
      statusList: [invoiceStatus.PAID],
    };
    dispatch({ type: sagaActions.FETCH_PAID_INVOICE, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getPaidnvoiceListMobile = async (
  dispatch: Dispatch<any>,
  page,
  from,
  to,
) => {
  try {
    const payload = {
      load: 0,
      page,
      dateFrom: Moment(new Date(from)).format("YYYY-MM-DD"),
      dateTo: Moment(new Date(to)).format("YYYY-MM-DD"),
      unitsPerPage: 8,
      statusList: [invoiceStatus.PAID],
    };
    dispatch({ type: sagaActions.FETCH_PAID_INVOICE, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getFilterPaidInvoiceList = async (
  from,
  to,
  dispatch: Dispatch<any>,
) => {
  try {
    const payload = {
      load: 0,
      dateFrom: Moment(new Date(from)).format("YYYY-MM-DD"),
      dateTo: Moment(new Date(to)).format("YYYY-MM-DD"),
      unitsPerPage: 8,
      statusList: [invoiceStatus.PAID],
    };
    dispatch({ type: sagaActions.FETCH_PAID_INVOICE, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getInvoiceGraphList = async (dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.FETCH_INVOICE_GRAPH, payload: [] });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getInvoiceDetailById = async (id, dispatch: Dispatch<any>) => {
  try {
    const payload = {
      id: id,
    };
    dispatch({ type: sagaActions.FETCH_INVOICE_DETAIL, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getInvoicePreviewById = async (id, dispatch: Dispatch<any>) => {
  try {
    const payload = {
      id: id,
    };
    dispatch({ type: sagaActions.FETCH_INVOICE_PREVIEW, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getInvoiceDownloadingById = async (
  id,
  dispatch: Dispatch<any>,
) => {
  try {
    const payload = {
      id: id,
    };
    dispatch({ type: sagaActions.FETCH_INVOICE_DOWNLOAD, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const invoiceHeader = (client = false) => {
  const columns = [
    !client && {
      id: 1,
      name: "Client",
      soryKey: "client",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    !client && {
      id: 2,
      name: "",
      soryKey: "invoiceno",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    !client && {
      id: 3,
      name: "",
      soryKey: "invoiceno",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 4,
      name: "Invoice no",
      soryKey: "invoiceno",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 5,
      name: "Issue date",
      soryKey: "issuedate",
      type: "heading",
      sortable: true,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 6,
      name: "Due date",
      soryKey: "duedate",
      type: "heading",
      display: true,
      sortable: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 7,
      name: "Amount",
      soryKey: "amount",
      type: "heading",
      display: true,
      sortable: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 8,
      name: "",
      type: !client ? "searchIcon" : "text", //searchIcon
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
  ];
  return columns;
};

export const invoicePendingRow = (rowValue: Array<any>, client = false) => {
  const row = rowValue?.map((rowV) => {
    let columns: any = [];
    columns.push(
      !client && {
        id: rowV.InvoiceID,
        name: rowV.Contact ? rowV?.Contact?.Name : "-",
        type: "text",
        experience: false,
        colSpan: 3,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.InvoiceID,
        name: rowV.InvoiceNumber ? rowV.InvoiceNumber : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#002DFF",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.InvoiceID,
        name: rowV?.Date ? Moment(rowV?.Date).format("DD-MM-YYYY") : "-",
        type: "text",
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.InvoiceID,
        name: rowV.DueDate ? daysAgoFromDate(rowV.DueDate) : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#E61A1A",
        fontSize: 12,
        fontWeight: "600",
        textTransform: "capitalize",
      },
      {
        id: rowV.InvoiceID,
        name: rowV.Total ? `$${rowV.Total}` : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.InvoiceID,
        name: "",
        type: "action",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
        peopleId: rowV?.InvoiceID,
        disable: rowV?.isDisabled,
        isPatonaTeamMember: rowV?.isPatonaTeamMember,
      },
    );
    return { ...rowV, teamMemberId: rowV?.InvoiceID, columns, details: rowV };
  });

  return !_.isEmpty(row) ? row : [];
};

export const invoiceDashboardHeader = (client = false) => {
  const columns = [
    !client && {
      id: 1,
      name: "Client",
      soryKey: "client",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    !client && {
      id: 2,
      name: "",
      soryKey: "invoiceno",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    !client && {
      id: 3,
      name: "",
      soryKey: "invoiceno",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 4,
      name: "Invoice no",
      soryKey: "invoiceno",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 5,
      name: "Issue date",
      soryKey: "issuedate",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 6,
      name: "Due date",
      soryKey: "duedate",
      type: "heading",
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 7,
      name: "Amount",
      soryKey: "amount",
      type: "heading",
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 8,
      name: "",
      type: !client ? "text" : "text", //searchIcon
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
  ];
  return columns;
};

export const invoiceDashboardPendingRow = (
  rowValue: Array<any>,
  client = false,
) => {
  const row = rowValue?.map((rowV) => {
    let columns: any = [];
    columns.push(
      !client && {
        id: rowV.InvoiceID,
        name: rowV.Contact
          ? rowV?.Contact?.Name?.length > 20
            ? rowV?.Contact?.Name.substring(0, 20) + "..."
            : rowV?.Contact?.Name
          : "-",
        type: "text",
        experience: false,
        colSpan: 3,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.InvoiceID,
        name: rowV.InvoiceNumber ? rowV.InvoiceNumber : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#002DFF",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.InvoiceID,
        name: rowV?.Date ? Moment(rowV?.Date).format("DD-MM-YYYY") : "-",
        type: "text",
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.InvoiceID,
        name: rowV.DueDate ? daysAgoFromDate(rowV.DueDate) : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#E61A1A",
        fontSize: 12,
        fontWeight: "600",
        textTransform: "capitalize",
      },
      {
        id: rowV.InvoiceID,
        name: rowV.Total ? `$${rowV.Total}` : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.InvoiceID,
        name: "",
        type: "action",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
        peopleId: rowV?.InvoiceID,
        disable: rowV?.isDisabled,
        isPatonaTeamMember: rowV?.isPatonaTeamMember,
      },
    );
    return { ...rowV, teamMemberId: rowV?.InvoiceID, columns, details: rowV };
  });

  return !_.isEmpty(row) ? row : [];
};

export const invoicePaidHeader = (client = false) => {
  const columns = [
    !client && {
      id: 1,
      name: "Client",
      soryKey: "client",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 2,
      name: "Invoice no",
      soryKey: "invoiceno",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 3,
      name: "Issue date",
      soryKey: "issuedate",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 4,
      name: "Paid date",
      soryKey: "paidon",
      type: "heading",
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 5,
      name: "Amount",
      soryKey: "paidamount",
      type: "heading",
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 6,
      name: "",
      type: "searchIcon", //searchIcon
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
  ];
  return columns;
};

export const memberInvoiceHeader = (client = false) => {
  const columns = [
    {
      id: 1,
      name: "Invoice no",
      soryKey: "invoiceno",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 2,
      name: "Month",
      soryKey: "month",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 3,
      name: "Amount",
      soryKey: "amount",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    !client && {
      id: 4,
      name: "Client",
      soryKey: "client",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 5,
      name: "Invoice Status",
      soryKey: "status",
      type: "heading",
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
  ];
  return columns;
};

export const memberInvoicesRow = (rowValue: Array<any>, client = false) => {
  const row = rowValue?.map((rowV) => {
    let columns: any = [];
    columns.push(
      {
        id: rowV.InvoiceID,
        name: rowV.invoiceNumber ? rowV.invoiceNumber : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#002DFF",
        fontSize: 12,
        fontWeight: "600",
        textTransform: "capitalize",
      },
      {
        id: rowV.InvoiceID,
        name: rowV?.month ? rowV?.month : "-",
        type: "text",
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.InvoiceID,
        name: rowV.amount ? `$${rowV.amount}` : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        editableType: "number",
        color: "#00E08C",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      !client && {
        id: rowV.InvoiceID,
        name: rowV.client ? rowV?.client : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },

      {
        id: rowV.InvoiceID,
        name: rowV.status ? rowV.status : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
    );
    return { ...rowV, teamMemberId: rowV?.InvoiceID, columns, details: rowV };
  });
  return !_.isEmpty(row) ? row : [];
};

export const invoicePaidRow = (rowValue: Array<any>, client = false) => {
  const row = rowValue?.map((rowV) => {
    let columns: any = [];
    columns.push(
      !client && {
        id: rowV.InvoiceID,
        name: rowV.Contact ? rowV?.Contact?.Name : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.InvoiceID,
        name: rowV.InvoiceNumber ? rowV.InvoiceNumber : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#002DFF",
        fontSize: 12,
        fontWeight: "600",
        textTransform: "capitalize",
      },
      {
        id: rowV.InvoiceID,
        name: rowV?.Date ? Moment(rowV?.Date).format("DD-MM-YYYY") : "-",
        type: "text",
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.InvoiceID,
        name: rowV.FullyPaidOnDate
          ? Moment(rowV.FullyPaidOnDate).format("DD-MM-YYYY")
          : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.InvoiceID,
        name: rowV.AmountPaid ? `$${rowV.AmountPaid}` : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        editableType: "number",
        color: "#00E08C",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.InvoiceID,
        name: "",
        type: "action",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
        peopleId: rowV?.InvoiceID,
        disable: rowV?.isDisabled,
        isPatonaTeamMember: rowV?.isPatonaTeamMember,
      },
    );
    return { ...rowV, teamMemberId: rowV?.InvoiceID, columns, details: rowV };
  });
  return !_.isEmpty(row) ? row : [];
};

export const fileSet = (
  e,
  setFileName,
  setFile,
  alert,
  setIsResumeUpdated?,
) => {
  e.preventDefault();
  let reader = new FileReader();
  let file = e.target.files[0];
  if (!/(\.doc|\.docx|\.pdf)$/i.test(file.name)) {
    alert.error("Expected file type .doc,.docx, .pdf");
    return false;
  }
  reader.onloadend = () => {
    setFileName(file.name);
    if (!!reader) {
      // @ts-ignore
      const base64String = reader?.result
        // @ts-ignore
        ?.replace("data:", "")
        ?.replace(/^.+,/, "");
      setFile(base64String);
      setIsResumeUpdated(true);
    }
  };
  reader.readAsDataURL(file);
};

export const scrollToTop = (setShowfloat) => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  setShowfloat(false);
};

export const imageReturnFromZoho = (url = "") => {
  const fileUrl = `https://people.zoho.in/patona/viewPhoto?filename=`;
  const zohoFileUrl = new URL(url);
  const urlParams = new URLSearchParams(zohoFileUrl.search);
  const paramValue = urlParams.get("filename");
  const paramFs = urlParams.get("fs");
  if (!_.isEmpty(paramValue)) {
    return fileUrl + paramValue;
  }
  if (!_.isEmpty(paramFs)) {
    return url;
  }
  return "";
};

export const invoiceDetailHeader = (client) => {
  const columns = [
    {
      id: 1,
      name: "Item",
      soryKey: "item",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      colSpan: 3,
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 2,
      name: "Quantity",
      soryKey: "quantity",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 3,
      name: "Price",
      soryKey: "price",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 4,
      name: "Discount",
      soryKey: "discount",
      type: "heading",
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 5,
      name: "Tax Amount",
      soryKey: "taxamount",
      type: "heading",
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 6,
      name: "Amount",
      soryKey: "amount",
      type: "heading",
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
  ];
  return columns;
};

export const invoiceDetailRow = (rowValue: any, client) => {
  const row = rowValue?.LineItems?.map((rowV, index) => {
    let columns: any = [];
    columns.push(
      {
        id: rowV.AccountID,
        list: [
          {
            name: `${rowV?.Item?.Name ? rowV?.Item?.Name : ""} ${
              rowV?.Item?.Code ? `(${rowV?.Item?.Code})` : ""
            }`,
          },
          { name: `${rowV?.Description}` },
        ],
        type: "array",
        experience: false,
        display: true,
        colSpan: 3,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.AccountID,
        name: rowV?.Quantity ? rowV?.Quantity : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.AccountID,
        name: rowV?.UnitAmount ? `$${rowV?.UnitAmount}` : "-",
        type: "text",
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.AccountID,
        name: rowV?.DiscountAmount ? `$${rowV?.DiscountAmount}` : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.AccountID,
        name: rowV?.TaxAmount ? `$${rowV?.TaxAmount}` : "-",
        type: "text",
        experience: false,
        display: true,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.AccountID,
        name: rowV?.LineAmount ? `$${rowV?.LineAmount}` : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
    );
    return { ...rowV, columns, details: rowV };
  });
  if (row?.length > 0) {
    let zeroIndex: any = [];
    zeroIndex.columns = [
      {
        id: "098",
        name: "Remote Team as a service",
        type: "text",
        colSpan: 9,
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
    ];
    row.splice(0, 0, zeroIndex);
    let subtotal: any = [];
    subtotal.columns = [
      {
        id: rowValue?.BrandingThemeID,
        list: [{ name: "Subtotal" }, { name: "Total GST" }],
        type: "array",
        colSpan: 7,
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "600",
        textTransform: "capitalize",
      },
      {
        id: rowValue?.BrandingThemeID,
        list: [
          { name: `$${rowValue?.SubTotal}` },
          { name: `$${rowValue?.TotalTax}` },
        ],
        type: "array",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "600",
        textTransform: "capitalize",
      },
    ];
    row.push(subtotal);
    let total: any = [];
    total.columns = [
      {
        id: "0999",
        list: [{ name: "Amount due" }],
        type: "array",
        colSpan: 7,
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 14,
        fontWeight: "600",
        textTransform: "capitalize",
      },
      {
        id: "08889",
        list: [{ name: `$${rowValue?.Total}` }],
        type: "array",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 14,
        fontWeight: "600",
        textTransform: "capitalize",
      },
    ];
    row.push(total);

    let downloadButton: any = [];
    downloadButton.columns = [
      {
        id: "0990",
        name: "",
        type: "text",
        colSpan: 6,
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 14,
        fontWeight: "600",
        textTransform: "capitalize",
      },
      {
        id: rowValue?.InvoiceID,
        name: "Preview",
        type: "textAction",
        experience: false,
        display: true,
        editable: false,
        color: "#002DFF",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowValue?.InvoiceID,
        name: "Download",
        type: "outlined_button",
        experience: false,
        sx: { padding: 0 },
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        textAlign: "left",
        fontWeight: "400",
        textTransform: "capitalize",
      },
    ];
    row.push(downloadButton);
  }

  return row;
};
