import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  m_title: {
    textTransform: "capitalize",
  },
  select: {
    ".css-1wxbabk-MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
  fullCalender: {
    "& .fc-h-event": {
      backgroundColor: "transparent !important",
      borderColor: "transparent !important",
    },
    "& .fc-daygrid-event": {
      fontSize: "15px",
    },
    "& .fc-sticky": {
      float: "right",
    },
    "& .fc-daygrid-day-frame": {
      position: "relative",
    },
    "& .fc-daygrid-day-frame:hover": {
      "& .fc-daygrid-event #in-icon": {
        display: "block",
      },
    },
    "& .fc-event:focus": {
      boxShadow: "none",
    },
    "& .fc-event:focus:after": {
      backgroundColor: "white",
    },
  },

  icon: {
    position: "absolute",
    top: "-42px",
    right: "5px",
    cursor: "pointer",
    display: "none",
  },
}));

export { useStyles };
