import Moment from "moment";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

const DetailInfo = ({ timesheetDetail }) => {
  return (
    <Grid container xs={12} sx={{ mb: 2 }}>
      <Grid item xs={12}>
        <Card>
          <CardContent sx={{ display: "flex", width: "100%" }}>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12} sm={6} md={5} sx={{ mt: -4 }}>
                <Grid container xs={12}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Grid container xs={12} sx={{ display: "flex" }}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                          ml: -2,
                          textAlign: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="/images/time.png"
                          style={{
                            width: "100%",
                            textAlign: "center",
                            alignItems: "center",
                          }}
                          alt="time"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={8} sx={{ mt: 3, ml: 2 }}>
                        <Box>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "600" }}
                          >
                            {"Total Hours"}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h6">
                            {timesheetDetail.totalHours}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 1 }}>
                    <Grid container xs={12} sx={{ display: "flex" }}>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body2" sx={{ fontWeight: "600" }}>
                          {`Started on `}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="caption">
                          {timesheetDetail?.fromDate
                            ? Moment(timesheetDetail?.fromDate).format(
                                "YYYY-MM-DD",
                              )
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container xs={12} sx={{ display: "flex" }}>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body2" sx={{ fontWeight: "600" }}>
                          {"Project name"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="caption">
                          {timesheetDetail?.projectName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={7} sx={{ mt: -1 }}>
                <Grid container xs={12}>
                  <Grid item xs={12} sx={{ display: "flex", mb: 2 }}>
                    <Grid container xs={12} sx={{ display: "flex" }}>
                      <Grid item xs={12} md={3}>
                        <Typography
                          variant="body2"
                          sx={{ mb: 2, fontWeight: "600" }}
                        >
                          {"Client"}
                        </Typography>
                        <Typography variant="caption">
                          {timesheetDetail?.client}
                        </Typography>
                      </Grid>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ width: "1px", height: 60, mr: 5 }}
                      />
                      <Grid item xs={12} md={3}>
                        <Typography
                          variant="body2"
                          sx={{ mb: 2, fontWeight: "600" }}
                        >
                          {"Approver"}
                        </Typography>
                        <Typography variant="caption">
                          {timesheetDetail?.timesheetApproverInfo?.name}
                        </Typography>
                      </Grid>

                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ width: "1px", height: 60, mr: 5 }}
                      />

                      <Grid item xs={12} md={3}>
                        <Typography
                          variant="body2"
                          sx={{ mb: 2, fontWeight: "600" }}
                        >
                          {"Billing status"}
                        </Typography>
                        <Typography variant="caption">
                          {timesheetDetail?.status}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ display: "flex" }}>
                    <Grid item xs={12} sm={6} md={2}>
                      <Typography variant="caption">{"Description"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={10} sx={{ pr: 3 }}>
                      <Typography variant="caption">
                        {timesheetDetail?.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export default DetailInfo;
