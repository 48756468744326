import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getAttendanceList } from "../../Attendance/attendance.helper";
import { useDispatch, useSelector } from "react-redux";
import { userAccess } from "../../../utils/CommonFunction";
import moment from "moment";
import DataGrid from "../../../shared/components/DataGrid/DataGrid";
import { dashboardAttendanceRow } from "./dahboardAttendance.helper";
import MobileSkeleton from "../../../shared/components/Skeletons/MobileSkeleton";
import TableSkeleton from "../../../shared/components/Skeletons/TableSkeleton";
import { Box } from "@mui/system";
import AttendanceCalender from "../../Attendance/CalenderView/AttendanceCalender";

const AttendaceContainer = () => {
  const [view, setView] = useState("week");
  const dispatch = useDispatch();
  const { email } = userAccess();
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  // const skeleton = isMobile ? <MobileSkeleton /> : <TableSkeleton />;
  const { attendanceList, attendanceLoading } = useSelector(
    //@ts-ignore
    (state) => state.attendance,
  );

  useEffect(() => {
    let sDate = "";
    let eDate = "";
    if (view === "week") {
      sDate = moment().startOf("week").format("DD-MM-YYYY");
      eDate = moment().endOf("week").format("DD-MM-YYYY");
      getAttendanceList(dispatch, { sDate, eDate, emailId: email });
    }
  }, [view]);

  //sort attendanceList according to date
  const entries = Object.entries(attendanceList);
  entries.sort((a, b) => {
    const dateA: any = moment(a[0], "YYYY-MM-DD");
    const dateB: any = moment(b[0], "YYYY-MM-DD");
    return dateA - dateB;
  });
  const sortedObj = Object.fromEntries(entries);
  //sort attendanceList according to date

  const handleChange = (event) => {
    setView(event.target.value);
  };

  return (
    <Grid item sm={12} xs={12}>
      <Box
        mb={view === "week" ? -5 : 0}
        pl={2}
        sx={{ backgroundColor: "white" }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
          Attendance
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="view"
            name="view"
            value={view}
            onChange={handleChange}
            row
          >
            <FormControlLabel
              value="week"
              control={<Radio />}
              label="This Week"
            />
            <FormControlLabel
              value="month"
              control={<Radio />}
              label="This Month"
            />
          </RadioGroup>
        </FormControl>
      </Box>

      {view === "week" ? (
        <>
          {attendanceLoading ? (
            <TableSkeleton />
          ) : (
            <DataGrid
              row={dashboardAttendanceRow(
                Object.keys(sortedObj).map((item: any) => {
                  return {
                    ...attendanceList[item],
                    date: item,
                  };
                }),
              )}
            />
          )}
        </>
      ) : (
        <Grid height={650}>
          <AttendanceCalender dashboard={true} />
        </Grid>
      )}
    </Grid>
  );
};

export default AttendaceContainer;
