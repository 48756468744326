import { Box, Card, Divider, Grid, Typography } from "@mui/material";
import { memo, useCallback, useRef, useState } from "react";
import { STRINGS } from "../../Strings";

const CandidateResume = ({ headerColor, html }) => {
  const [iframeHeight, setIframeHeight] = useState<string>("0px");
  const frameRef = useRef<HTMLIFrameElement>(null);
  const onLoad = useCallback(
    (e) =>
      setIframeHeight(
        (frameRef.current?.contentWindow?.document.body.scrollHeight || 0) +
          10 +
          "px",
      ),
    [],
  );
  return (
    <Card
      className="tbl-card"
      sx={{
        minWidth: 275,
        marginTop: 2,
        boxShadow: 10,
        width: "auto",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: headerColor,
          paddingLeft: "1.5rem",
          paddingBottom: "0.5rem",
          paddingTop: "0.5rem",
          justifyContent: "flex-start",
          minHeight: 52,
        }}
      >
        <Grid item xs={11} display={"flex"} justifyContent={"space-between"}>
          <Typography variant="subtitle2" component="div">
            {STRINGS.Resume}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Box
        className="tbl-body"
        sx={{
          padding: "1.5rem",
        }}
      >
        <iframe
          srcDoc={html}
          title="candidateResume"
          ref={frameRef}
          width={"100%"}
          style={{
            border: 0,
          }}
          height={iframeHeight}
          onLoad={onLoad}
        ></iframe>
      </Box>
    </Card>
  );
};

export default memo(CandidateResume);
