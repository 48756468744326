import { Button, CircularProgress, Grid } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import {
  Document,
  useGetDocumentsQuery,
} from "../../redux/Documents/documentSlice";
import { usePermissions } from "../../utils/permission";
import UploadDocument from "./UploadDocument";

const Initial_Map = {
  Resume: "",
  ID: "",
  Certificate: "",
};

const CandidateDocuments = ({
  candidateId,
  candidateName,
  companyName = "",
}) => {
  const [showNew, setShowNew] = useState(false);
  const { Hire_UploadCandidateDocuments } = usePermissions();
  const { data, isLoading, isSuccess } = useGetDocumentsQuery(
    {
      userId: candidateId,
    },
    {
      skip: !candidateId,
    },
  );

  const [resultMap, setResultMap] = useState<Record<string, string>>({
    ...Initial_Map,
  });

  useEffect(() => {
    setResultMap({ ...Initial_Map });
  }, [candidateId]);

  const createData = useCallback((docList: Document[]) => {
    if (docList) {
      setResultMap((prev) => {
        docList.forEach((doc) => {
          const splitTitle = doc.title.split("-");
          prev[splitTitle[0]] = doc.url || "";
        });
        console.log("setState Called", docList, prev);

        return { ...prev };
      });
    }
  }, []);

  useEffect(() => {
    if (isSuccess && data?.data) {
      createData(data.data);
    }
  }, [createData, data, isSuccess]);

  const uploadDone = useCallback(() => {
    setShowNew(false);
  }, []);

  if (isLoading)
    return (
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <CircularProgress size={40}></CircularProgress>
      </Grid>
    );

  return (
    <Grid container item xs={12} md={8} flexDirection={"column"} spacing={1}>
      {Object.entries(resultMap).map((el, index) => (
        <UploadDocument
          documentName={el[0]}
          id={candidateId}
          type="client"
          userName={candidateName}
          key={`${el}-${index}-${candidateId}`}
          url={el[1]}
          roleCanView="TMF_Admin,Teamified_Admin,Employer,Employer_Hiring_Manager,Employer_Finance,Client_Admin,Patona_Admin"
          roleCanEdit="TMF_Admin"
          roleCanDelete="TMF_Admin,Patona_Admin,Teamified_Admin"
          disableUpload={!Hire_UploadCandidateDocuments}
        ></UploadDocument>
      ))}
      <Grid container item justifyContent={"flex-end"}>
        {showNew ? (
          <UploadDocument
            documentName=""
            id={candidateId}
            type="client"
            userName={candidateName}
            url=""
            uploadDone={uploadDone}
            roleCanView="TMF_Admin,Teamified_Admin,Employer,Employer_Hiring_Manager,Employer_Finance,Client_Admin,Patona_Admin"
            roleCanEdit="TMF_Admin"
            roleCanDelete="TMF_Admin,Patona_Admin,Teamified_Admin"
          ></UploadDocument>
        ) : Hire_UploadCandidateDocuments ? (
          <Button variant="text" size="small" onClick={() => setShowNew(true)}>
            +Add More
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
};
export default memo(CandidateDocuments);
