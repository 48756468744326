import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import Moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getWeekStartAndEndDate,
  userAccess,
} from "../../../utils/CommonFunction";
import { getWeeklyTimeLogDetail } from "../TeamMemberTimesheet.helper";

const LogTableSkeleton = () => {
  return (
    <>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_) => (
        <>
          <Box display="flex" justifyContent="space-around">
            <Skeleton
              variant="text"
              sx={{ fontSize: "1.5rem", width: "13rem" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1.5rem", width: "13rem" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1.5rem", width: "25rem" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1.5rem", width: "13rem" }}
            />
          </Box>
          <hr />
        </>
      ))}
    </>
  );
};

const TimeLogTable = () => {
  const [weeklyView, setWeeklyView] = useState<number>(0);
  const [startEndDate, setStartEndDate] = useState("");
  const dispatch = useDispatch();
  const { timeLogDetail, timeLogDetailLoading } = useSelector(
    //@ts-ignore
    (state) => state.timesheet,
  );
  const { zohoId, isTeamMember } = userAccess();

  const totalHours = useMemo(() => {
    if (timeLogDetail && timeLogDetail.length) {
      return timeLogDetail.reduce((a, b) => {
        return a.add(b.hours);
      }, Moment.duration("00:00"));
    }
  }, [timeLogDetail]);

  const TableCellFontSize = {
    fontSize: "12px",
    fontWeight: "400",
  };

  useEffect(() => {
    var d = new Date();
    const daysMinus = d.getDate() + weeklyView * 7;
    d.setDate(daysMinus);
    const { start, end } = getWeekStartAndEndDate(d);
    const fromDate = start ? Moment(new Date(start)).format("YYYY-MM-DD") : "";
    const endDate = start ? Moment(new Date(end)).format("YYYY-MM-DD") : "";
    const startDate = start ? Moment(new Date(start)).format("DD-MM-YYYY") : "";
    const enDate = start ? Moment(new Date(end)).format("DD-MM-YYYY") : "";
    const timesheetName = `${startDate} to ${enDate}`;
    setStartEndDate(timesheetName);
    getWeeklyTimeLogDetail(dispatch, {
      zohoID: zohoId,
      fromDate: fromDate,
      toDate: endDate,
    });
  }, [weeklyView, dispatch, zohoId]);

  return (
    <TableContainer
      sx={{ maxWidth: 1178, ml: 1, backgroundColor: "#fff", p: 2, mt: 2 }}
    >
      <Grid
        item
        xs={12}
        sm={6}
        md={7}
        sx={{
          display: "flex",
          textAlign: "center",
          alignContent: "center",
          alignItems: "center",
          mr: 2,
        }}
      >
        {
          <IconButton
            onClick={() => {
              setWeeklyView(weeklyView - 1);
            }}
          >
            <ArrowBackIosIcon sx={{ mr: -2 }} />
          </IconButton>
        }
        <Typography variant="h6" sx={{ fontWeight: "normal", ml: 1 }}>
          {startEndDate}
        </Typography>
        {
          <IconButton
            disabled={weeklyView === 0 ? true : false}
            onClick={() => {
              setWeeklyView(weeklyView + 1);
            }}
          >
            <ArrowForwardIosIcon sx={{ mr: 1 }} />
          </IconButton>
        }
      </Grid>
      <Table aria-label="caption table">
        {timeLogDetailLoading ? (
          <LogTableSkeleton />
        ) : (
          <TableBody>
            {timeLogDetail?.map((row) => (
              <TableRow key={row.date}>
                <TableCell
                  sx={{ ...TableCellFontSize }}
                  component="th"
                  scope="row"
                >
                  {row.workDate}
                </TableCell>
                <TableCell sx={{ ...TableCellFontSize }} align="left">
                  {row.day}
                </TableCell>
                <TableCell sx={{ ...TableCellFontSize }} align="left">
                  {row.desc ? row.desc : "-"}
                </TableCell>
                <TableCell sx={{ ...TableCellFontSize }} align="center">
                  {row.hours}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        m={2}
      >
        <Typography>
          Total Submitted Hours:{" "}
          {totalHours ? totalHours.format("hh:mm") : "00:00"}
        </Typography>
        {!isTeamMember && (
          <Button
            sx={{ color: "#fff", backgroundColor: "#00E08C" }}
            variant="contained"
          >
            Approved Timesheet
          </Button>
        )}
      </Box>
    </TableContainer>
  );
};

export default TimeLogTable;
