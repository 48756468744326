const TasksIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.76428 2.5H14.8449C15.7229 2.5 16.4419 3.21917 16.4419 4.09719V15.9028C16.4419 16.7798 15.7221 17.5 14.8449 17.5H5.76428C4.88606 17.5 4.16669 16.7818 4.16669 15.9028V4.09719C4.16669 3.21798 4.88527 2.5 5.76428 2.5ZM9.96515 14.2365H14.687V15.0186H9.96515V14.2365ZM9.96515 12.5978H14.687V13.3791H9.96515V12.5978ZM9.96515 9.99394H14.687V10.7757H9.96515V9.99394ZM9.96515 8.35466H14.687V9.13638H9.96515V8.35466ZM9.96515 6.18582H14.687V6.96793H9.96515V6.18582ZM9.96515 4.54673H14.687V5.32845H9.96515V4.54673ZM5.96641 14.3136C5.60523 13.9524 6.15484 13.4006 6.518 13.7638L6.78923 14.0358L8.06913 12.7565C8.43011 12.3953 8.98209 12.9455 8.61913 13.3083L7.06383 14.8624C6.91214 15.0143 6.66493 15.0135 6.51304 14.8614L5.96641 14.3136ZM5.96641 10.0708C5.60523 9.70981 6.15484 9.15762 6.518 9.52078L6.78923 9.79261L8.06913 8.5137C8.43011 8.15233 8.98209 8.70233 8.61913 9.06529L7.06383 10.6196C6.91214 10.7713 6.66493 10.7705 6.51304 10.6186L5.96641 10.0708ZM5.96641 5.82762C5.60523 5.46684 6.15484 4.91465 6.518 5.27801L6.78923 5.55004L8.06913 4.27093C8.43011 3.90936 8.98209 4.45956 8.61913 4.82232L7.06383 6.37703C6.91214 6.52833 6.66493 6.52753 6.51304 6.37584L5.96641 5.82762ZM14.8449 3.28172H5.76428C5.31494 3.28172 4.9488 3.64766 4.9488 4.09719V15.9028C4.9488 16.3529 5.31375 16.7183 5.76428 16.7183H14.8449C15.2954 16.7183 15.6606 16.3529 15.6606 15.9028V4.09719C15.6606 3.64687 15.2954 3.28172 14.8449 3.28172Z"
        className="fill-color"
      />
    </svg>
  );
};

export default TasksIcon;
