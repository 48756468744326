import RemoveIcon from "@mui/icons-material/Remove";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllSkills } from "../../../modules/People/People.helper";

type SkillsProps = {
  setSkill?: ([]) => void;
  editSkill?: [];
};

const Skills: React.FunctionComponent<SkillsProps> = (props) => {
  const dispatch = useDispatch();
  const { setSkill = () => null, editSkill = [] } = props;
  const [val, setVal] = useState<any>(editSkill || []);

  useEffect(() => {
    getAllSkills(dispatch);
  }, []);
  const { skills } = useSelector(
    //@ts-ignore
    (state) => state.people,
  );

  const valHtml = val?.map((option: any, index) => {
    const label = option?.skill || option;
    return (
      <Chip
        sx={{ marginRight: 1, marginTop: 1 }}
        key={label + index + "_testing"}
        label={label}
        deleteIcon={<RemoveIcon />}
        onDelete={() => {
          const filterArray: any = val.filter((entry) => entry !== option);
          setVal(filterArray);
          setSkill(filterArray);
        }}
      />
    );
  });

  return (
    <div
      style={{
        border: "1px solid rgba(0, 0, 0, 0.05)",
        paddingLeft: 15,
        paddingBottom: 5,
      }}
    >
      <Autocomplete
        multiple
        id="tags-standard"
        freeSolo
        filterSelectedOptions
        options={skills}
        onChange={(e, option) => {
          if (option.length > 0) {
            option.forEach((val, index) => {
              if (!Object.keys(val)?.includes("skill")) {
                option[index] = {
                  id: 0,
                  skill: val,
                };
              }
            });
            setVal(option);
            setSkill(option);
          }
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.skill}
            </li>
          );
        }}
        handleHomeEndKeys={true}
        getOptionLabel={(option) => option.skill}
        renderTags={() => {}}
        value={val}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder="Skills"
            margin="normal"
            fullWidth
          />
        )}
      />
      <div className="selectedTags">{valHtml}</div>
    </div>
  );
};

export default Skills;
