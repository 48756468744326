import { Box, Card, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import Moment from "react-moment";

export const OpenJobsCards = (props) => {
  const { getDetails, jobsData } = props;
  return (
    <Box
      className="responsive-tbl"
      display="flex"
      columnGap={2}
      overflow="auto"
    >
      {jobsData?.map((data) => (
        <Box key={data.job_Id} display="flex" flexShrink={0} width={"90%"}>
          <Card
            key={data.job_Id}
            className="tbl-card"
            sx={{ marginBottom: 3, width: "100%" }}
            onClick={() => getDetails(data.shortCode, false, data.stages)}
          >
            <Grid container spacing={2} className="tbl-header">
              <Grid item xs={8}>
                <Typography variant="h6" component="div" noWrap>
                  {data.job_Title}
                </Typography>
                <Box display="flex" justifyContent="flex-start">
                  <Typography variant="subtitle2">
                    <Moment format="MM/DD/YYYY">
                      {data.first_Published_At}
                    </Moment>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box display="flex" justifyContent="flex-end">
                  <Typography variant="subtitle2">
                    {data.department_Name}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Box className="tbl-body">
              <Grid container rowSpacing={1}>
                {data.stages?.map((data: any) => (
                  <Fragment key={data?.current_Stage_Name}>
                    <Grid
                      container
                      item
                      xs={6}
                      display="flex"
                      alignItems={"center"}
                    >
                      <Grid item xs={9}>
                        <Typography variant="body2" component="div">
                          {data?.current_Stage_Name}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2" component="div">
                          {data?.stage_Count}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </Box>
          </Card>
        </Box>
      ))}
    </Box>
  );
};
