import { Grid, Typography } from "@mui/material";
import { FC, memo } from "react";
import { STRINGS } from "../../../Strings";
import TextSkeleton from "../Skeletons/TextSkeleton";

type TabDetailsProps = {
  title: string;
  value: string;
  showSkeleton: boolean;
};
const TabDetails: FC<TabDetailsProps> = ({ title, value, showSkeleton }) => {
  return (
    <Grid container spacing={2} sx={{ display: "flex" }} mb={"1rem"}>
      <Grid item xs={6}>
        <Typography variant="body2" component="div">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {showSkeleton ? (
          <TextSkeleton />
        ) : (
          <Typography variant="body2" component="div">
            {value}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default memo(TabDetails);
