import api from "./BaseApi";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const getOdooAccessToken = BASE_URL + "generateOdooAccessToken";
export const getOdooToken = async () => {
  try {
    const response = await api.get(getOdooAccessToken);
    return response.data;
  } catch (error) {
    console.log(error, "Error fetching Odoo Token");
  }
};
