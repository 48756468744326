import approvalReducer from "./Approval/approvalSlice";
import attendanceReducer from "./Attendance/attendanceSlice";
import clientReducer from "./Client/clientSlice";
import companyReducer from "./Company/companySlice";
import dashboardReducer from "./Dashboard/dashboardSlice";
import { documentSlice, documentSliceApi } from "./Documents/documentSlice";
import { candidateMeetingApi } from "./Hire/candidateMeetingApi";
import { candidateListApi } from "./Hire/candidateSlice";
import { hireApi } from "./Hire/hireApi";
import hireReducer from "./Hire/hireSlice";
import { interviewQuestionsApi } from "./Hire/interviewQuestionSlice";
import { mandatoryQuestionsApi } from "./Hire/mandatoryQuestionsSlice";
import invoiceReducer from "./Invoice/invoiceSlice";
import loginReducer from "./Login/loginSlice";
import opportunitiesReducer from "./Opportunities/opportunitiesSlice";
import peopleReducer from "./People/peopleSlice";
import performanceReducer from "./Performance/indexSlice";
import registrationReducer from "./Registration/userRegistrationSlice";
import { TaskApi } from "./Task/TaskApi";
import timesheetReducer from "./Timesheet/timesheetSlice";
import { userApi } from "./User/userApi";
import userReducer from "./User/userSlice";

const reducers = {
  registration: registrationReducer,
  login: loginReducer,
  client: clientReducer,
  people: peopleReducer,
  user: userReducer,
  company: companyReducer,
  hire: hireReducer,
  dashboard: dashboardReducer,
  approval: approvalReducer,
  invoice: invoiceReducer,
  timesheet: timesheetReducer,
  performance: performanceReducer,
  opportunities: opportunitiesReducer,
  attendance: attendanceReducer,
  [candidateListApi.reducerPath]: candidateListApi.reducer,
  [interviewQuestionsApi.reducerPath]: interviewQuestionsApi.reducer,
  [mandatoryQuestionsApi.reducerPath]: mandatoryQuestionsApi.reducer,
  [documentSliceApi.reducerPath]: documentSliceApi.reducer,
  [hireApi.reducerPath]: hireApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  documents: documentSlice.reducer,
  [TaskApi.reducerPath]: TaskApi.reducer,
  [candidateMeetingApi.reducerPath]: candidateMeetingApi.reducer,
};

export default reducers;
