import React, { useEffect } from "react";
import ReactFlipCard from "reactjs-flip-card";
import BarGraph from "../BarGraph/BarGraph";
import OpenJobs from "../OpenJobs/OpenJobs";
import { useStyles } from "./styles";
//styles
import { Skeleton, styled, useMediaQuery } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { STRINGS } from "../../../Strings";
import DataGrid from "../../../shared/components/DataGrid/DataGrid";
import TableSkeleton from "../../../shared/components/Skeletons/TableSkeleton";
import { userAccess } from "../../../utils/CommonFunction";
import { usePermissions } from "../../../utils/permission";
import {
  getInvoiceDetailById,
  getInvoiceDownloadingById,
  getInvoiceGraphList,
  getPaidInvoiceList,
  getPendingInvoiceList,
  invoiceDashboardHeader,
  invoiceDashboardPendingRow,
  invoicePaidHeader,
  invoicePaidRow,
} from "../../Invoice/Invoice.helper";
import InvoiveMobileDataGrid from "../../Invoice/InvoiveMobileDataGrid";
import {
  fetchDashboardCalendar,
  fetchDashboardData,
} from "../Dashboard.helper";
import InvoiceGraph from "../LineGraph/InvoiceGraph";
import UpcomingPublicHoliday from "./UpcomingPublicHoliday/index";

const pendingInvoiceAction = [
  { name: "View", action: "getDetails", display: true },
  {
    name: "Download",
    action: "download",
    display: true,
  },
];

const ResponsiveGrid = styled(Grid)(({ theme }) => ({
  "& > .MuiGrid-item": {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1),
    },
  },
}));

const DashboardLanding = () => {
  const screenWidth = useMediaQuery(`(min-width: 1800px)`);
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    dashboardHeader,
    dashboardActivity,
    dashboardJobs,
    dashboardCalendar,
    isDashboardCalendarLoading,
  } = useSelector(
    //@ts-ignore
    (state) => state.dashboard,
  );
  const {
    Dashboard_Total_Clients,
    Dashboard_Total_Teammember,
    Dashboard_JobRequest,
    Dashboard_NewJoinees,
    Dashboard_Invoices,
  } = usePermissions();

  const {
    totalPending,
    totalPendingAmount,
    isPendingInvoiceLoading,
    pendingInvoices,
    isInvoiceGraphLoading,
    invoiceGraph,
    paidInvoices,
  } = useSelector(
    //@ts-ignore
    (state) => state.invoice,
  );

  const [chartData, setChartData] = React.useState([]);
  const [graphData, setGraphData] = React.useState<any>([]);
  const [invoiceGraphToggle, setInvoiceGraphToggle] = React.useState(false);
  const [activityGraphToggle, setActivityGraphToggle] = React.useState(false);

  const classes = useStyles();

  useEffect(() => {
    const payload = {
      load: 0,
    };
    fetchDashboardData(payload, dispatch);
    fetchDashboardCalendar({}, dispatch);
    if (Dashboard_Invoices) {
      getPendingInvoiceList(dispatch);
      getPaidInvoiceList(dispatch);
      getInvoiceGraphList(dispatch);
    }
  }, []);

  useEffect(() => {
    if (!_.isNull(dashboardActivity)) {
      formatChartData(dashboardActivity);
    }
  }, [dashboardActivity]);

  useEffect(() => {
    if (!_.isNull(invoiceGraph)) {
      formatGraphData(invoiceGraph);
    }
  }, [invoiceGraph]);

  const formatChartData = async (stages) => {
    let arr = stages;
    const data = {
      labels: arr?.map((data) => data?.current_Stage_Name),
      datasets: [
        {
          label: "",
          data: arr?.map((data) => data?.total_Stage_Count),
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };
    //@ts-ignore
    return setChartData(data);
  };

  const formatGraphData = async (stages) => {
    let arr = stages;
    let unPaidAmount = arr?.some((data) => data?.unPaidAmount > 0);
    let paidAmount = arr?.some((data) => data?.paidAmount > 0);

    const data = {
      loadingGraph: unPaidAmount || paidAmount,
      labels: arr?.map((data) => data?.month),
      datasets: [
        {
          label: "Unpaid",
          data: arr?.map((data) => data?.unPaidAmount),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "#FDE0D0",
          yAxisID: "y",
        },
        {
          label: "Paid",
          data: arr?.map((data) => data?.paidAmount),
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "#A0F5D5",
          yAxisID: "y1",
        },
      ],
    };
    //@ts-ignore
    return setGraphData(data);
  };

  const profilePhotosList = (photo) => {
    if (_.isEmpty(photo)) return "";

    let sliceCount = userAccess().client ? 2 : 3;
    sliceCount = Math.min(photo.length, sliceCount);

    const avatars = photo
      .slice(0, sliceCount)
      ?.map((images, index) => (
        <Avatar
          key={index}
          title={images?.name}
          alt={images?.name}
          src={images?.profilePhoto}
          sx={{ height: 24, width: 24, fontSize: 10 }}
        />
      ));

    return (
      <AvatarGroup
        total={photo.length}
        sx={{
          "& .MuiAvatar-root": { width: 24, height: 24, fontSize: 10 },
        }}
      >
        {avatars}
      </AvatarGroup>
    );
  };

  const getInvoiceDetails = (id) => {
    getInvoiceDetailById(id, dispatch);
    navigate("/client-invoices", {
      state: {
        invoiceId: id,
      },
    });
  };

  return (
    <ResponsiveGrid item container xs={12} sx={{ padding: 2 }}>
      <Grid item xs={12} sm={6} md={6}>
        {dashboardHeader && Object.keys(dashboardHeader).length > 0 && (
          <Grid
            item
            container
            xs={12}
            rowSpacing={2}
            columnSpacing={1}
            className="jobActivity"
          >
            {Dashboard_Total_Clients && (
              <Grid item xs={6} sm={6} md={6}>
                <Card
                  className="same-height"
                  sx={{ backgroundColor: "#F0D7FA", cursor: "pointer" }}
                  onClick={() => navigate("/client")}
                >
                  <CardContent className="total-cards-content">
                    <Typography
                      variant="h6"
                      align="center"
                      className={"jobText"}
                    >
                      {STRINGS.TotalClients}
                    </Typography>
                    <Typography variant="subtitle1" align="center">
                      {dashboardHeader?.totalClients || "00"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}

            {Dashboard_JobRequest && (
              <Grid item xs={6} sm={6} md={6}>
                <Card
                  className="same-height"
                  sx={{
                    backgroundColor: "#A0F5D5",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/hire")}
                >
                  <CardContent className="total-cards-content">
                    <Typography variant="h6" align="center" className="jobText">
                      {STRINGS.TotalNewRequest}
                    </Typography>
                    <Typography variant="subtitle1" align="center">
                      {dashboardHeader?.totalJobs || "00"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}

            {Dashboard_Total_Teammember && (
              <Grid item xs={6} sm={6} md={6}>
                <Card
                  className="same-height"
                  sx={{
                    backgroundColor: "#CCD5FC",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/team-members")}
                >
                  <CardContent className="total-cards-content">
                    <Typography
                      variant="h6"
                      align="center"
                      className="jobTextTeamMember"
                    >
                      {STRINGS.TotalMembers}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      align="center"
                      className="jobTextTeamMemberCount"
                    >
                      {dashboardHeader?.totalTeamMembers || "00"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}

            {Dashboard_NewJoinees && (
              <Grid item xs={6} sm={6} md={6}>
                <Card
                  className="same-height"
                  sx={{ backgroundColor: "#FEEFCE", cursor: "pointer" }}
                  onClick={() => navigate("/team-members")}
                >
                  <CardContent className="total-cards-content">
                    <Typography variant="h6" align="center" className="jobText">
                      {STRINGS.TotalNewJoinees}
                    </Typography>
                    <Typography variant="subtitle1" align="center">
                      {dashboardHeader?.totalNewJoinee || "00"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        )}

        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          rowSpacing={2}
          sx={{ pt: 2 }}
          className="leaveDashboardResBox"
        >
          {isDashboardCalendarLoading && <TableSkeleton rowCount={[1]} />}
          {userAccess().client &&
            !isDashboardCalendarLoading &&
            dashboardCalendar.length > 0 &&
            dashboardCalendar?.map((leaveInfo, index) => {
              return (
                <React.Fragment key={index}>
                  {leaveInfo.title !== "Upcoming Public Holiday" &&
                    leaveInfo.title !== "Pending" && (
                      <Grid item xs={12}>
                        <Card
                          className="same-height"
                          sx={{
                            backgroundColor: "#FFFAEA",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigate("/leave", {
                              state: {
                                dashboardFilter: leaveInfo.title.toLowerCase(),
                              },
                            })
                          }
                        >
                          <CardContent className="calander-cards-content">
                            <Typography variant="h6" align="left">
                              {leaveInfo.title || ""}
                            </Typography>
                            <Grid item container xs={12}>
                              <Grid
                                item
                                xs={!_.isEmpty(leaveInfo?.holidaydate) ? 5 : 8}
                              >
                                <Typography
                                  variant="caption"
                                  pt={1}
                                  align="center"
                                >
                                  {leaveInfo?.count > 0
                                    ? leaveInfo?.count === 1
                                      ? `${leaveInfo?.profilePhotosList[0]?.name} on leave`
                                      : `${leaveInfo?.count} people on leave`
                                    : leaveInfo.title === "Pending"
                                      ? `No pending leave`
                                      : leaveInfo.title === "Upcoming"
                                        ? `No upcoming leave`
                                        : `No one is on leave`}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={!_.isEmpty(leaveInfo?.holidaydate) ? 3 : 0}
                              >
                                <Typography
                                  variant="caption"
                                  pt={1}
                                  align="center"
                                >
                                  {leaveInfo?.holidaydate ?? ""}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Stack
                                  direction="row"
                                  sx={{ justifyContent: "flex-end" }}
                                >
                                  {profilePhotosList(
                                    leaveInfo?.profilePhotosList,
                                  )}
                                </Stack>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}

                  {leaveInfo.title === "Upcoming Public Holiday" && (
                    <Grid item xs={12}>
                      <UpcomingPublicHoliday
                        data={leaveInfo}
                        isAdmin={!userAccess().client}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}

          {!userAccess().client &&
            !isDashboardCalendarLoading &&
            dashboardCalendar.length > 0 &&
            dashboardCalendar?.map((leaveInfo, index) => {
              return (
                <React.Fragment key={index}>
                  {leaveInfo.title !== "Upcoming Public Holiday" && (
                    <Grid item xs={12}>
                      <Card
                        className="same-height"
                        sx={{
                          backgroundColor: "#FFFAEA",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate("/leave", {
                            state: {
                              dashboardFilter: leaveInfo.title.toLowerCase(),
                            },
                          })
                        }
                      >
                        <CardContent className="calander-cards-content">
                          <Typography variant="h6" align="left">
                            {leaveInfo.title || ""}
                          </Typography>
                          <Grid item container xs={12}>
                            <Grid
                              item
                              xs={!_.isEmpty(leaveInfo?.holidaydate) ? 5 : 8}
                            >
                              <Typography
                                variant="caption"
                                pt={1}
                                align="center"
                              >
                                {leaveInfo?.count > 0
                                  ? leaveInfo?.count === 1
                                    ? `${leaveInfo?.profilePhotosList[0]?.name} on leave`
                                    : `${leaveInfo?.count} people on leave`
                                  : leaveInfo.title === "Pending"
                                    ? `No pending leave`
                                    : leaveInfo.title === "Upcoming"
                                      ? `There is no upcoming planned leave`
                                      : `No one is on leave today`}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={!_.isEmpty(leaveInfo?.holidaydate) ? 3 : 0}
                            >
                              <Typography
                                variant="caption"
                                pt={1}
                                align="center"
                              >
                                {leaveInfo?.holidaydate ?? ""}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Stack
                                direction="row"
                                sx={{ justifyContent: "flex-end" }}
                              >
                                {profilePhotosList(
                                  leaveInfo?.profilePhotosList,
                                )}
                              </Stack>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}

                  {leaveInfo.title === "Upcoming Public Holiday" && (
                    <Grid item xs={12}>
                      <UpcomingPublicHoliday
                        data={leaveInfo}
                        isAdmin={!userAccess().client}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
        </Grid>

        {Dashboard_Invoices && (
          <Card
            sx={{
              marginBottom: 3,
              marginTop: 2,
              boxShadow: 10,
              paddingTop: 2,
              minHeight: 315,
            }}
          >
            {isPendingInvoiceLoading ? (
              <TableSkeleton rowCount={[1, 2, 3]} />
            ) : (
              <>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    item
                    xs={totalPending > 2 ? 8 : 12}
                    className="pendingInvoiceHeader"
                  >
                    {totalPending > 0 ? (
                      <>
                        <Typography
                          variant="h6"
                          sx={{ color: "#E61A1A", ml: 2 }}
                          className="jobText"
                        >
                          {`${totalPending} ${
                            totalPending > 1 ? `invoices` : `invoice`
                          } awaiting payment`}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            color: "#E61A1A",
                            ml: 2,
                            mr: totalPending > 2 ? 0 : 2,
                          }}
                          className="pendingInvoiceHeaderAmount"
                        >
                          {`$${totalPendingAmount}`}
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="h6" sx={{ ml: 2 }}>
                        {`List of paid invoices`}
                      </Typography>
                    )}
                  </Grid>
                  {totalPending > 2 && (
                    <Grid item xs={3} sx={{ textAlign: "right" }}>
                      <Button
                        className="pendingInvoiceHeaderViewAll"
                        variant="text"
                        onClick={() => navigate("/invoices")}
                      >
                        {"View All"}
                      </Button>
                    </Grid>
                  )}
                </Grid>

                {totalPending > 0 ? (
                  isMobile ? (
                    <InvoiveMobileDataGrid
                      row={invoiceDashboardPendingRow(
                        pendingInvoices?.slice(0, 2),
                        userAccess().client,
                      )}
                      actionList={pendingInvoiceAction}
                      paid={false}
                      getDetails={getInvoiceDetails}
                      getDownload={(id) => {
                        getInvoiceDownloadingById(id, dispatch);
                      }}
                    />
                  ) : (
                    <DataGrid
                      header={invoiceDashboardHeader(userAccess().client)}
                      row={invoiceDashboardPendingRow(
                        pendingInvoices?.slice(0, 2),
                        userAccess().client,
                      )}
                      getDetails={getInvoiceDetails}
                      getDownload={(id) => {
                        getInvoiceDownloadingById(id, dispatch);
                      }}
                      actionList={pendingInvoiceAction}
                    />
                  )
                ) : isMobile ? (
                  <InvoiveMobileDataGrid
                    row={invoicePaidRow(
                      paidInvoices?.slice(0, 2),
                      userAccess().client,
                    )}
                    actionList={pendingInvoiceAction}
                    paid={false}
                    getDetails={getInvoiceDetails}
                    getDownload={(id) => {
                      getInvoiceDownloadingById(id, dispatch);
                    }}
                  />
                ) : (
                  <DataGrid
                    header={invoicePaidHeader(userAccess().client)}
                    row={invoicePaidRow(
                      paidInvoices?.slice(0, 2),
                      userAccess().client,
                    )}
                    getDetails={getInvoiceDetails}
                    getDownload={(id) => {
                      getInvoiceDownloadingById(id, dispatch);
                    }}
                    actionList={pendingInvoiceAction}
                  />
                )}
              </>
            )}
          </Card>
        )}

        {/* {Dashboard_Invoices &&  */}
        <Card sx={{ minHeight: 250, marginTop: Dashboard_Invoices ? 0 : 3 }}>
          <CardContent>
            <Grid
              item
              container
              xs={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: -3,
              }}
              spacing={2}
            >
              <Grid item xs={8}>
                <Typography variant="h6" align="left" className="jobText">
                  {STRINGS.OpenJobRequests}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ textAlign: "right", mr: -5 }}>
                {!_.isNull(dashboardJobs) &&
                  Object.keys(dashboardJobs)?.length > 2 && (
                    <Button
                      variant="text"
                      className="pendingInvoiceHeaderViewAll"
                      sx={{
                        height: 20,
                        ":hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      onClick={() => navigate("/hire")}
                    >
                      {"View All"}
                    </Button>
                  )}
              </Grid>
            </Grid>

            <hr />
            {!_.isNull(dashboardJobs) &&
            Object.keys(dashboardJobs)?.length > 0 ? (
              //@ts-ignore

              <OpenJobs jobsData={dashboardJobs?.slice(0, 2)} />
            ) : (
              <Typography
                variant="caption"
                sx={{ textAlign: "center", justifyContent: "center" }}
              >
                {"No open jobs"}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={12}
          rowSpacing={2}
          className="leaveDashboardBox"
        >
          {/* {isDashboardCalendarLoading && (
            <TableSkeleton
              rowCount={[1]}
              header={userAccess().client ? false : true}
            />
          )} */}
          {userAccess().client &&
            !isDashboardCalendarLoading &&
            dashboardCalendar.length > 0 &&
            dashboardCalendar?.map((leaveInfo, index) => {
              return (
                <React.Fragment key={index}>
                  {leaveInfo.title !== "Upcoming Public Holiday" &&
                    leaveInfo.title !== "Pending Approvals" && (
                      <Grid item xs={4}>
                        <Card
                          className="same-height"
                          sx={{
                            backgroundColor: "#FFFAEA",
                            mr: 1,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigate("/leave", {
                              state: {
                                dashboardFilter: leaveInfo.title.toLowerCase(),
                              },
                            })
                          }
                        >
                          <CardContent className="calander-cards-content">
                            <Typography variant="h6" align="left">
                              {leaveInfo.title || ""}
                            </Typography>
                            <Grid item container xs={12}>
                              <Grid
                                item
                                xs={!_.isEmpty(leaveInfo?.holidaydate) ? 5 : 8}
                              >
                                <Typography
                                  variant="caption"
                                  pt={1}
                                  align="center"
                                >
                                  {leaveInfo?.count > 0
                                    ? leaveInfo?.count === 1
                                      ? `${leaveInfo?.profilePhotosList[0]?.name} on leave`
                                      : `${leaveInfo?.count} people on leave`
                                    : leaveInfo.title === "Pending Approvals"
                                      ? `No pending leave`
                                      : leaveInfo.title === "Upcoming Leave"
                                        ? `No upcoming leave`
                                        : `No one is on leave`}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={!_.isEmpty(leaveInfo?.holidaydate) ? 3 : 0}
                              >
                                <Typography
                                  variant="caption"
                                  pt={1}
                                  align="center"
                                >
                                  {leaveInfo?.holidaydate ?? ""}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Stack
                                  direction="row"
                                  sx={{ justifyContent: "flex-end" }}
                                >
                                  {profilePhotosList(
                                    leaveInfo?.profilePhotosList,
                                  )}
                                </Stack>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}

                  {leaveInfo.title === "Upcoming Public Holiday" && (
                    <Grid item xs={4}>
                      <UpcomingPublicHoliday
                        data={leaveInfo}
                        isAdmin={!userAccess().client}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          {isDashboardCalendarLoading &&
            Array.from({ length: userAccess().client ? 3 : 4 })?.map(
              (_, index) => {
                return (
                  <Grid key={index} item xs={userAccess().client ? 4 : 6}>
                    <Card
                      className="same-height"
                      sx={{
                        mr: userAccess().client ? 1 : index % 2 === 0 ? 1 : 0,
                        cursor: "pointer",
                        p: 0,
                      }}
                    >
                      <CardContent className="calander-cards-content">
                        <Skeleton
                          variant="rounded"
                          width={"100%"}
                          height={100}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                );
              },
            )}
          {!userAccess().client &&
            !isDashboardCalendarLoading &&
            dashboardCalendar.length > 0 &&
            dashboardCalendar?.map((leaveInfo, index) => {
              return (
                <React.Fragment key={index}>
                  {leaveInfo.title !== "Upcoming Public Holiday" && (
                    <Grid item xs={6}>
                      <Card
                        className="same-height"
                        sx={{
                          backgroundColor: "#FFFAEA",
                          mr: index % 2 === 0 ? 1 : 0,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate("/leave", {
                            state: {
                              dashboardFilter: leaveInfo.title.toLowerCase(),
                            },
                          })
                        }
                      >
                        <CardContent className="calander-cards-content">
                          <Typography variant="h6" align="left">
                            {leaveInfo.title || ""}
                          </Typography>
                          <Grid item container xs={12}>
                            <Grid
                              item
                              xs={!_.isEmpty(leaveInfo?.holidaydate) ? 5 : 8}
                            >
                              <Typography
                                variant="caption"
                                pt={1}
                                align="center"
                              >
                                {leaveInfo?.count > 0
                                  ? leaveInfo?.count === 1
                                    ? `${leaveInfo?.profilePhotosList[0]?.name} on leave`
                                    : `${leaveInfo?.count} people on leave`
                                  : leaveInfo.title === "Pending Approvals"
                                    ? `No pending leave`
                                    : leaveInfo.title === "Upcoming Leave"
                                      ? `There is no upcoming planned leave`
                                      : `No one is on leave today`}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={!_.isEmpty(leaveInfo?.holidaydate) ? 3 : 0}
                            >
                              <Typography
                                variant="caption"
                                pt={1}
                                align="center"
                              >
                                {leaveInfo?.holidaydate ?? ""}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Stack
                                direction="row"
                                sx={{ justifyContent: "flex-end" }}
                              >
                                {profilePhotosList(
                                  leaveInfo?.profilePhotosList,
                                )}
                              </Stack>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}

                  {leaveInfo.title === "Upcoming Public Holiday" && (
                    <Grid item xs={6}>
                      <UpcomingPublicHoliday
                        data={leaveInfo}
                        isAdmin={!userAccess().client}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
        </Grid>

        {Dashboard_Invoices && isInvoiceGraphLoading ? (
          <TableSkeleton rowCount={[1, 2, 3]} />
        ) : (
          <>
            {Dashboard_Invoices && (
              <Card
                sx={{
                  marginBottom: 2,
                  marginTop: isMobile ? 0 : 2,
                  boxShadow: 10,
                }}
              >
                <CardContent
                  sx={{
                    marginTop: -2,
                    cursor: "pointer",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography variant="h6" align="left" className="jobText">
                        {"Paid and unpaid invoices"}
                      </Typography>
                      <Button
                        variant="text"
                        onClick={() =>
                          setInvoiceGraphToggle(!invoiceGraphToggle)
                        }
                      >
                        <img
                          src="/images/change_view.png"
                          className="responsiveImage"
                          style={{ height: 20, width: 20 }}
                          alt="reset"
                        />
                      </Button>
                    </Box>

                    <hr />

                    {graphData &&
                    Object.keys(graphData).length > 0 &&
                    graphData?.loadingGraph ? (
                      <ReactFlipCard
                        containerStyle={{ width: "100%", height: "20%" }}
                        flipByProp={invoiceGraphToggle}
                        flipTrigger={"disabled"}
                        frontComponent={
                          <InvoiceGraph
                            graphdata={graphData}
                            height={"100%"}
                            ratio={isMobile ? 1.1 : screenWidth ? 5.8 : 3.1}
                            rightRange={true}
                          />
                        }
                        backComponent={
                          <InvoiceGraph
                            graphdata={graphData}
                            ratio={isMobile ? 1.1 : screenWidth ? 5.8 : 3.0}
                            height={"100%"}
                            type={"line"}
                          />
                        }
                      />
                    ) : (
                      <Typography sx={{ height: 230 }}>
                        {"No paid and unpaid invoices"}
                      </Typography>
                    )}
                  </Box>
                </CardContent>
              </Card>
            )}
          </>
        )}

        <Card
          sx={{
            marginBottom: 2,
            marginTop: 3,
            boxShadow: 10,
          }}
        >
          <CardContent
            sx={{
              marginTop: -2,
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#fff",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6" align="left" className="jobText">
                  {STRINGS.CandidateActivity}
                </Typography>
                <Button
                  variant="text"
                  onClick={() => setActivityGraphToggle(!activityGraphToggle)}
                >
                  <img
                    src="/images/change_view.png"
                    className="responsiveImage"
                    style={{ height: 20, width: 20 }}
                    alt="reset"
                  />
                </Button>
              </Box>

              <hr />

              {chartData &&
              Object.keys(chartData).length > 0 &&
              //@ts-ignore
              chartData?.labels?.length > 0 ? (
                <ReactFlipCard
                  containerStyle={{ width: "100%", height: "100%" }}
                  flipByProp={activityGraphToggle}
                  flipTrigger={"disabled"}
                  frontComponent={
                    <BarGraph
                      chartData={chartData}
                      height={"100%"}
                      ratio={isMobile ? 1.1 : screenWidth ? 5.8 : 3.1}
                    />
                  }
                  backComponent={
                    <BarGraph
                      chartData={chartData}
                      height={"100%"}
                      ratio={isMobile ? 1.1 : screenWidth ? 6.0 : 3.4}
                      type={"pie"}
                    />
                  }
                />
              ) : (
                <Typography sx={{ height: 140 }}>{"No open jobs"}</Typography>
              )}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </ResponsiveGrid>
  );
};

export default DashboardLanding;
