import moment from "moment";

export const dashboardAttendanceRow = (rowValue: Array<any>) => {
  const row = rowValue?.map((rowV, index) => {
    let firstIn =
      rowV.firstIn === "-"
        ? "00:00"
        : rowV.firstIn?.split(" ")[1] + " " + rowV.firstIn?.split(" ")[2];
    let lastOut =
      rowV.lastOut === "-"
        ? "00:00"
        : rowV.lastOut?.split(" ")[1] + " " + rowV.lastOut?.split(" ")[2];

    if (rowV.status === "Weekend") return false;
    let columns: any = [];
    columns.push(
      {
        id: rowV.id,
        name: `0${index}`,
        type: "title",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "600",
        textTransform: "capitalize",
        align: "left",
        width: 150,
      },
      {
        id: rowV.id,
        name: `${moment(rowV.date).format("dddd")}`,
        type: "title",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "600",
        textTransform: "capitalize",
        align: "left",
        width: 150,
      },
      {
        id: rowV.id,
        name: `${firstIn}-${lastOut}`,
        type: "title",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
        align: "left",
        width: 150,
      },
      {
        id: rowV.id,
        name: `${rowV.shiftName}`,
        type: "title",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
        align: "left",
      },
    );
    return { ...rowV, columns, rowV };
  });

  return row;
};
