import React from "react";
import { Avatar, Box, Card, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const TeamMemberTimesheetMobileGrid = ({ data }) => {
  const navigate = useNavigate();
  const handleCellClick = (cellData) => {
    const id = cellData.timesheetId;
    const date = cellData.timesheetName
      .replace("Timesheet", "")
      .replace("(", "")
      .replace(")", "");

    navigate("/timesheets/timesheet-details", {
      state: { date, id },
    });
  };
  return (
    <>
      {data.map((items) => {
        const statusColor =
          items.status === "Approved"
            ? "#00E08C"
            : items.status === "Pending"
              ? "#F27F0D"
              : items.status === "Reject"
                ? "#E61A1A"
                : "#000000";

        const timeSheetName = items.timesheetName
          .replace("Timesheet", "")
          .replace("(", "")
          .replace(")", "");
        return (
          <Grid className="responsive-tbl">
            <Card
              key={items.timesheetId}
              onClick={() => {
                handleCellClick(items);
              }}
              className="tbl-card"
              sx={{ minWidth: 275, marginBottom: 3, width: "100%" }}
            >
              <Grid container spacing={2} className="tbl-header">
                <Grid item xs={12}>
                  <Typography
                    sx={{ ml: 1, mb: 2 }}
                    variant="h6"
                    component="div"
                    noWrap
                  >
                    {timeSheetName}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  alignItems="center"
                  sx={{ ml: 1, mb: 2 }}
                >
                  <Avatar sx={{ height: 30, width: 30, mr: 1 }} />
                  <Typography variant="body2" flexGrow={1}>
                    {items.client}
                  </Typography>
                  <Typography variant="body2">Timesheet Name</Typography>
                </Grid>
              </Grid>
              <Box className="tbl-body">
                <Grid item xs={12} display="flex">
                  <Typography variant="body2" sx={{ mr: 4 }}>
                    Submitted Hours
                  </Typography>
                  <Typography variant="body2" flexGrow={1}>
                    {items.totalHours}Hrs
                  </Typography>
                  <Typography variant="body1" sx={{ color: statusColor }}>
                    {items.status}
                  </Typography>
                </Grid>
              </Box>
            </Card>
          </Grid>
        );
      })}
    </>
  );
};

export default TeamMemberTimesheetMobileGrid;
