import { createSlice, PayloadAction } from "@reduxjs/toolkit";

enum PERFORMANCE_TYPE {
  MONTHLY = "MONTHLY",
  REWARD = "REWARD",
  IMMEDIATEACTION = "IMMEDIATEACTION",
}

interface EmployeePerformance {
  feedbackId: number;
  client: string;
  feedBackType: PERFORMANCE_TYPE;
  score: number;
  from: string;
  date: string;
}

interface EmployeePerformanceState {
  employeeName: string;
  employeeType: string;
  employeeId: string; // different from the query param
  clientId: string;
  lastScore: number;
  isLastMonthFeedbackSubmitted: boolean;
  feedBackList: EmployeePerformance[];
  chartData: number[];
  selectedFeedbackDetails: object;
  submitFeedbackInfo: {
    submitted: boolean;
    isLoading: boolean;
    error: string | null;
  };
  isLoading: boolean;
  error: string | null;
  feedbackSubmittedMonths: number[];
  annualFeedbackDetails: object;
  annualSubmitFeedbackInfo: {
    submitted: boolean;
    isLoading: boolean;
    error: string | null;
  };
}

const initialState: EmployeePerformanceState = {
  employeeName: "",
  employeeType: "",
  employeeId: "",
  clientId: "",
  lastScore: 0,
  isLastMonthFeedbackSubmitted: false,
  feedBackList: [],
  chartData: [],
  selectedFeedbackDetails: {},
  submitFeedbackInfo: {
    submitted: false,
    isLoading: false,
    error: null,
  },
  isLoading: false,
  error: null,
  feedbackSubmittedMonths: [],
  annualFeedbackDetails: {},
  annualSubmitFeedbackInfo: {
    submitted: false,
    isLoading: false,
    error: null,
  },
};

const employeeSlice = createSlice({
  name: "employeePerformance",
  initialState,
  reducers: {
    setEmployeePerformanceSuccess(
      state,
      action: PayloadAction<EmployeePerformanceState>,
    ) {
      state.isLoading = false;
      state.employeeId = action.payload.employeeId;
      state.employeeName = action.payload.employeeName;
      state.employeeType = action.payload.employeeType;
      state.clientId = action.payload.clientId;
      state.lastScore = action.payload.lastScore;
      state.isLastMonthFeedbackSubmitted =
        action.payload.isLastMonthFeedbackSubmitted;

      if (Array.isArray(action.payload.feedBackList)) {
        const transformedData = action.payload.feedBackList?.map(
          (feedback) => ({
            ...feedback,
            id: feedback.feedbackId,
          }),
        );

        state.feedBackList = transformedData;
      }
    },
    setEmployeePerformanceFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.feedBackList = [];
      state.chartData = [];
      state.error = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
      if (state.isLoading) {
        state.feedBackList = [];
        state.error = null;
      }
    },
    setEmployeeChartData(state, action: PayloadAction<number[]>) {
      state.chartData = action.payload;
    },
    setEmployeeFeedbackDetails(state, action: PayloadAction<object>) {
      state.selectedFeedbackDetails = action.payload;
    },
    setSubmitFeedbackInfo(state, action: PayloadAction<any>) {
      state.submitFeedbackInfo = action.payload;
    },
    resetSubmitFeedbackInfo(state) {
      state.submitFeedbackInfo = {
        submitted: false,
        isLoading: false,
        error: null,
      };
    },
    setFeedbackSubmittedMonths(state, action: PayloadAction<number[]>) {
      state.feedbackSubmittedMonths = action.payload;
    },
    clearEmployeePerformance(state) {
      return initialState;
    },
    setEmployeeAnnualFeedbackDetails(state, action: PayloadAction<{}>) {
      state.annualFeedbackDetails = action.payload;
    },
    setSubmitAnnualFeedbackInfo(state, action: PayloadAction<any>) {
      state.annualSubmitFeedbackInfo = action.payload;
    },
  },
});

export const {
  setEmployeePerformanceSuccess,
  setEmployeePerformanceFailure,
  setEmployeeChartData,
  setEmployeeFeedbackDetails,
  setIsLoading,
  setSubmitFeedbackInfo,
  resetSubmitFeedbackInfo,
  setFeedbackSubmittedMonths,
  clearEmployeePerformance,
  setEmployeeAnnualFeedbackDetails,
  setSubmitAnnualFeedbackInfo,
} = employeeSlice.actions;

export default employeeSlice.reducer;
