import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  right_column: {
    backgroundColor: "#F8F8FC",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export { useStyles };
