import CloseIcon from "@mui/icons-material/Close";
import {
  CircularProgress,
  Modal,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { resetPublicHolidays } from "../../../../redux/Dashboard/dashboardSlice";
import { sagaActions } from "../../../../redux/sagaActions";
import { useStyles } from "./styles";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`holiday-tabpanel-${index}`}
      aria-labelledby={`holiday-tab-${index}`}
      className={classes.tabContentContainer}
      {...other}
    >
      {value === index && (
        <Box pt={3} className={classes.tabContent}>
          {children}
        </Box>
      )}
    </div>
  );
};

const HolidayTable = ({ data }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table aria-label="holiday table">
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell>Holiday</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Day</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row: any, index: any) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.day}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const HolidayListModal = ({ isModalOpen, setIsModalOpen }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { publicHolidays } = useSelector((state: any) => state.dashboard);
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    const location = newValue === 0 ? "Pune" : "Philippines";
    fetchList(location);
  };

  const fetchList = async (location: string) => {
    if (!publicHolidays[location] || publicHolidays[location].length === 0) {
      dispatch({
        type: sagaActions.FETCH_PUBLIC_HOLIDAY_LIST,
        payload: location,
      });
    }
  };

  useEffect(() => {
    const location = value === 0 ? "Pune" : "Philippines";
    if (isModalOpen) {
      fetchList(location);
    }

    return () => {
      dispatch(resetPublicHolidays());
    };
  }, [isModalOpen]);

  return (
    <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <Box className={classes.modal}>
        <Box className={classes.modalHeader}>
          <Typography
            variant="h5"
            align="left"
            className={classes.customTypography}
          >
            Holiday list for {new Date().getFullYear()} -{" "}
            {new Date().getFullYear() + 1}
          </Typography>

          <IconButton onClick={() => setIsModalOpen(false)}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </Box>

        <Box className={classes.tabsContainer} mt={2}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Countries tabs"
          >
            <Tab label="India" id="holiday-tab-0" className={classes.tab} />

            <Tab
              label="Philippines"
              id="holiday-tab-1"
              className={classes.tab}
            />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          {publicHolidays.isLoading && (
            <Box className={classes.loader}>
              <CircularProgress />
            </Box>
          )}
          {!publicHolidays.isLoading && (
            <HolidayTable data={publicHolidays["Pune"] || []} />
          )}
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          {publicHolidays.isLoading && (
            <Box className={classes.loader}>
              <CircularProgress />
            </Box>
          )}
          {!publicHolidays.isLoading && (
            <HolidayTable data={publicHolidays["Philippines"] || []} />
          )}
        </CustomTabPanel>
      </Box>
    </Modal>
  );
};

export default HolidayListModal;
