import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHireCandidateStagingHelper } from "./Hire.helper";
import { styles } from "./HireStyle";

const CandidateListStageHeader = ({
  stageCandidateList,
  selectedStage,
  initialStage,
}) => {
  //@ts-ignore
  const { hireCandidateStaging, hireJobsDetail } = useSelector(
    (state: any) => state.hire,
  );

  const [stagesData, setStagesData] = useState<Array<any>>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (stagesData.length > 0) {
      if (initialStage) {
        const res = stagesData.find(
          (el) => el.code.toLowerCase() === initialStage?.toLowerCase(),
        );
        if (res) stageCandidateList(res);
        else stageCandidateList(stagesData[0]);
      } else {
        stageCandidateList(stagesData[0]);
      }
    }
  }, [stagesData, initialStage]);

  useEffect(() => {
    if (hireCandidateStaging?.stages?.length) {
      setStagesData(hireCandidateStaging.stages);
    }
  }, [hireCandidateStaging]);

  useEffect(() => {
    if (hireJobsDetail.reference) {
      getHireCandidateStagingHelper(hireJobsDetail.reference, dispatch);
    }
  }, [dispatch, hireJobsDetail]);
  const classes = styles();
  return (
    <Box className={classes.pageHeader}>
      <Grid spacing={2} p={2} display={"flex"} textAlign={"center"} gap={1}>
        {stagesData?.map((stage, index) => {
          return (
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-around"}
              key={index}
              flex={1}
              className={
                selectedStage?.code === stage?.code
                  ? classes.candidate_Selected_stage
                  : classes.candidate_stage
              }
              onClick={() => stageCandidateList(stage)}
            >
              <Box>
                <Typography variant="body1">{stage?.count}</Typography>
              </Box>
              <Box>
                <Typography variant="caption">{stage?.name}</Typography>
              </Box>
            </Box>
          );
        })}
      </Grid>
    </Box>
  );
};

export default memo(CandidateListStageHeader);
