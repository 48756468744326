import React, { useState, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Pie, getElementsAtEvent } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
);

const BarGraph = (props) => {
  const { chartData, height = "69%", type, ratio = 3 } = props;
  const navigate = useNavigate();
  const chartRef = useRef();
  const onClick = (event) => {
    //@ts-ignore
    console.log(getElementsAtEvent(chartRef?.current, event));
    //@ts-ignore
    if (getElementsAtEvent(chartRef?.current, event).length > 0) {
      navigate("/hire");
    }
  };

  const options = {
    responsive: true,
    aspectRatio: ratio,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  //@ts-ignore
  return type === "pie" ? (
    <Pie
      options={{
        maintainAspectRatio: true,
        aspectRatio: ratio,
        responsive: true,
        animation: {
          animateRotate: false,
          animateScale: true,
        },
        plugins: {
          legend: {
            labels: {
              font: {
                size: 18,
              },
            },

            display: true,
            position: "right",
          },
        },
      }}
      height={height}
      data={chartData}
      onClick={onClick}
      ref={chartRef}
    />
  ) : (
    <Bar
      height={height}
      options={options}
      data={chartData}
      onClick={onClick}
      ref={chartRef}
    />
  );
};

export default BarGraph;
