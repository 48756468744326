import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../../services/service";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

type InterviewQuestionRequest = {
  internalCode: string;
};

type Question = {
  id: string;
  question: string;
  hint: string;
};

type InterviewQuestionResponse = {
  status: number;
  successful: boolean;
  message: string;
  data: Array<Question>;
};
export const mandatoryQuestionsApi = createApi({
  reducerPath: "mandatoryQuestions",
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL + "",
  }),
  endpoints: (builder) => ({
    getInterviewQuestions: builder.query<
      InterviewQuestionResponse,
      InterviewQuestionRequest
    >({
      query: ({ internalCode }) => ({
        url: "/getInterviewQuestions",
        method: "POST",
        body: {
          internalCode,
        },
      }),
    }),
  }),
});

export const { useGetInterviewQuestionsQuery } = mandatoryQuestionsApi;
