import Moment from "moment";
import { Dispatch } from "@reduxjs/toolkit";
import { sagaActions } from "../../redux/sagaActions";

export const fetchDashboardData = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.FETCH_DASHBOARD_DATA, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const fetchDashboardCalendar = async (
  payload,
  dispatch: Dispatch<any>,
) => {
  try {
    dispatch({ type: sagaActions.FETCH_DASHBOARD_CALENDAR, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const fetchCountAndFilterData = async (dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.FETCH_DASHBOARD_FILTER });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};
