import { Dispatch } from "@reduxjs/toolkit";
import _ from "lodash";
import { sagaActions } from "../../redux/sagaActions";
import moment from "moment";
export const Columns = [
  {
    id: 1,
    name: "Date",
    type: "heading",
    sortable: false,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
    width: "200px",
  },
  {
    id: 2,
    name: "Check in time",
    type: "heading",
    sortable: false,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
    width: "400px",
  },
  {
    id: 3,
    name: "Check out time",
    type: "heading",
    sortable: false,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
    width: "200px",
  },
  {
    id: 4,
    name: "Worked hours",
    type: "heading",
    sortable: false,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
    width: "150px",
  },
];

export const attendanceRow = (rowValue: Array<any>) => {
  const row = rowValue?.map((rowV) => {
    const rowVDate = moment(rowV?.date, "YYYY MM DD");
    const todayDate = moment();
    let columns: any = [];
    const isLastOutToday =
      rowV?.firstIn !== "-" && rowVDate.isSame(todayDate, "day");
    columns.push(
      {
        id: rowV.id,
        name: rowV.date
          ? `${rowV.date} | ${moment(rowV.date).format("dddd")}`
          : "-",
        type: "title",
        experience: false,
        display: true,
        editable: false,
        color: rowVDate.isSame(todayDate, "day") ? "#00E08C" : "#000000",
        fontSize: 12,
        fontWeight: "600",
        textTransform: "capitalize",
      },
      {
        id: rowV.id,
        name:
          rowV?.firstIn !== "-"
            ? rowV?.firstIn?.substring(11, 19)
            : rowV?.status?.includes("Holiday")
              ? rowV?.status
              : "00:00",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
        progressBar: rowVDate.isSame(todayDate, "day"),
      },
      {
        id: rowV.id,
        name:
          rowV?.lastOut !== "-"
            ? rowV?.lastOut?.substring(11, 19)
            : isLastOutToday || rowV?.status?.includes("Holiday")
              ? ""
              : "00:00",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.id,
        name: rowV?.status?.includes("Holiday") ? "" : rowV.totalHours,
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
    );
    return { ...rowV, columns, rowV };
  });

  return row;
};

export const getAttendanceList = (dispatch: Dispatch<any>, request) => {
  try {
    dispatch({
      type: sagaActions.GET_ATTENDANCE,
      payload: request,
    });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const addPunchInPuchOut = (dispatch: Dispatch<any>, request) => {
  try {
    dispatch({
      type: sagaActions.ADD_CHECK_IN_CHECK_OUT,
      payload: request,
    });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};
