import { Dispatch } from "@reduxjs/toolkit";
import _ from "lodash";
import { sagaActions } from "../../redux/sagaActions";

export const getOpportunities = (dispatch: Dispatch<any>, request) => {
  try {
    dispatch({
      type: sagaActions.GET_OPPORTUNITIES_FOR_TEAMMEMBER,
      payload: request,
    });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getJobDetailById = (dispatch: Dispatch<any>, request) => {
  try {
    dispatch({
      type: sagaActions.GET_DETAIL_BY_ID,
      payload: request,
    });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};
