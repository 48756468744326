import { Paper } from "@mui/material";
import CustomizedDataGrid from "../../shared/components/CustomizedDataGrid";
import { getColumns } from "./constants/Columns";

const HireList = ({
  jobRequestList,
  totalCount,
  isLoading,
  pageSize,
  pageNumber,
  handlePageChange,
  handlePageSizeChange,
  handleMenuClick,
  selectedRequestType,
}) => {
  const getRowId = (row) => row.job_Id;
  const columns = getColumns(selectedRequestType, handleMenuClick);

  return (
    <Paper className="hire-list-container">
      <CustomizedDataGrid
        version={2}
        customClassName="hire-list"
        rows={jobRequestList}
        columns={columns}
        totalRowCount={totalCount}
        pageNumber={pageNumber}
        pageSize={pageSize}
        rowHeight={74}
        getRowId={getRowId}
        isDataLoading={isLoading}
        handlePageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
    </Paper>
  );
};

export default HireList;
