import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import ImmediateActionModal from "../modal/giveFeedback/ImmediateActionModal";
import MonthlyFeedbackModal from "../modal/giveFeedback/MonthlyFeedbackModal";
import RewardModal from "../modal/giveFeedback/RewardModal";
import { userAccess } from "../../../../utils/CommonFunction";
import { teamMember } from "../../../../Constants";
import { usePermissions } from "../../../../utils/permission";

const FeedbackMenu = ({ searchable = false, onSubmit, tabValue = 1 }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [feedbackModalType, setFeedbackModalType] = useState("");
  const { Performance_AddReward_Feedback_Action } = usePermissions();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMenuClick = (type) => {
    setFeedbackModalType(type);
    handleClose();
  };

  const handleOnSubmit = () => {
    onSubmit();
    setFeedbackModalType("");
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      {Performance_AddReward_Feedback_Action && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            key="give-feedback"
            onClick={() => onMenuClick("monthly")}
            disabled={tabValue === 0 ? true : false}
          >
            Give Feedback
          </MenuItem>

          <MenuItem key="reward" onClick={() => onMenuClick("reward")}>
            Reward
          </MenuItem>

          <MenuItem key="action" onClick={() => onMenuClick("immediateAction")}>
            Immediate Action
          </MenuItem>
        </Menu>
      )}

      {feedbackModalType === "monthly" && (
        <MonthlyFeedbackModal
          showModal={true}
          onSubmit={handleOnSubmit}
          onCancel={() => setFeedbackModalType("")}
          searchable={searchable}
        />
      )}

      {feedbackModalType === "reward" && (
        <RewardModal
          showModal={true}
          onSubmit={handleOnSubmit}
          onCancel={() => setFeedbackModalType("")}
          searchable={searchable}
        />
      )}

      {feedbackModalType === "immediateAction" && (
        <ImmediateActionModal
          showModal={true}
          onSubmit={handleOnSubmit}
          onCancel={() => setFeedbackModalType("")}
          searchable={searchable}
        />
      )}
    </>
  );
};

export default FeedbackMenu;
