import { useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { sagaActions } from "../../../../../redux/sagaActions";
import CustomRatings from "../../../../../shared/components/Ratings/CustomRatings";
import { userAccess } from "../../../../../utils/CommonFunction";
import { useStyles } from "../styles";
import SearchableEmployeeField from "./SearchableEmployeeField";

const MonthlyFeedbackModal = ({
  searchable = false,
  showModal,
  onSubmit,
  onCancel,
  noClient = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const empId = useParams().id;
  const { ClientId } = userAccess();

  const [error, setError] = useState("");
  const [score, setScore] = useState(0);
  const [comment, setComment] = useState("");
  const [shareFeedback, setShareFeedback] = useState(false);

  const [selectedEmployeeDetails, setSelectedEmployeeDetails] = useState({
    id: null,
    clientId: null,
    lastScore: null,
    isLastMonthFeedbackSubmitted: false,
  });

  const { employeeName, lastScore, clientId, isLastMonthFeedbackSubmitted } =
    useSelector((state: any) => state.performance.employee);

  const validateForm = () => {
    if (!empId && selectedEmployeeDetails?.id === null) {
      return false;
    }

    if (score === 0) {
      return false;
    }

    if (comment.trim().length === 0) {
      return false;
    }

    if (noClient) {
      return false;
    }

    if (isLastMonthSubmitted()) {
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }

    const previousMonthStartDate = moment()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    const previousMonthYear = moment().subtract(1, "month").year();

    const month = moment().subtract(1, "month").month() + 1;

    const { userId, firstName, lastName } = userAccess();

    let payload = {
      employeeId: searchable ? selectedEmployeeDetails?.id : empId,
      feedBackType: 1,
      clientId: searchable ? selectedEmployeeDetails?.clientId : clientId,
      userId: userId,
      from: firstName + " " + lastName,
      date: previousMonthStartDate,
      month: month,
      year: previousMonthYear,
      score,
      comment,
      isShareOn: shareFeedback,
    };

    dispatch({ type: sagaActions.ADD_FEEDBACK, payload });
    onSubmit();
  };

  const getPreviousScore = () => {
    if (searchable) {
      return selectedEmployeeDetails.lastScore;
    }

    return lastScore;
  };

  const isLastMonthSubmitted = () => {
    if (searchable) {
      return selectedEmployeeDetails.isLastMonthFeedbackSubmitted;
    }

    return isLastMonthFeedbackSubmitted;
  };

  return (
    <Modal open={showModal} onClose={() => {}}>
      <Box className={classes.modal}>
        <Typography
          variant="h4"
          textAlign="center"
          fontWeight={400}
          p={2}
          mb={2}
        >
          Team member feedback
        </Typography>

        {searchable && (
          <SearchableEmployeeField
            onSelect={(employee) => setSelectedEmployeeDetails(employee)}
          />
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          mt={2}
          sx={{
            "@media (max-width: 768px)": {
              flexDirection: "column",
            },
          }}
        >
          <Box>
            <Typography variant="body1" fontWeight={500} mb={1}>
              How did the team member perform this month?
            </Typography>

            <CustomRatings
              rating={score}
              onChange={setScore}
              color={ClientId === 0 ? "#6781F6" : "#DB9BF3"}
            />
          </Box>

          {!!getPreviousScore() && (
            <Typography variant="body2" color="#707D84" mt={1} fontSize={12}>
              Previous feedback: {getPreviousScore()}
            </Typography>
          )}
        </Box>

        <TextField
          error={!!error}
          helperText={error}
          label="Comment"
          name="comment"
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          margin="normal"
          fullWidth
          multiline
          rows={4}
          onBlur={validateForm}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={shareFeedback}
              onChange={(event) => setShareFeedback(event.target.checked)}
              name="shareFeedback"
              color="primary"
              sx={{
                "&.MuiCheckbox-colorPrimary.Mui-checked": {
                  color: "#002dff",
                },
              }}
            />
          }
          label="Share the feedback with team member?"
          sx={{
            "& .MuiFormControlLabel-label": {
              fontSize: "12px",
            },
          }}
        />

        {isLastMonthSubmitted() && (
          <FormHelperText error={true} sx={{ textAlign: "right" }}>
            Feedback cannot be given as feedback for this month has already been
            submitted.
          </FormHelperText>
        )}

        {noClient && (
          <FormHelperText error={true} sx={{ textAlign: "right" }}>
            Feedback cannot be given as this team member is not currently linked
            to a client.
          </FormHelperText>
        )}

        <Box className={classes.actions}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!validateForm()}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default MonthlyFeedbackModal;
