import _ from "lodash";
import { all, delay, put, takeLatest } from "redux-saga/effects";
import { Status } from "../../services/ApiStatus";
import { sagaActions } from "../sagaActions";
import { jobsFail, jobsSuccess, jobsPending } from "./opportunitiesSlice";
import { opportunities } from "../../services/Opportunities";

function* fetchOpportunities(payload: any = "") {
  try {
    if (!_.isUndefined(payload?.payload)) {
      yield put(jobsPending());
    }
    const response = yield opportunities(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(jobsSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(jobsFail(response?.message));
      }
    }
  } catch (error: any) {
    yield put(jobsFail(error?.message));
  }
}

export default function* watcherSaga() {
  yield all([
    yield takeLatest(
      sagaActions.GET_OPPORTUNITIES_FOR_TEAMMEMBER,
      fetchOpportunities,
    ),
  ]);
}
