import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobs: [],
  jobsLoading: false,
};

const jobsSlice = createSlice({
  name: "opportunities",
  initialState,
  reducers: {
    jobsPending: (state) => {
      state.jobsLoading = true;
    },
    jobsSuccess: (state, { payload }) => {
      state.jobsLoading = false;
      state.jobs = payload;
    },
    jobsFail: (state, { payload }) => {
      state.jobsLoading = false;
      state.jobs = [];
    },
  },
});

export const { jobsPending, jobsSuccess, jobsFail } = jobsSlice.actions;

export default jobsSlice.reducer;
