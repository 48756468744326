import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { memo, useCallback, useEffect } from "react";
import { useAlert } from "react-alert";
import { useDeleteTaskMutation } from "../../redux/Task/TaskApi";

const DeleteTaskSubPage = ({ task }) => {
  const [deleteTaskApi, { isLoading, isError, isSuccess }] =
    useDeleteTaskMutation();
  const alert = useAlert();
  const deleteTask = useCallback(async () => {
    deleteTaskApi({
      taskId: task.id,
    });
  }, [deleteTaskApi, task]);

  useEffect(() => {
    if (isSuccess) {
      alert.success("Task deleted successfully");
    }
  }, [alert, isSuccess]);

  useEffect(() => {
    if (isError) {
      alert.error("Error while deleting task");
    }
  }, [alert, isError]);
  return (
    <Grid
      container
      justifyContent={"space-between"}
      alignItems={"center"}
      mt={1}
    >
      <Grid container item alignItems={"center"} flex={1} gap={1}>
        <img src="/images/delete-bg.svg" alt=""></img>
        <Typography variant="body2">
          Are you sure you want to delete the task?
        </Typography>
      </Grid>
      <Grid item>
        <Button
          sx={{
            minWidth: "116px",
          }}
          disabled={isLoading}
          onClick={deleteTask}
          variant="contained"
        >
          {isLoading ? (
            <CircularProgress size={20}></CircularProgress>
          ) : (
            "Delete task"
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

export default memo(DeleteTaskSubPage);
