import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  info_header: {
    backgroundColor: "#000000",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px 0px",
  },
  login_container: {
    backgroundColor: "#F8F8FC",
    width: "100%",
    maxWidth: "800px",
    padding: "20px",
    margin: "30px auto",
  },
}));

export { useStyles };
