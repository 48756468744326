import React from "react";
import { useStyles } from "./styles";
import { useAlert } from "react-alert";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { googleRegistrationSchema } from "../../../schemas";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";

//styles
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Container,
} from "@mui/material";

import { registerGoogleAction } from "./Company.helper";
import { STRINGS } from "../../../Strings";
import { LABELS } from "../../../Labels";

const initialValues = {
  UserId: "",
  FirstName: "",
  LastName: "",
  Email: "",
  CompanyName: "",
};

const CompanyName: React.FunctionComponent = () => {
  const location: Location | unknown | any = useLocation();
  const { googleId, firstName, lastName, email } = location?.state || {};
  const alert = useAlert();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { values, errors, dirty, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: googleRegistrationSchema,
      onSubmit: (values, action) => {
        const creds = {
          UserId: googleId,
          FirstName: firstName,
          LastName: lastName,
          Email: email,
          CompanyName: values.CompanyName,
        };

        registerGoogleAction(creds, alert, dispatch, navigate);
        action.resetForm();
      },
    });

  return (
    <>
      <Container maxWidth="xl" disableGutters={true}>
        <Grid
          container
          component="main"
          spacing={0}
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item sm={4} md={6}>
            <Box
              component="img"
              src="/images/Teamified-Logo-Black.svg"
              className="logo_rbl"
              sx={{
                maxWidth: "-webkit-fill-available",
                padding: {
                  md: "24px 0px 0px 43px",
                  sm: "24px 0px",
                },
              }}
              alt="Teamified logo"
            />
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <img src="/images/forgot-password-banner.svg" alt="log in" />
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} md={6} className={classes.right_column}>
            <Box sx={{ display: "grid" }}>
              <Box>
                <Typography variant="h3" align="center" mb={3}>
                  {STRINGS.EnterCompanyName}
                </Typography>
              </Box>

              <Box>
                <Box
                  sx={{ display: "block", maxWidth: "480px", margin: "auto" }}
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <TextField
                    error={!!errors.CompanyName}
                    helperText={errors.CompanyName}
                    margin="normal"
                    fullWidth
                    id="CompanyName"
                    label="Company Name"
                    name="CompanyName"
                    variant="outlined"
                    autoComplete="off"
                    value={values.CompanyName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <Button
                    disabled={!dirty}
                    type="submit"
                    fullWidth
                    size="large"
                    variant="contained"
                    sx={{ mt: 2 }}
                  >
                    {LABELS.submit}
                  </Button>
                </Box>
              </Box>
              {/* <Typography variant="body2" align="center" mt={3}>
                {`${STRINGS.ReadyToBuild} `}
                <Link to="/Register">{STRINGS.SignUpNow}</Link>{" "}
              </Typography> */}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CompanyName;
