import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { clientStyles } from "./styles";
//styles
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { useDispatch, useSelector } from "react-redux";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Button, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setClientCompleteLoginFirstTime } from "../../../redux/User/userSlice";
import FillCompanyDetails from "./FillCompanyDetails";
import { getCompanyDetails } from "../../Client/ProfileLanding/Profile.helper";
import { getProfile } from "../../Client/UserDetails/User.helper";
import EditUser from "../../Client/UserDetails/EditUser";
import { STRINGS } from "../../../Strings";

const steps = ["Sign up", "Company Details", "Individual Details"];

const ClientOnboarding = () => {
  const [stage, setStage] = React.useState(1);
  const classes = clientStyles();
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userInfo } = useSelector(
    //@ts-ignore
    (state) => state.login,
  );
  const { isLoading, user } = useSelector(
    //@ts-ignore
    (state) => state.user,
  );
  useEffect(() => {
    getCompanyDetails(dispatch);
    getProfile(dispatch);
  }, []);

  const pressNext = () => {
    setStage((stage) => stage + 1);
    if (stage == 2) {
      dispatch(setClientCompleteLoginFirstTime());
      navigate("/dashboard");
    }
  };

  return (
    <Grid container component="main">
      <Box mt={2} mb={2} ml={2} mr={2} p={2} className={classes.main_container}>
        <Typography
          variant="h3"
          sx={{
            pl: 5,
          }}
        >
          {STRINGS.Welcome.params({ user: userInfo.firstName })}
        </Typography>
        <img src="/images/welcome.png" alt="Teamified logo" />
      </Box>
      <Grid container component="main">
        <Box mt={2} mb={2} ml={2} mr={2} className={classes.text_container}>
          <Grid item md={6}>
            <Typography variant="h6">{STRINGS.GetStarted}</Typography>
          </Grid>
          <Grid item md={6}>
            <Stepper activeStep={stage} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Box>
      </Grid>
      <Grid container component="main" className={classes.com_container}>
        {stage === 1 && (
          <Box pt={2} sx={{ flex: 1 }}>
            <Typography
              variant="h6"
              sx={{
                pl: 5,
              }}
            >
              {STRINGS.CompanyDetails}
            </Typography>
            <Divider sx={{ pt: 1 }} />
            <FillCompanyDetails pressNext={pressNext} />
          </Box>
        )}

        {stage === 2 && (
          <Box pt={2} sx={{ flex: 1 }}>
            <Typography
              variant="h6"
              sx={{
                pl: 5,
              }}
            >
              {STRINGS.IndividualDetails}
            </Typography>
            <Divider sx={{ pt: 1 }} />
            <EditUser
              user={user}
              isLoading={isLoading}
              showCancel={false}
              pressNext={pressNext}
              submitLabel={STRINGS.Save}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default ClientOnboarding;
