import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../../services/service";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

type AddOrUpdateMeetingRequest = {
  id?: number;
  title: string;
  description: string;
  meetingStartDate: string;
  meetingEndDate: string;
  role: string;
  attendeesEmail: string[];
  clientId: string;
  userId: string;
  candidateId: string;
  eventId?: string;
  updatedBy?: string;
  interviewerName: string;
  clientName: string;
  candidateName: string;
  jobId: string;
  scheduledBy: string;
  token: string;
};
type AddMeetingResponse = {};

export type EventObject = {
  id: number;
  title: string;
  description: string;
  meetingStartedOn: string;
  meetingEndedOn: string;
  role: string;
  attendeesEmail: string;
  link: string;
  deletedAt: string;
  deletedBy: string;
  deletedReason: string;
  clientId: string;
  userId: string;
  candidateId: string;
  eventId?: string;
  updatedBy?: string;
  interviewerName: string;
  clientName: string;
  candidateName: string;
  jobId: string;
  scheduledBy: string;
};

type GetMeetingsRequest = {
  userId?: string;
  candidateId?: string;
  candidateStage?: string;
  clientId?: string;
};
type GetMeetingsResponse = {
  data: Array<EventObject>;
  message: string;
  status: number;
  success: boolean;
};

type DeleteMeetingRequest = {
  interviewId: number;
  eventId: string;
  deletedReason: string;
  deletedAt: string;
  deletedBy: string;
  token: string;
};

type DeleteMeetingResponse = any;

export const candidateMeetingApi = createApi({
  reducerPath: "candidateMeetings",
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL || "",
  }),
  tagTypes: ["interviewList"],
  endpoints: (builder) => ({
    addInterviewMeeting: builder.mutation<
      AddMeetingResponse,
      AddOrUpdateMeetingRequest
    >({
      query: (req) => ({
        method: "POST",
        url: "addInterviewMeeting",
        body: req,
      }),
      invalidatesTags: ["interviewList"],
    }),

    getAllMeetings: builder.query<GetMeetingsResponse, GetMeetingsRequest>({
      query: (req) => ({
        method: "POST",
        url: "getAllMeetings",
        body: req,
      }),
      providesTags: ["interviewList"],
    }),

    updateInterviewMeeting: builder.mutation<
      AddMeetingResponse,
      AddOrUpdateMeetingRequest
    >({
      query: (req) => ({
        method: "POST",
        url: "updateInterviewMeeting",
        body: req,
      }),
      invalidatesTags: ["interviewList"],
    }),
    deleteInterviewMeeting: builder.mutation<
      DeleteMeetingResponse,
      DeleteMeetingRequest
    >({
      query: (req) => ({
        method: "POST",
        url: "deleteInterview",
        body: req,
      }),
    }),
  }),
});

export const {
  useAddInterviewMeetingMutation,
  useGetAllMeetingsQuery,
  useUpdateInterviewMeetingMutation,
  useDeleteInterviewMeetingMutation,
} = candidateMeetingApi;
