import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { sagaActions } from "../../../../redux/sagaActions";
import FormikInput from "../../../../shared/components/FormikInput";
import {
  MonthsArray,
  SalaryIncreaseNominationTypes,
  SalaryIncreaseTypes,
  userAccess,
} from "../../../../utils/CommonFunction";

export const numberValidation = Yup.number()
  .typeError("Must be number")
  .integer("Must be number")
  .min(1, "minimum 1")
  .max(2147483647, "the value should be maximum");

let staticSchema = {};

const percentageSchema = {
  amount: numberValidation.required("Please enter valid percentage"),
};
const amountSchema = {
  amount: numberValidation.required("Please enter valid amount"),
};

const monthlySchema = {
  month: Yup.string().required("Please select a month!"),
};

let noRaiseCommentSchema: any = {
  questionComment_7: Yup.string().required("Comment required"),
};

const AnnualFeedbackDetailsForm = ({
  allQuestions,
  employeeDetails,
  setShowModal,
  handleRedirectBack,
}) => {
  const dispatch = useDispatch();

  const [feedbackFormSchema, setFeedBackFormSchema] = useState(staticSchema);
  const [questionObject, setQuestionObject] = useState([]);
  const [formLoading, setFormLoading] = useState(false);
  const [salaryIncreaseInputCommentName, setSalaryIncreaseInputCommentName] =
    useState("");

  const [formInitialValue, setFormInitialValue] = useState({
    salaryIncrease: "3",
    duration: "1",
    amount: "",
    month: "",
    employeeName: employeeDetails.employeeName,
  });

  useEffect(() => {
    let pushQuestionIds: any = [];
    let schemaObject: any = {};
    let formInitialValueObjects: any = {};

    allQuestions?.map((item) => {
      let singleQuestionObject: any = {};
      let singleSchemaObject = {};
      let singleInitialObject = {};
      singleQuestionObject["id"] = item.id;
      singleQuestionObject[`questionID_${item.id}`] = item.id;
      singleQuestionObject[`questionText_${item.id}`] = item.text;
      singleQuestionObject[`questionComment_${item.id}`] = "";
      singleQuestionObject[`questionScore_${item.id}`] = "";
      singleQuestionObject[`salaryIncreaseQuestion_${item.id}`] =
        item.salaryIncreaseQuestion;
      singleQuestionObject[`acceptRatings_${item.id}`] = item.acceptRatings;

      if (!item.salaryIncreaseQuestion) {
        singleInitialObject[`questionComment_${item.id}`] = "";
        singleInitialObject[`questionScore_${item.id}`] = "";

        singleSchemaObject[`questionComment_${item.id}`] =
          Yup.string().required("Comment required");

        if (item.acceptRatings) {
          singleSchemaObject[`questionScore_${item.id}`] =
            Yup.string().required("Rating required");
        }

        schemaObject = { ...schemaObject, ...singleSchemaObject };
      } else {
        setSalaryIncreaseInputCommentName(`questionComment_${item.id}`);
        singleInitialObject[`questionComment_${item.id}`] = "";
        noRaiseCommentSchema[`questionComment_${item.id}`] =
          Yup.string().required("Comment required");
      }

      formInitialValueObjects = {
        ...formInitialValueObjects,
        ...singleInitialObject,
      };

      pushQuestionIds.push(singleQuestionObject);
    });

    setQuestionObject(pushQuestionIds);
    staticSchema = schemaObject;
    setFeedBackFormSchema(
      Yup.object().shape({
        ...schemaObject,
        ...noRaiseCommentSchema,
      }),
    );
    setFormInitialValue({ ...formInitialValue, ...formInitialValueObjects });
    setFormLoading(false);
  }, []);

  const handleSubmit = (data: any) => {
    setShowModal(true);
    let submittedQuestionsArray: any = [];
    const monthId = data.month;

    const monthName = MonthsArray?.find(
      (item) => item?.value === monthId,
    )?.name;

    const { userId, firstName, lastName } = userAccess();

    allQuestions?.map((item) => {
      const questionObject = {
        questionID: item.id,
        questionText: item.text,
      };

      if (item.salaryIncreaseQuestion) {
        questionObject["salaryIncreaseNomination"] = data.salaryIncrease;
        questionObject["salaryIncreaseNominationType"] = data.duration;

        if (data.salaryIncrease === "3") {
          questionObject["questionComment"] =
            data[`questionComment_${item.id}`];
        }

        if (data.duration === "1") {
          questionObject["monthID"] = data.month;
          questionObject["month"] = monthName;
        }
        if (data.salaryIncrease === "1") {
          questionObject["amount"] = data.amount;
        } else if (data.salaryIncrease === "2") {
          questionObject["percentage"] = data.amount;
        }
      } else {
        questionObject["questionComment"] = data[`questionComment_${item.id}`];
        questionObject["questionScore"] = data[`questionScore_${item.id}`];
      }

      submittedQuestionsArray.push(questionObject);
    });
    const formData = {
      performanceAnualFeedbackQuestionsCommentsReq: submittedQuestionsArray,
      employeeID: employeeDetails.employeeId,
      clientId: employeeDetails.clientId,
      userId: userId,
      employeeName: employeeDetails.name,
      feedBackType: 4,
      score: 0,
      date: new Date(),
      month: moment(new Date()).format("MM"),
      year: new Date().getFullYear() - 1,
      from: `${firstName} ${lastName}`,
    };

    dispatch({
      type: sagaActions.SUBMIT_ANNUAL_FEEDBACK,
      payload: formData,
    });
  };

  const handleFormSchemaChange = (formikAllProps, currentValue, inputName) => {
    formikAllProps.setErrors(salaryIncreaseInputCommentName, "");
    const salaryIncreaseValue =
      inputName === "salaryIncrease"
        ? currentValue
        : formikAllProps.values.salaryIncrease;
    const durationValue =
      inputName === "duration" ? currentValue : formikAllProps.values.duration;

    if (salaryIncreaseValue !== "3") {
      let schemaObject = {};

      if (salaryIncreaseValue === "1") {
        schemaObject = { ...schemaObject, ...amountSchema };
      }

      if (salaryIncreaseValue === "2") {
        schemaObject = { ...schemaObject, ...percentageSchema };
      }

      if (durationValue === "1") {
        schemaObject = { ...schemaObject, ...monthlySchema };
      }

      setFeedBackFormSchema(
        Yup.object().shape({
          ...staticSchema,
          ...schemaObject,
        }),
      );
    } else {
      setFeedBackFormSchema(
        Yup.object().shape({
          ...staticSchema,
          ...noRaiseCommentSchema,
        }),
      );
    }
  };

  return formLoading ? (
    <CircularProgress />
  ) : (
    <Box
      sx={{
        padding: "0px 15px",
      }}
    >
      <Formik
        initialValues={formInitialValue}
        enableReinitialize
        validationSchema={feedbackFormSchema}
        onSubmit={(data) => handleSubmit(data)}
      >
        {(formikProps) => {
          return (
            <Form>
              <Box className="annaul-feeback-no-filled-form-header">
                <Typography variant="h5">
                  Annual feedback to {employeeDetails.employeeName}
                </Typography>
              </Box>
              <Box>
                {questionObject?.map((item: any, index) =>
                  !item[`salaryIncreaseQuestion_${item.id}`] ? (
                    <Box key={"form_" + item.id}>
                      {item[`acceptRatings_${item.id}`] ? (
                        <FormikInput
                          label={
                            `Q${index + 1}. ` + item[`questionText_${item.id}`]
                          }
                          name={`questionScore_${item.id}`}
                          formikProps={formikProps}
                          className="annual-feedback-rating-form"
                          options={undefined}
                          inputType="custom-rating"
                          precision={0.5}
                          size="large"
                          starIconStyle={{ width: "50px", height: "50px" }}
                        />
                      ) : (
                        <Typography
                          variant="h6"
                          style={{ marginBottom: "10px" }}
                        >
                          {`Q${index + 1}. ` + item[`questionText_${item.id}`]}
                        </Typography>
                      )}

                      <FormikInput
                        label="Comment"
                        name={`questionComment_${item.id}`}
                        formikProps={formikProps}
                        className="comment-box-custom-form-control"
                        options={undefined}
                        fullWidth
                        multiline
                        rows={2}
                      />
                    </Box>
                  ) : (
                    <>
                      <FormikInput
                        label={
                          `Q${index + 1}. ` + item[`questionText_${item.id}`]
                        }
                        name="salaryIncrease"
                        formikProps={formikProps}
                        className="radio-button-performance"
                        fullWidth
                        multiline
                        rows={2}
                        schemaOnChangeFun={(value) =>
                          handleFormSchemaChange(
                            formikProps,
                            value,
                            "salaryIncrease",
                          )
                        }
                        schemaChange
                        inputType="radio-button"
                        options={SalaryIncreaseTypes}
                      />
                      <Box className="salary-increase-flax">
                        {formikProps.values.salaryIncrease === "2" ||
                        formikProps.values.salaryIncrease === "1" ? (
                          <>
                            <FormikInput
                              name="duration"
                              formikProps={formikProps}
                              className="radio-button-performance"
                              inputType="radio-button"
                              options={SalaryIncreaseNominationTypes}
                              schemaOnChangeFun={(value) =>
                                handleFormSchemaChange(
                                  formikProps,
                                  value,
                                  "duration",
                                )
                              }
                              schemaChange
                            />

                            {formikProps.values.salaryIncrease === "1" ? (
                              <FormikInput
                                label="Amount"
                                name="amount"
                                formikProps={formikProps}
                                options={undefined}
                                inputType="outlined-input"
                                className="annual-feedback-mention-amount-input"
                                startAdornment={
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                }
                              />
                            ) : (
                              <FormikInput
                                label="Percentage"
                                name="amount"
                                formikProps={formikProps}
                                options={undefined}
                                inputType="outlined-input"
                                className="annual-feedback-mention-amount-input"
                                endAdornment={
                                  <InputAdornment position="start">
                                    %
                                  </InputAdornment>
                                }
                              />
                            )}

                            {formikProps.values.duration === "1" && (
                              <FormikInput
                                label="Apply from month"
                                name="month"
                                formikProps={formikProps}
                                options={MonthsArray}
                                inputType="select"
                                className="month-dropdown"
                              />
                            )}
                          </>
                        ) : (
                          <FormikInput
                            label="Comment"
                            name={`questionComment_${item.id}`}
                            formikProps={formikProps}
                            className="comment-box-custom-form-control"
                            options={undefined}
                            fullWidth
                            multiline
                            rows={2}
                          />
                        )}
                      </Box>
                    </>
                  ),
                )}

                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    margin: "10px 0px",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ height: 40 }}
                    onClick={() => handleRedirectBack()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{ height: 40 }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AnnualFeedbackDetailsForm;
