import { AppBar, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import MultipleFeedbackList from "./MultipleFeedbackList";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const PerformanceMultipleFeedback = () => {
  let query = useQuery();
  const refId = query.get("refId");

  if (!refId) {
    return <div>No Data</div>;
  }

  return (
    <Box px={2}>
      <AppBar
        sx={{
          background: "black",
          padding: "1rem 16px",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: "1200px",
            margin: "0 auto",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            "@media (max-width:600px)": {
              justifyContent: "center",
            },
          }}
        >
          <img
            src="/images/patona.png"
            alt="logo"
            style={{ width: 200, height: 36 }}
          />
        </Box>
      </AppBar>
      <Box
        component="main"
        sx={{
          maxWidth: "1200px",
          margin: "80px auto 0 auto",
        }}
        mt={10}
      >
        <MultipleFeedbackList />
      </Box>
    </Box>
  );
};

export default PerformanceMultipleFeedback;
