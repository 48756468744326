import { Button, CircularProgress, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { memo, useEffect } from "react";
import { useAlert } from "react-alert";
import * as yup from "yup";
import { useAddTaskCommentMutation } from "../../redux/Task/TaskApi";
import FormikInput from "../../shared/components/FormikInput";
import { userAccess } from "../../utils/CommonFunction";

const initialValues = {
  comment: "",
};

const validationSchema = yup.object({
  comment: yup.string(),
});

const TaskCommentsModal = ({ taskId }) => {
  const [
    addCommentApi,
    {
      isLoading: addCommentLoading,
      isError: addCommentError,
      isSuccess: addCommentSuccess,
    },
  ] = useAddTaskCommentMutation();
  const alert = useAlert();
  const postAddComment = async (values) => {
    const request = {
      comment: values.comment,
      id: 0,
      taskId: taskId,
      commentedBy: userAccess().userId,
      commentedByEmail: userAccess().email,
    };
    addCommentApi(request);
  };

  useEffect(() => {
    if (addCommentSuccess) {
      alert.success("Comment added successfully");
    }
  }, [addCommentSuccess, alert]);
  useEffect(() => {
    if (addCommentError) {
      alert.error("Error while adding comment");
    }
  }, [addCommentError, alert]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(data, { resetForm }) =>
        postAddComment(data).then(() => resetForm())
      }
    >
      {(formikProps) => (
        <Form>
          <Grid container spacing={2} mt={1} alignItems={"center"}>
            <Grid item justifyContent={"space-between"} flex={1}>
              <FormikInput
                label="Comment here*"
                name="comment"
                formikProps={formikProps}
                options={undefined}
                fullWidth
              />
            </Grid>

            <Grid item mb={1}>
              <Button
                type="submit"
                disabled={
                  addCommentLoading || formikProps.values.comment.length === 0
                }
                variant="contained"
              >
                {addCommentLoading ? (
                  <CircularProgress size={20}></CircularProgress>
                ) : (
                  "Add"
                )}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default memo(TaskCommentsModal);
