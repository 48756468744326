import { useSelector } from "react-redux";

import { Button, CircularProgress, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

import { useStyles } from "./styles";

const SubmitFeedbackModal = ({ onClose, open }) => {
  const classes = useStyles();
  const { submitFeedbackInfo } = useSelector(
    (state: any) => state.performance.employee,
  );

  if (submitFeedbackInfo?.isLoading) {
    return (
      <Modal open={open}>
        <Box
          className={classes.modal}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography variant="h5" textAlign="center" fontWeight={400} p={2}>
            Submitting feedback...
          </Typography>
        </Box>
      </Modal>
    );
  }

  if (submitFeedbackInfo?.submitted && !submitFeedbackInfo?.error) {
    return (
      <Modal open={open}>
        <Box
          className={classes.modal}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CheckCircleIcon sx={{ color: "green", fontSize: 40 }} />
          <Typography variant="h5" textAlign="center" fontWeight={400} p={2}>
            Feedback submitted
          </Typography>
          <Button variant="contained" onClick={(e) => onClose(e, "success")}>
            Close
          </Button>
        </Box>
      </Modal>
    );
  }

  if (submitFeedbackInfo?.error && submitFeedbackInfo?.submitted) {
    return (
      <Modal open={open}>
        <Box
          className={classes.modal}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ErrorIcon sx={{ color: "red", fontSize: 40 }} />
          <Typography variant="h5" textAlign="center" fontWeight={400} p={2}>
            An error occurred while submitting feedback
          </Typography>
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
        </Box>
      </Modal>
    );
  }

  return null;
};

export default SubmitFeedbackModal;
