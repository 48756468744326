import { Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { TabPanel } from "../../../shared/components/TabPanel/TabPanel";
import { Box } from "@mui/system";
import DataGrid from "../../../shared/components/DataGrid/DataGrid";
import { leaveHistoryGridRow } from "../../TeamMemberLeave/TeamMemberApprovals.helper";
import { useDispatch, useSelector } from "react-redux";
import { getTeamMemberLeaveDetails } from "../../Approvals/Approvals.helper";
import { userAccess } from "../../../utils/CommonFunction";
import {
  getTeamMemberTimeSheetDetails,
  teamMemberTimesheetRow,
} from "../../TeamMemberTimesheet/TeamMemberTimesheet.helper";
import TableSkeleton from "../../../shared/components/Skeletons/TableSkeleton";
import { useNavigate } from "react-router-dom";

const LeaveContainer = () => {
  const [tabValue, setValue] = useState(0);
  const { email, zohoId } = userAccess();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLeaveInfoLoading, leaveInfoList } = useSelector(
    //@ts-ignore
    (state) => state.approval,
  );
  const { isTimesheetDetailLoading, timesheetDetail } = useSelector(
    //@ts-ignore
    (state) => state.timesheet,
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 1) {
      getTeamMemberTimeSheetDetails(dispatch, {
        zohoId: zohoId,
      });
    } else {
      getTeamMemberLeaveDetails(dispatch, {
        email: email,
        zohoId: zohoId,
        isForPast: newValue ? false : true,
      });
    }
  };

  useEffect(() => {
    getTeamMemberLeaveDetails(dispatch, {
      email: email,
      zohoId: zohoId,
      isForPast: true,
    });
  }, []);

  return (
    <Grid item sm={8} xs={6} className="leave-container" mt={-5}>
      <Tabs
        value={tabValue}
        variant="fullWidth"
        onChange={handleChange}
        sx={{ backgroundColor: "#FFFFFF" }}
      >
        <Tab label="Leaves" />
        <Tab label="Timesheet" />
        <Tab label="Upcoming leaves" />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        {isLeaveInfoLoading ? (
          <TableSkeleton />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "white",
                mt: 2,
                p: 1,
                mb: -3,
              }}
            >
              <Typography>Date</Typography>
              <Typography ml={-12}>Reason</Typography>
              <Box>
                <Button onClick={() => navigate("/leave")}>View all</Button>
                <Button variant="contained">Apply leave</Button>
              </Box>
            </Box>
            <DataGrid row={leaveHistoryGridRow(leaveInfoList)?.slice(0, 5)} />
          </>
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {isTimesheetDetailLoading ? (
          <TableSkeleton />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "white",
                mt: 2,
                p: 1,
                mb: -3,
              }}
            >
              <Typography>Date</Typography>
              <Typography ml={-12}>Reason</Typography>
              <Box>
                <Button>View all</Button>
                <Button variant="contained">Apply leave</Button>
              </Box>
            </Box>
            <DataGrid
              row={teamMemberTimesheetRow(timesheetDetail)?.slice(0, 5)}
            />
          </>
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        {isLeaveInfoLoading ? (
          <TableSkeleton />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "white",
                mt: 2,
                p: 1,
                mb: -3,
              }}
            >
              <Typography>Date</Typography>
              <Typography ml={-12}>Reason</Typography>
              <Box>
                <Button onClick={() => navigate("/leave")}>View all</Button>
                <Button variant="contained">Apply leave</Button>
              </Box>
            </Box>
            <DataGrid row={leaveHistoryGridRow(leaveInfoList)?.slice(0, 5)} />
          </>
        )}
      </TabPanel>
    </Grid>
  );
};

export default LeaveContainer;
