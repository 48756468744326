import { Dispatch } from "@reduxjs/toolkit";
import _ from "lodash";
import { AlertContainer } from "react-alert";
import { sagaActions } from "../../redux/sagaActions";

export const leaveAction = (dispatch: Dispatch<any>, request) => {
  try {
    dispatch({ type: sagaActions.LEAVE_ACTION, payload: request });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getApporovalList = (dispatch: Dispatch<any>, payload = {}) => {
  try {
    let request = {};
    if (!_.isEmpty(payload)) {
      request = { ...request, ...payload };
    }
    dispatch({ type: sagaActions.GET_APPOROVALS_LIST, payload: request });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getCalenderApporovalList = (
  dispatch: Dispatch<any>,
  payload = {},
) => {
  try {
    let request = {
      load: 0,
    };
    if (!_.isEmpty(payload)) {
      request = { ...request, ...payload };
    }
    dispatch({
      type: sagaActions.GET_CALENDER_APPOROVALS_LIST,
      payload: request,
    });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getLeaveRequestsShortInfo = (
  dispatch: Dispatch<any>,
  payload = {},
) => {
  try {
    let request = {
      load: 0,
    };
    if (!_.isEmpty(payload)) {
      request = { ...request, ...payload };
    }
    dispatch({
      type: sagaActions.GET_LEAVE_INFO,
      payload: request,
    });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getTeamMemberLeaveDetails = (dispatch: Dispatch<any>, request) => {
  try {
    dispatch({
      type: sagaActions.GET_UPCOMING_PAST_LEAVE_LIST,
      payload: request,
    });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getApporovalDetails = (dispatch: Dispatch<any>, request) => {
  try {
    dispatch({ type: sagaActions.GET_APPOROVALS_DETAIL, payload: request });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const copyToClicpBoard = (alert: AlertContainer) => {
  navigator.clipboard.writeText(window.location.href);
  alert.success("Link copied to clipboard");
};

export const Columns = [
  {
    id: 1,
    name: "Leave from",
    soryKey: "from",
    type: "heading",
    sortable: true,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
  {
    id: 2,
    name: "Leave to",
    soryKey: "to",
    type: "heading",
    sortable: false,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
  {
    id: 3,
    name: "Employee",
    soryKey: "Employee",
    type: "heading",
    sortable: false,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
  {
    id: 4,
    name: "Leave type",
    soryKey: "Leave type",
    type: "heading",
    sortable: false,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
  {
    id: 5,
    name: "Reason",
    soryKey: "Reason",
    type: "heading",
    sortable: false,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
  {
    id: 6,
    name: "Approved by",
    soryKey: "Approved by",
    type: "heading",
    sortable: false,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
  {
    id: 7,
    name: "Leave status",
    soryKey: "Leave status",
    type: "heading",
    sortable: false,
    display: true,
    color: "#000000",
    fontSize: 16,
    fontWeight: "500",
    textTransform: "capitalize",
  },
];

export const approvalRow = (rowValue: Array<any>) => {
  const row = rowValue
    .filter((filterMember) => !filterMember.isDeleted)
    ?.map((rowV) => {
      let columns: any = [];
      columns.push(
        {
          id: rowV.id,
          name: rowV.from ? rowV.from : "-",
          type: "title",
          experience: false,
          display: true,
          editable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
        },
        {
          id: rowV.id,
          name: rowV?.to ? rowV?.to : "-",
          type: "text",
          experience: false,
          display: true,
          editable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
        },
        {
          id: rowV.id,
          name: rowV?.employeeName ? rowV?.employeeName : "-",
          imageName: rowV.profilePhotoUrl ? rowV.profilePhotoUrl : "-",
          imageUrl: rowV.profilePhotoUrl ? rowV.profilePhotoUrl : "-",
          type: "text",
          avatar: true,
          display: true,
          editable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
        },
        {
          id: rowV.id,
          name: rowV.leavetype ? rowV.leavetype : "-",
          type: "text",
          experience: false,
          display: true,
          editable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
        },
        {
          id: rowV.id,
          name: rowV?.reasonforleave ? rowV?.reasonforleave : "-",
          type: "text",
          experience: false,
          display: true,
          editable: false,
          editableType: "number",
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
        },
        {
          id: rowV.id,
          name: rowV?.approver ? rowV?.approver : "-",
          type: "text",
          experience: false,
          display: true,
          editable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
        },
        {
          id: rowV.id,
          name: rowV?.approvalStatus ? rowV?.approvalStatus : "-",
          type: "text",
          experience: false,
          display: true,
          editable: false,
          color: leaveStatusColor(rowV?.approvalStatus),
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
          peopleId: rowV?.id,
          disable: false,
        },
      );
      return { ...rowV, columns, details: rowV, teamMemberId: rowV.id };
    });

  return row;
};

export function leaveStatusColor(color: string) {
  let textColor = "#000000";
  switch (color?.toLocaleLowerCase()) {
    case "approved":
      textColor = "green";
      break;
    case "rejected":
      textColor = "red";
      break;
    case "cancelled":
      textColor = "red";
      break;

    case "pending":
      textColor = "orange";
      break;
  }
  return textColor;
}

export function leaveTypeColor(type: string) {
  let textColor = "#6781F6";
  switch (type?.toLocaleLowerCase()) {
    case "causal leave":
      textColor = "#6781F6";
      break;
    case "earned leave":
      textColor = "#FFC315";
      break;
    case "optional leave":
      textColor = "#4CE9AE";
      break;

    case "sick leave":
      textColor = "#6781F6";
      break;

    case "bereavement leave":
      textColor = "#4D5666";
      break;
    case "paternity leave":
      textColor = "#DB9BF3";
      break;
    case "maternity leave":
      textColor = "#DB9BF3";
      break;
    case "leave without pay":
      textColor = "#F05E4F";
      break;
  }
  return textColor;
}

export const Rows = [
  {
    columns: [
      {
        id: "1",
        name: "01-10-2023",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "2",
        name: "02-10-2023",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "3",
        name: "Mahesh Thumar",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "4",
        name: "Casual Leave",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "5",
        name: "Casual",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "6",
        name: "Dhaval S",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "6",
        name: "Approved",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "green",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
    ],
  },
  {
    columns: [
      {
        id: "1",
        name: "02-11-2022",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "2",
        name: "04-11-2023",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "3",
        name: "Raju K",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "4",
        name: "Annual Leave",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "5",
        name: "Vacation",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "6",
        name: "Dhaval S",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: "6",
        name: "Rejected",
        type: "title",
        experience: false,
        display: true,
        avatar: false,
        editable: false,
        color: "red",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
    ],
  },
];
