import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  headerContainer: {
    height: 100,
    backgroundColor: "#CCD5FC",
    borderRadius: 1,
  },
  headerGrid: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignSelf: "center",
  },
  headerTypo: {
    fontSize: 24,
    fontWeight: "400",
    align: "center",
  },
  company_panel: {
    backgroundColor: "#CCD5FC",
    borderRadius: "10px",
    padding: "20px 40px",
    color: "#000000",
  },
  add_member_Header: {
    backgroundColor: "#f7ebfc",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
    padding: "20px 40px",
    color: "#000000",
  },
  back_icon: {
    cursor: "pointer",
    marginRight: "15px",
    color: "#000000",
  },
  user_logo: {
    border: "1px solid #CCD3D5",
    borderRadius: "25px",
    width: 50,
    height: 50,
  },
  companyLogo: {
    width: 100,
    height: 100,
    borderRadius: "8px",
    borderTopLeftRadius: 10,
    backgroundColor: "#CCD5FC",
  },
  companyLogoText: {
    textAlign: "center",
    paddingTop: "30px",
    backgroundColor: "#CCD5FC",
    borderRadius: "8px",
  },
  user_pic: {
    border: "1px solid #CCD3D5",
    borderRadius: "10px",
    width: 100,
    height: 100,
  },
  flexBox: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    alignItems: "center",
  },
  typographyLabel: {
    flex: "0 0 120px",
  },
  typographyContent: {
    flexGrow: 1,
  },
  memberTypo: {
    fontWeight: 500,
    fontStyle: "Plus Jakarta Sans",
    fontSize: "32px",
  },
}));

export { styles };
