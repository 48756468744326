import React from "react";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";

//styles
import { Box, Grid, Button, Typography } from "@mui/material";
import { STRINGS } from "../../../Strings";
import { LABELS } from "../../../Labels";

type renderBottomInfoProps = {
  source: string;
};

const RenderBottomInfo: React.FunctionComponent<renderBottomInfoProps> = (
  props,
) => {
  const { source } = props;
  const classes = useStyles();

  return (
    <>
      {source === STRINGS.AlreadyRegistered && (
        <Box className={classes.login_container}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Box component="div">
                <Typography variant="body1">{LABELS.login}</Typography>
              </Box>
              <Box component="div">
                <Typography variant="body2">
                  {STRINGS.RememberYourDetails}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Link to="/login">
                <Button variant="contained">{LABELS.login}</Button>
              </Link>
            </Grid>
          </Grid>
        </Box>
      )}
      {source === STRINGS.LinkExpired && (
        <Box className={classes.login_container}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Box component="div">
                <Typography variant="body1">
                  {STRINGS.ResendVerificationLink}
                </Typography>
              </Box>
              <Box component="div">
                <Typography variant="body2">
                  {STRINGS.TeamifiedVerificationLink}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained">{LABELS.resend}</Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default RenderBottomInfo;
