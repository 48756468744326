import { CircularProgress, Grid } from "@mui/material";
import { memo, useEffect } from "react";
import { useAlert } from "react-alert";
import { useGetTasksQuery } from "../../redux/Task/TaskApi";
import { userAccess } from "../../utils/CommonFunction";
import TabPanel from "../Hire/common/TabPanel";
import TaskTable from "./TaskTable";
import { useFilteredTaskData } from "./task-helper";

const TaskList = ({ tabValue, filters, searchText }) => {
  const { data, isLoading, refetch, isError } = useGetTasksQuery(
    {
      role: userAccess().role,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    },
  );
  const alert = useAlert();

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (isError) {
      alert.error("Something went wrong: All Tasks");
    }
  }, [alert, isError]);

  const filteredData = useFilteredTaskData(
    data?.data || [],
    filters,
    searchText,
  );

  return (
    <TabPanel tabValue={tabValue} index={2}>
      {isLoading ? (
        <Grid
          container
          item
          justifyContent={"center"}
          alignItems={"center"}
          height={"100%"}
        >
          <CircularProgress size={36}></CircularProgress>
        </Grid>
      ) : (
        <TaskTable tasksList={filteredData} refetchTasks={refetch} />
      )}
    </TabPanel>
  );
};

export default memo(TaskList);
