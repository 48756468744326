import { FileUploadOutlined } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  useGenerateUploadLinkMutation,
  useUploadToAzureMutation,
} from "../../redux/Documents/documentSlice";

const UploadResume = ({ id, documentName = "Resume", setTextCVLink }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [getLink, { data, isSuccess, isLoading }] =
    useGenerateUploadLinkMutation();
  const [files, setFiles] = useState<File | null>(null);
  const [
    uploadFile,
    { isLoading: uploadIsLoading, isSuccess: isUploadSuccess },
  ] = useUploadToAzureMutation();

  const generateLink = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.length) {
        getLink({
          fileName: e.target.files[0].name,
          id,
        });
        setFiles(e.target.files[0]);
      }
    },
    [getLink, id],
  );

  const uploadToAzure = useCallback(
    (link: string) => {
      if (files) {
        uploadFile({
          link,
          file: files,
          fileName: documentName,
          id: id,
        });
      }
    },
    [documentName, files, id, uploadFile],
  );

  useEffect(() => {
    if (isSuccess && data) {
      uploadToAzure(data.link);
    }
  }, [isSuccess, data, uploadToAzure]);

  useEffect(() => {
    if (isUploadSuccess && data) {
      setTextCVLink(data.link, files?.type);
    }
  }, [data, files?.type, isUploadSuccess, setTextCVLink]);

  return (
    <TextField
      variant="outlined"
      type="text"
      label={files ? "" : "Upload Resume*"}
      fullWidth
      value={files ? files.name : ""}
      disabled
      InputProps={{
        endAdornment: (
          <IconButton component="label">
            <FileUploadOutlined />
            <input
              accept=".docx,.doc,.txt,application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
              style={{ display: "none" }}
              type="file"
              hidden
              onChange={generateLink}
            />
          </IconButton>
        ),
      }}
    />
  );
};

export default memo(UploadResume);
