import { Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useDownloadDocumentQuery } from "../../redux/Documents/documentSlice";
import {
  useGetCandidateCVfromRecruitlyQuery,
  useGetCandidateListQuery,
  useGetCandidateQuery,
} from "../../redux/Hire/candidateSlice";
import {
  clearCandidateSuccess,
  commentAddedSuccessfully,
} from "../../redux/Hire/hireSlice";
import { STRINGS } from "../../Strings";
import CandidateDocuments from "./CandidateDocuments";
import CandidateInfo from "./CandidateInfo";
import CandidateInterviewDetails from "./CandidateInterviewDetails";
import CandidateScrollingList from "./CandidateScrollingList";
import { getHireCandidateStagingHelper } from "./Hire.helper";

export const CANDIDATE_PIPELINE_CODE_MAP = {
  SOURCED: "Screened by Talent Team",
  SHORTLIST: "shortlist",
  CV_SENT: "clientEndorsement",
  INTERVIEW: "clientInterview",
  OFFER: "offer",
  PLACED: "candidateDecision",
};

const CandidateListBody = ({
  jobId,
  selectedStage,
  setSelectedStage,
  tabValue,
}) => {
  //@ts-ignore
  const { isCommentAdded, moveCandidate, moveCandidateError, hireJobsDetail } =
    useSelector(
      //@ts-ignore
      (state) => state.hire,
    );

  const dispatch = useDispatch();
  const alert = useAlert();
  const [selectedCandidate, setSelectedCandidate] = useState<any>(null);
  const [showComment, setShowComment] = useState(false);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [selectedPage, setSelectedPage] = useState(0);
  const [candidateCVLoading, setCandidateCVLoading] = useState(false);

  const isNotApplied = selectedStage?.code !== "APPLIED";
  const [removeRejected, setRemoveRejected] = useState<boolean>(true);

  const {
    data: candidateListData,
    isLoading,
    isError,
    isSuccess,
    isFetching,
    refetch: candidateListRefetch,
    isUninitialized,
  } = useGetCandidateListQuery(
    {
      JobRef: hireJobsDetail.reference,
      JobPipeLineCode: selectedStage?.code,
      PageNumber: selectedPage,
      PageSize: 10,
      removeRejected: !removeRejected,
    },
    {
      skip: !selectedStage || !hireJobsDetail?.reference,
      refetchOnMountOrArgChange: true,
    },
  );

  const { data: candidateDetail, isLoading: candidateDetailPending } =
    useGetCandidateQuery(
      {
        CandidateId: selectedCandidate?.id,
      },
      {
        skip: !selectedCandidate || selectedStage?.code === "APPLIED",
      },
    );

  const { data: cvDetails } = useGetCandidateCVfromRecruitlyQuery(
    {
      CandidateId: selectedCandidate?.id,
    },
    {
      skip:
        !selectedCandidate ||
        selectedStage?.code === "APPLIED" ||
        (selectedCandidate && selectedCandidate?.id?.includes("man-cand")),
      refetchOnMountOrArgChange: true,
    },
  );

  const resumeFileName = candidateDetail?.data?.textCv?.split("/") || [];

  const { data: cvUrl } = useDownloadDocumentQuery(
    {
      fileName: resumeFileName[resumeFileName?.length - 1],
    },
    {
      skip:
        !(selectedCandidate && selectedCandidate?.id?.includes("man-cand")) ||
        selectedStage?.code === "APPLIED" ||
        resumeFileName.length === 0,
    },
  );

  useEffect(() => {
    if (selectedStage?.code) {
      setSelectedPage(0);
    }
  }, [selectedStage]);

  const handleSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRemoveRejected(event.target.checked);
    },
    [],
  );

  const strip = useCallback((html) => {
    var doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }, []);
  const showDetails = (candidate) => {
    setSelectedCandidate(candidate);
    setShowComment(false);
    setShowCommentBox(false);
  };

  useEffect(() => {
    if (moveCandidate) {
      getHireCandidateStagingHelper(hireJobsDetail.reference, dispatch);
      if (!isUninitialized) {
        candidateListRefetch();
      }
      dispatch(clearCandidateSuccess());
    }
  }, [moveCandidate]);

  useEffect(() => {
    if (moveCandidateError) {
      alert.error(moveCandidateError);

      dispatch(clearCandidateSuccess());
    }
  }, [moveCandidateError]);

  useEffect(() => {
    if (isCommentAdded) {
      alert.success("Comment has been posted successfully");
      dispatch(commentAddedSuccessfully());
    }
  }, [isCommentAdded]);

  useEffect(() => {
    if (isSuccess && candidateListData.data.candidates.length) {
      setSelectedCandidate(candidateListData.data.candidates[0]);
    }
  }, [candidateListData]);

  const onScrollCandidateList = (event) => {
    if (!isLoading) {
      var element = event.target;
      if (
        Math.ceil(element.scrollHeight) - Math.ceil(element.scrollTop) ===
        Math.ceil(element.clientHeight)
      ) {
        if (
          (candidateListData?.data?.candidates.length || 0) <
          (candidateListData?.data.filter.count || 0)
        ) {
          setSelectedPage((selectedPage) => selectedPage + 1);
        }
      }
    }
  };

  const internalCode = hireJobsDetail?.udfList?.find(
    (el) => el.key.label === "Internal Code",
  )?.value.textValue;

  return isSuccess && (candidateListData?.data.candidates || []).length > 0 ? (
    <>
      <Grid container display={"flex"} spacing={1}>
        <CandidateScrollingList
          candidates={candidateListData?.data.candidates || []}
          isCandidateListLoading={isLoading}
          moreCandidateListLoading={isFetching}
          showCandidate={showDetails}
          selectedCandidate={selectedCandidate}
          onScrollCandidateList={onScrollCandidateList}
          removeRejected={removeRejected}
          setRemoveRejected={handleSwitchChange}
        />
        {tabValue === 1 && (
          <CandidateInterviewDetails
            cvDetails={cvDetails?.data}
            selectedStage={selectedStage}
            jobId={jobId}
            setSelectedStage={setSelectedStage}
            candidateId={selectedCandidate?.id}
            jobDescription={strip(hireJobsDetail.description)}
            internalCode={internalCode}
            candidateEmail={selectedCandidate?.email}
          />
        )}
        {tabValue === 0 && (
          <CandidateInfo
            candidateId={selectedCandidate?.id}
            setShowCommentBox={setShowCommentBox}
            candidateDetail={candidateDetail?.data}
            selectedCandidate={selectedCandidate}
            selectedStage={selectedStage}
            setSelectedStage={setSelectedStage}
            candidateDetailPending={candidateDetailPending}
            showComment={showComment}
            showCommentBox={showCommentBox}
            setShowComment={setShowComment}
            cvDetails={cvDetails?.data}
            isNotApplied={isNotApplied}
            clientName={hireJobsDetail?.company.label || ""}
            jobId={hireJobsDetail?.id}
            roleName={hireJobsDetail?.title}
          />
        )}
        {tabValue === 2 && (
          <CandidateDocuments
            candidateId={selectedCandidate?.id}
            candidateName={selectedCandidate?.label}
          ></CandidateDocuments>
        )}
      </Grid>
    </>
  ) : (
    <Grid
      spacing={"10px"}
      alignItems="center"
      mt={"2rem"}
      display={"flex"}
      justifyContent={"center"}
    >
      <Typography variant="h5">{STRINGS.NoRecordFound}</Typography>
    </Grid>
  );
};

export default CandidateListBody;
