import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { usePermissions } from "../../utils/permission";

function performanceTabAriaProps(index: number) {
  return {
    id: `performance-tab-${index}`,
    "aria-controls": `performance-tabpanel-${index}`,
  };
}
const TaskSubheader = ({ handleTabChange, tabValue }) => {
  const { Task_ListAllTask } = usePermissions();
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          background: "#fff",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          marginTop: "12px",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="fullWidth"
          aria-label="basic tabs example"
        >
          <Tab
            label="Tasks created by me"
            {...performanceTabAriaProps(0)}
          ></Tab>
          <Tab label="Tasks assigned to me" {...performanceTabAriaProps(1)} />
          {Task_ListAllTask && (
            <Tab label="All Tasks" {...performanceTabAriaProps(2)} />
          )}
        </Tabs>
      </Box>
    </Box>
  );
};

export default TaskSubheader;
