import { Button, Grid, Typography } from "@mui/material";
import moment from "moment";
import { FC, memo, useCallback, useState } from "react";
import { EventObject } from "../../redux/Hire/candidateMeetingApi";
import { userAccess } from "../../utils/CommonFunction";
import AssigneeUser from "../Tasks/Components/AssigneeUser";

type Props = {
  meetingDetails?: EventObject;
  openReschedule: () => void;
  openDeleteReason: () => void;
};
const ScheduledCallDetails: FC<Props> = ({
  meetingDetails,
  openReschedule,
  openDeleteReason,
}) => {
  const [showDetails, setShowDetails] = useState(
    meetingDetails?.deletedAt ? false : true,
  );

  const toggleDetails = useCallback(() => {
    setShowDetails((showDetails) => !showDetails);
  }, []);

  if (!meetingDetails) return null;

  return (
    <Grid container spacing={2}>
      {meetingDetails.deletedAt ? (
        <Grid container item flexDirection={"column"} gap={1}>
          <Typography variant="body2" fontWeight={500}>
            On {moment.utc(meetingDetails.deletedAt).format("Do MMM,")}{" "}
            {userAccess().userId === meetingDetails.deletedBy ? (
              "you"
            ) : (
              <AssigneeUser assigneeUser={meetingDetails.deletedBy} />
            )}{" "}
            has deleted the scheduled call with the candidate.
          </Typography>
          <Typography variant="body2">
            Reason: {meetingDetails.deletedReason}
          </Typography>
          <Grid item>
            <Button
              variant="outlined"
              onClick={toggleDetails}
              color={showDetails ? "secondary" : "primary"}
            >
              {showDetails ? "Close" : "View"} scheduled call details
            </Button>
          </Grid>
        </Grid>
      ) : null}
      {showDetails && (
        <Grid container item xs={12} gap={1}>
          <Grid container item xs={12} sm={12}>
            <Grid item xs={3}>
              <Typography variant="body2">Title: </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{meetingDetails.title}</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={3}>
              <Typography variant="body2">Description: </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">
                Your call will be recorded for analyzing and safety purpose
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} alignItems={"center"}>
            <Grid item xs={3}>
              <Typography variant="body2">Day and time</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">
                {moment
                  .utc(new Date(meetingDetails.meetingStartedOn))
                  .format("ddd, Do MMM h:mm a")}
              </Typography>
            </Grid>
            <Grid container item xs={6} gap={1}>
              <Button
                variant="contained"
                onClick={() => {
                  window.open(meetingDetails.link, "_blank");
                }}
              >
                Google meet
              </Button>
              <Button onClick={openReschedule}>Reschedule</Button>
              <Button onClick={openDeleteReason}>Delete</Button>
            </Grid>
          </Grid>
          <Grid container item xs={12} alignItems={"center"}>
            <Grid item xs={3}>
              <Typography variant="body2">Guests</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography
                variant="body2"
                overflow={"hidden"}
                textOverflow={"ellipsis"}
              >
                {meetingDetails.attendeesEmail.split(",").join(", ")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(ScheduledCallDetails);
