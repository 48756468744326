import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  headerContainer: {
    height: 100,
    backgroundColor: "#CCD5FC",
    borderRadius: 1,
  },
  headerGrid: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignSelf: "center",
  },
  headerTypo: {
    fontSize: 24,
    fontWeight: "400",
    align: "center",
  },
  company_panel: {
    backgroundColor: "#CCD5FC",
    borderRadius: "10px",
    padding: "20px 40px",
    color: "#000000",
  },
  back_icon: {
    cursor: "pointer",
    marginRight: "15px",
    color: "#000000",
  },
  adminUsersIcons: {
    height: 60,
    width: 60,
    borderRadius: 30,
    display: "flex",
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  pageHeader: {
    backgroundColor: "#fff",
    boxShadow: "0px 4px 4px 0px #0000000D",
  },

  headerUsers: {
    mt: 1,
    mb: 1,
    mr: 2,
    ml: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  company_panel2: {
    background: "rgba(204, 211, 213, 0.3)",
    padding: "20px 40px",
    color: "#000000",
  },
  back_icon2: {
    cursor: "pointer",
    marginRight: "15px",
    color: "#000000",
    verticalAlign: "middle",
  },
  display_card: {
    // backgroundColor: "#FFFFFF",
    // borderRadius: "16px",
    // boxShadow: "1px 4px 20px rgba(132, 132, 132, 0.2)",
    // padding: "2rem",
    marginTop: "24px",
  },
  job_display_card: {
    borderRadius: "16px",
    // padding: "2rem",
  },
  company_title_text: {
    fontSize: "16px !important",
    fontWeight: "700 !important",
  },
  card_box: {
    border: "4px solid #CCD5FC",
    borderRadius: "10px",
    padding: 20,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    textAlign: "center",
  },
  candidate_details: {
    background: "#FFFAEA",
    borderRadius: "10px",
    padding: "20px",
    color: "#000000",
  },
  candidate_card: {
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "1px 4px 20px rgba(132, 132, 132, 0.2)",
    padding: "1rem",
    margin: "2rem 0",
  },
  candidate_section: {
    borderBottom: "1px solid #707D84",
    paddingBottom: "1rem",
  },
  candidate_Selected_stage: {
    color: "#A16AE8",
    border: "1px solid #A16AE8",
    borderRadius: "6px",
    background: "#F2F4F6",
  },
  candidate_stage: {
    cursor: "pointer",
    border: "1px solid #A16AE8",
    borderRadius: "6px",
  },

  //candidate responsive styles
  cd_stages_container: {
    background: "#E5EAFD",
    padding: "10px",
    borderRadius: "6px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    overflowX: "auto",
  },
  cd_stages_box: {
    textAlign: "center",
    padding: "0.5rem 1.5rem",
  },
  cd_stage_box_active: {
    color: "#002DFF",
    border: "1px solid #002DFF",
    borderRadius: "6px",
    padding: "0.5rem 1.5rem",
    background: "#FFFFFF",
  },
  cd_list_container: {
    display: "flex",
    flexDirection: "row",
    padding: "8px 8px",
    backgroundColor: "#fff",
    boxShadow: "0px 4px 4px 0px #0000000D",
    // borderBottom: "1px solid #707D84",
  },
  cd_image_col: {
    marginRight: "10px",
  },
  cd_mid_col: {},
  cd_last_col: {
    flex: "5%",
    marginRight: "5px",
    textAlign: "right",
  },
  cd_image: {
    borderRadius: "50%",
    border: "1px solid #CCD3D5",
  },
  cursor: {
    cursor: "pointer",
  },

  cd_stage_title: {
    lineHeight: "20px ",
  },
  add_member_Header: {
    backgroundColor: "#f7ebfc",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
    padding: "20px 40px",
    color: "#000000",
  },
  user_logo: {
    border: "1px solid #CCD3D5",
    borderRadius: "25px",
    width: 50,
    height: 50,
  },
  companyLogo: {
    width: 100,
    height: 100,
    borderRadius: "8px",
    borderTopLeftRadius: 10,
    backgroundColor: "#CCD5FC",
  },
  user_pic: {
    border: "1px solid #CCD3D5",
    borderRadius: "10px",
    width: 100,
    height: 100,
  },
  companyLogoText: {
    textAlign: "center",
    paddingTop: "30px",
    backgroundColor: "#CCD5FC",
    borderRadius: "8px",
  },
  headerTitle: {
    "@media (max-width:780px)": {
      fontSize: ".8rem",
    },
  },
  jobDetailHeading: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
  },
  timelineIcon: {
    width: "22px",
    height: "20px",
    marginRight: "11px",
  },
  qualifiedTimelineIcon: {
    width: "22px",
    height: "20px",
    marginRight: "1px",
  },
  disqualifiedText: {
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "22.68px",
    textAlign: "center",
    color: "#E61A1A",
  },
  disqualifiedCon: {
    marginRight: 10,
  },
  qualifiedCon: {
    width: "20px",
    height: "20px",
  },
}));

export { styles };
