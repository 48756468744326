import _ from "lodash";
import { all, put, takeLatest } from "redux-saga/effects";
import { Peoples } from "../../Types/People/People";
import { Status } from "../../services/ApiStatus";
import {
  getInvoiceDetail,
  getInvoicePreview,
  getInvoices,
  getInvoicesGraphData,
} from "../../services/Invoice";
import { sagaActions } from "../sagaActions";
import {
  invoiceDetails,
  invoiceDetailsFail,
  invoiceDetailspending,
  invoiceDownloadFail,
  invoiceDownloadSuccess,
  invoiceDownloadpending,
  invoiceGraphFail,
  invoiceGraphSuccess,
  invoicePreview,
  invoicePreviewFail,
  invoicePreviewpending,
  isInvoiceGraphpending,
  paging,
  paidInvoiceFail,
  paidInvoiceLoading,
  paidInvoiceSuccess,
  paidPaging,
  pendingInvoiceFail,
  pendingInvoiceLoading,
  pendingInvoiceSuccess,
  setTotalPendingAmount,
} from "./invoiceSlice";

function* fetchPendingInvoice(payload: any = "") {
  try {
    if (!_.isUndefined(payload?.payload?.load)) {
      yield put(pendingInvoiceLoading());
    }

    const response: any = yield getInvoices(payload);
    if (!!response && response?.status === Status.Success) {
      const { paginationFilter, invoicesTotalAmount } = response.data;
      const page = Math.ceil(
        paginationFilter.count / paginationFilter.pageSize,
      );
      const isPaging = page > 1 ? true : false;
      yield put(
        setTotalPendingAmount({
          totalPendingAmount: invoicesTotalAmount,
          totalPending: paginationFilter?.count,
        }),
      );
      yield put(
        paging({
          page: page,
          isPaging: isPaging,
          pageSize: paginationFilter.pageSize,
          currentPage: paginationFilter.pageNumber,
        }),
      );
      yield put(pendingInvoiceSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(pendingInvoiceFail(response?.message));
        yield put(paging({ page: 0, isPaging: false }));
        yield put(
          setTotalPendingAmount({
            totalPendingAmount: 0,
            totalPending: 0,
          }),
        );
      }
    }
  } catch (error: any) {
    yield put(pendingInvoiceFail(error?.message));
    yield put(paging({ page: 0, isPaging: false }));
    yield put(
      setTotalPendingAmount({
        totalPendingAmount: 0,
        totalPending: 0,
      }),
    );
  }
}

function* fetchInvoiceByID(payload) {
  try {
    yield put(invoiceDetailspending());
    const response = yield getInvoiceDetail(payload);
    if (!!response && response?.status === Status.Success) {
      yield put(invoiceDetails(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(invoiceDetailsFail(response?.message));
      }
    }
  } catch (error: any) {
    yield put(invoiceDetailsFail(error?.message));
  }
}

function* fetchInvoicePreviewByID(payload) {
  try {
    yield put(invoicePreviewpending());
    const response = yield getInvoicePreview(payload);
    if (!!response && response?.status === Status.Success) {
      yield put(invoicePreview(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(invoicePreviewFail(response?.message));
      }
    }
  } catch (error: any) {
    yield put(invoicePreviewFail(error?.message));
  }
}

function* fetchPaidInvoice(payload: any = "") {
  try {
    if (!_.isUndefined(payload?.payload?.load)) {
      yield put(paidInvoiceLoading());
    }
    const response: Peoples = yield getInvoices(payload);
    if (!!response && response?.status === Status.Success) {
      const { paginationFilter } = response.data;
      const page = Math.ceil(
        paginationFilter.count / paginationFilter.pageSize,
      );
      const isPaging = page > 1 ? true : false;
      yield put(
        paidPaging({
          paidPage: page,
          isPaidPaging: isPaging,
          paidPageSize: paginationFilter.pageSize,
          paidCurrentPage: paginationFilter.pageNumber,
        }),
      );
      yield put(paidInvoiceSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(paidInvoiceFail(response?.message));
        yield put(paidPaging({ paidPage: 0, isPaidPaging: false }));
      }
    }
  } catch (error: any) {
    yield put(paidInvoiceFail(error?.message));
    yield put(paidPaging({ page: 0, isPaging: false }));
  }
}

function* fetchInvoiceDownloadByID(payload) {
  try {
    yield put(invoiceDownloadpending());
    const response = yield getInvoicePreview(payload);
    if (!!response && response?.status === Status.Success) {
      const linkSource = `data:application/pdf;base64,${response?.data}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = "Invoice.pdf";
      downloadLink.click();
      yield put(invoiceDownloadSuccess());
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(invoiceDownloadFail(response?.message));
      }
    }
  } catch (error: any) {
    yield put(invoiceDownloadFail(error?.message));
  }
}

function* fetchInvoiceGraph(payload) {
  try {
    yield put(isInvoiceGraphpending());
    const response = yield getInvoicesGraphData(payload);
    if (!!response && response?.status === Status.Success) {
      yield put(invoiceGraphSuccess(response?.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(invoiceGraphFail());
      }
    }
  } catch (error: any) {
    yield put(invoiceGraphFail());
  }
}

export default function* watcherSaga() {
  yield all([
    yield takeLatest(sagaActions.FETCH_PENDING_INVOICE, fetchPendingInvoice),
    yield takeLatest(sagaActions.FETCH_PAID_INVOICE, fetchPaidInvoice),
    yield takeLatest(sagaActions.FETCH_INVOICE_DETAIL, fetchInvoiceByID),
    yield takeLatest(
      sagaActions.FETCH_INVOICE_PREVIEW,
      fetchInvoicePreviewByID,
    ),
    yield takeLatest(
      sagaActions.FETCH_INVOICE_DOWNLOAD,
      fetchInvoiceDownloadByID,
    ),
    yield takeLatest(sagaActions.FETCH_INVOICE_GRAPH, fetchInvoiceGraph),
  ]);
}
