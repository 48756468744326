import * as Yup from "yup";

export const addHirePostSchema = Yup.object({
  title: Yup.string().min(2).required("Please enter your job title name"),
  clientId: Yup.number()
    .min(1, "Please select client name")
    .required("Please select client name"),
  experienceLevel: Yup.string()
    .min(2, "Please select experience level")
    .required("Please select experience level"),
  expectedSalaryFrom: Yup.number()
    .min(1)
    .required("Please enter expected salary from"),
  expectedSalaryTo: Yup.number()
    .min(1)
    .moreThan(
      Yup.ref("expectedSalaryFrom"),
      "expected salary to should be greater than expected salary from",
    )
    .required("Please enter expected salary to"),
});
