import { Grid } from "@mui/material";
import { memo } from "react";
import AddNewTaskModal from "./AddNewTaskModal";

const TaskInfoSubPage = ({ task }) => {
  const closeModalPlaceholderToSatisfyTS = () => {};
  return (
    <Grid container>
      <AddNewTaskModal
        closeModal={closeModalPlaceholderToSatisfyTS}
        task={task}
      ></AddNewTaskModal>
    </Grid>
  );
};

export default memo(TaskInfoSubPage);
