import { Grid, Typography } from "@mui/material";
import moment from "moment";
import AssigneeUser from "./AssigneeUser";

const TaskComment = ({ taskComment, last = false }) => {
  return (
    <Grid
      container
      pb={1}
      borderBottom={last ? "0px solid #CCD3D5" : "1px solid #CCD3D5"}
      width={"100%"}
      justifyContent={"space-between"}
    >
      <Grid container item direction={"column"} xs={10}>
        <Grid item>
          {" "}
          <Typography variant="body2">{taskComment.comment}</Typography>
        </Grid>
        <Grid item gap={"4px"} container>
          <Typography variant="body2">comment by:</Typography>
          <Typography variant="body2">
            <AssigneeUser assigneeUser={taskComment.commentedBy}></AssigneeUser>
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          {moment.utc(taskComment.createdAt).local().format("DD-MM-yyyy h:mma")}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default TaskComment;
