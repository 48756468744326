import { useState } from "react";

import {
  Box,
  Button,
  FormHelperText,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { sagaActions } from "../../../../../redux/sagaActions";
import { userAccess } from "../../../../../utils/CommonFunction";
import { getDateTime } from "../../../../../utils/dateFormatter";
import { useStyles } from "../styles";
import SearchableEmployeeField from "./SearchableEmployeeField";

const REWARD_TYPES = {
  incentive: 1,
  adayoff: 2,
  goodies: 3,
  chocolates: 4,
  amazonvoucher: 5,
};

const RewardModal = ({
  onSubmit,
  onCancel,
  noClient = false,
  showModal,
  searchable = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const empId = useParams().id;

  const { isLoading, employeeName, clientId, submitFeedbackInfo } = useSelector(
    (state: any) => state.performance.employee,
  );

  const [achiement, setAchievement] = useState("");
  const [appreciation, setAppreciation] = useState("");
  const [amount, setAmount] = useState("");

  const [selectedEmployeeDetails, setSelectedEmployeeDetails] = useState({
    id: null,
    clientId: null,
    lastScore: null,
    isLastMonthFeedbackSubmitted: false,
  });

  const validateForm = () => {
    if (!empId && selectedEmployeeDetails?.id === null) {
      return false;
    }

    if (achiement.trim().length === 0) {
      return false;
    }

    if (appreciation.trim().length === 0) {
      return false;
    }

    if (appreciation === "incentive") {
      if (amount.trim().length === 0) {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }

    const { userId, firstName, lastName } = userAccess();

    let payload = {
      employeeId: searchable ? selectedEmployeeDetails?.id : empId,
      feedBackType: 2,
      clientId: searchable ? selectedEmployeeDetails?.clientId : clientId,
      userId: userId,
      from: firstName + " " + lastName,
      date: getDateTime(),
      achiement,
      awardappreciation: REWARD_TYPES[appreciation],
      incentiveAmount: appreciation === "incentive" ? `$${amount}` : "",
    };

    dispatch({ type: sagaActions.ADD_FEEDBACK, payload });
    onSubmit();
  };

  return (
    <Modal open={showModal} onClose={() => {}}>
      <Box className={classes.modal}>
        <Typography
          variant="h4"
          textAlign="center"
          fontWeight={400}
          p={2}
          mb={2}
        >
          Reward
        </Typography>

        {searchable && (
          <SearchableEmployeeField
            onSelect={(employee) => setSelectedEmployeeDetails(employee)}
          />
        )}

        <Typography variant="body1" fontWeight={500} mt={2}>
          Which achievements of the team member deserve recognition in your
          eyes?
        </Typography>

        <TextField
          label="Mention the achievements"
          name="achievement"
          value={achiement}
          onChange={(event) => setAchievement(event.target.value)}
          margin="normal"
          fullWidth
          multiline
          rows={4}
          onBlur={validateForm}
        />

        <Typography variant="body1" fontWeight={500} mt={2} mb={1}>
          How will you like to appreciate the team member for his work?
        </Typography>

        <TextField
          select
          label="Appreciate by"
          value={appreciation}
          onChange={(event) => {
            setAppreciation(event.target.value);
          }}
          onBlur={(event) => {
            event.stopPropagation();
          }}
          margin="normal"
          fullWidth
        >
          <MenuItem value="incentive">Incentive</MenuItem>
          <MenuItem value="adayoff">A day off</MenuItem>
          <MenuItem value="goodies">Goodies</MenuItem>
          <MenuItem value="chocolates">Chocolates</MenuItem>
          <MenuItem value="amazonvoucher">Amazon voucher</MenuItem>
        </TextField>

        {appreciation === "incentive" && (
          <TextField
            label="Amount"
            name="amount"
            value={amount}
            onChange={(event) => setAmount(event.target.value)}
            margin="normal"
            placeholder="$200"
            fullWidth
            type="number"
            onBlur={validateForm}
          />
        )}

        {noClient && (
          <FormHelperText error={true} sx={{ textAlign: "right" }}>
            Feedback cannot be given as this team member is not currently linked
            to a client.
          </FormHelperText>
        )}

        <Box className={classes.actions}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!validateForm() || noClient}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RewardModal;
