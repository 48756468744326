import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Card, Grid, IconButton, Stack, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import Moment from "moment";
import React from "react";
import { daysAgoFromDate, userAccess } from "../../utils/CommonFunction";

import { STRINGS } from "../../Strings";

type TableProps = {
  row?: Array<any>;
  getDetails?: (number, boolean?) => void;
  deleteMember?: (number) => void;
  actionList?: Array<any>;
  moveToTeamMember?: (number) => void;
  disableTeamMember?: (number) => void;
  enableTeamMember?: (number) => void;
  showPaging?: boolean;
  page?: number;
  getPageList?: (number) => void;
  sortTable?: (boolean, string) => void;
  cellClick?: (data: any) => void;
  paid?: boolean;
  getDownload?: (number) => void;
};

const InvoiveMobileDataGrid: React.FunctionComponent<TableProps> = (props) => {
  const classes = styles();
  const defaultRowCell = [1, 2, 3];

  const {
    row = defaultRowCell,
    getDetails = () => null,
    deleteMember = () => null,
    moveToTeamMember = () => null,
    disableTeamMember = () => null,
    enableTeamMember = () => null,
    actionList = [],
    showPaging = false,
    page = 0,
    getPageList = () => null,

    sortTable = () => null,
    cellClick = () => null,
    paid = false,
    getDownload = () => null,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [actionPeopleId, setActionPeopleId] = React.useState<number>();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const rowAction = (action) => {
    switch (action) {
      case "getDetails":
        getDetails(actionPeopleId, true);
        break;
      case "download":
        getDownload(actionPeopleId);
        break;
    }
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const responsiveCard = () => {
    return (
      <Box className="responsive-tbl">
        {row?.map((value) => {
          return (
            <Card
              key={value.id}
              className="tbl-card"
              sx={{ minWidth: 275, marginBottom: 3 }}
            >
              <Grid
                container
                spacing={2}
                className={
                  value?.isDisabled ? "tbl-header-disable" : "tbl-header"
                }
              >
                <Grid item xs={8}>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 2,
                      ml: 1,
                    }}
                  >
                    {!userAccess().client && (
                      <Box>
                        <Typography variant="h6" component="div">
                          {value?.Contact?.Name
                            ? `${value?.Contact?.Name} `
                            : "-"}
                        </Typography>
                      </Box>
                    )}
                    {userAccess().client && (
                      <Box>
                        <Typography
                          variant="h6"
                          component="div"
                          color={"#002DFF"}
                        >
                          {value?.InvoiceNumber
                            ? `${value?.InvoiceNumber} `
                            : "-"}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box display="flex" justifyContent="flex-end">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClick(e);
                        setActionPeopleId(value?.InvoiceID);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
              <Box className="tbl-body">
                <Grid container spacing={2}>
                  <Grid item xs={12} spacing={2}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {!userAccess().client && (
                        <Typography
                          variant="caption"
                          component="div"
                          color={"#002DFF"}
                        >
                          {value.InvoiceNumber
                            ? `${value.InvoiceNumber} `
                            : "-"}
                        </Typography>
                      )}

                      {userAccess().client && (
                        <Typography variant="caption" component="div">
                          {value.Date
                            ? Moment(value?.Date).format("DD-MM-YYYY")
                            : "-"}
                        </Typography>
                      )}

                      {!paid && (
                        <Typography
                          variant="caption"
                          component="div"
                          color={"#E61A1A"}
                        >
                          {value.DueDate ? daysAgoFromDate(value.DueDate) : "-"}
                        </Typography>
                      )}

                      {paid && (
                        <Typography variant="caption" component="div">
                          {value.FullyPaidOnDate
                            ? `Paid on ` +
                              Moment(value.FullyPaidOnDate).format("DD-MM-YYYY")
                            : "-"}
                        </Typography>
                      )}

                      {!paid && (
                        <Typography variant="caption" component="div">
                          {value.Total ? `$${value.Total} ` : "-"}
                        </Typography>
                      )}
                      {paid && (
                        <Typography variant="caption" component="div">
                          {value.AmountPaid ? `$${value.AmountPaid} ` : "-"}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          );
        })}
      </Box>
    );
  };

  return (
    <Stack sx={{ flexGrow: 1 }} mt={2}>
      {responsiveCard()}
      {row.length === 0 && (
        <Typography variant="subtitle1" py={3} align={"center"}>
          {STRINGS.NoRecordFound}
        </Typography>
      )}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {!!actionList &&
          actionList?.length > 0 &&
          actionList?.map((action) => {
            return (
              action.display && (
                <MenuItem onClick={() => rowAction(action.action)}>
                  {action.name}
                </MenuItem>
              )
            );
          })}
      </Menu>
    </Stack>
  );
};

export default InvoiveMobileDataGrid;

const styles = makeStyles((theme) => ({
  yellowContainer: {
    height: 66,
    backgroundColor: "#FFFAEA",
    borderRadius: 1,
  },
  whiteContainer: {
    height: 66,
    backgroundColor: "#FFF",
    borderRadius: 1,
  },
  headerGrid: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignSelf: "center",
  },
  headerTypoHeading: {
    fontSize: 13,
    fontWeight: "600",
    align: "center",
  },
  headerTypo: {
    fontSize: 11,
    fontWeight: "400",
    align: "center",
  },
}));
