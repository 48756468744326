import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { userAccess } from "../../../utils/CommonFunction";
import { addPunchInPuchOut } from "../attendance.helper";
import AttendanceCalender from "../CalenderView/AttendanceCalender";
import AttendanceList from "../ListView/AttendanceList";
import useStyles from "../style";
import useGeocoding from "../useGeocoding";

const AttendanceHeader = () => {
  const [timeSheetView, setTimeSheetView] = useState("List");
  const [punchInTime, setPunchInTime] = useState<string>();
  const [totalTime, setTotalTime] = useState(moment.duration());
  const [punchOutTime, setPunchOutTime] = useState<string>();
  const [userLocation, setUserLocation] = useState();
  const dispatch = useDispatch();
  const { email } = userAccess();
  const classes = useStyles();

  const { attendanceList } = useSelector(
    //@ts-ignore
    (state) => state.attendance,
  );

  const startTime = useMemo(() => {
    return punchInTime
      ? moment(punchInTime, "hh:mm A").set({
          second: 0,
          millisecond: 0,
        })
      : null;
  }, [punchInTime]);

  useEffect(() => {
    const day = moment();
    let punchTime;
    Object.values(attendanceList).forEach((item: any) => {
      if (moment(item.firstIn, "DD-MM-YYYY").isSame(day, "day")) {
        const [hours, minutes] = item.totalHours.split(":").map(Number);
        const totalMinutes = hours * 60 + minutes;
        punchTime = moment(item.firstIn, "DD-MM-YYYY hh:mm A");
        if (totalMinutes > 0) {
          setPunchOutTime(item.totalHours);
        }
      }
    });
    if (punchTime) {
      //@ts-ignore
      setPunchInTime(moment(punchTime).format("hh:mm A"));
    }
  }, [attendanceList, punchOutTime]);

  useEffect(() => {
    if (!startTime) return;
    const updateTotalTime = () => {
      const now = moment();
      const duration = moment.duration(now.diff(startTime));
      setTotalTime(duration);
    };
    const intervalId = setInterval(updateTotalTime, 1000);
    return () => clearInterval(intervalId);
  }, [startTime]);

  const hours = useMemo(() => {
    const h = Math.floor(totalTime.asHours());
    return Math.max(h, 0);
  }, [totalTime]);

  const minutes = useMemo(() => {
    const m = Math.floor(totalTime.asMinutes() % 60);
    return Math.max(m, 0);
  }, [totalTime]);

  const progressPercent = useMemo(() => {
    const totalHours = hours + minutes / 60;
    const percent = (totalHours / 11) * 100;
    return Math.min(Math.floor(percent), 100);
  }, [hours, minutes]);

  const progressTime = useMemo(() => {
    if (punchOutTime) {
      return punchOutTime;
    }
    return `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }`;
  }, [punchOutTime, hours, minutes]);

  const getUserLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          //@ts-ignore
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting user location: ", error);
        },
      );
    } else {
      console.log("Geolocation is not supported by this browser");
    }
  }, []);

  useEffect(() => {
    getUserLocation();
  }, [getUserLocation]);

  const location = useGeocoding(
    //@ts-ignore
    userLocation?.latitude,
    //@ts-ignore
    userLocation?.longitude,
  );

  const handlePunchInOut = () => {
    let currentTime = moment().format("DD-MMM-YYYY HH:mm:ss");
    let testObj = {};
    if (punchInTime) {
      testObj = {
        checkOut: currentTime,
        emailId: email,
        location: `${location?.county}, ${location?.city}, ${location?.state}`,
      };
      setPunchOutTime(currentTime);
    } else {
      testObj = {
        checkIn: currentTime,
        emailId: email,
        location: `${location?.county}, ${location?.city}, ${location?.state}`,
      };
      setPunchInTime(currentTime);
    }
    addPunchInPuchOut(dispatch, testObj);
  };

  return (
    <Container maxWidth="xl">
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={4} display="flex" alignItems="center">
          {!punchOutTime && (
            <Typography mr={2} fontSize="20px" fontWeight="600" width={80}>
              {progressTime}
            </Typography>
          )}
          {!punchOutTime && (
            <Button
              variant="outlined"
              color={punchInTime ? "error" : "success"}
              onClick={handlePunchInOut}
            >
              {punchInTime ? "Check out" : "Check in"}
            </Button>
          )}
        </Grid>
        <Grid item xs={12} sm={3} display="flex" alignItems="center">
          <FormControl fullWidth>
            <RadioGroup
              row
              name="view-selection"
              value={timeSheetView}
              className={classes.calenderRadio}
              onChange={(e) => setTimeSheetView(e.target.value)}
            >
              <FormControlLabel
                value="List"
                control={<Radio />}
                label="List view"
              />
              <FormControlLabel
                value="Calendar"
                control={<Radio />}
                label="Calendar view"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {timeSheetView === "List" ? (
          <AttendanceList
            progressPercent={progressPercent}
            progressTime={progressTime}
            punchOutTime={punchOutTime}
          />
        ) : (
          <AttendanceCalender />
        )}
      </Grid>
    </Container>
  );
};

export default AttendanceHeader;
