import { City, Country, State } from "country-state-city";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { addClientSchema } from "../../schemas/add-client";
import { getClientById, updateClientRecord } from "../../services/Client";
import { useStyles } from "./styles";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Skeleton from "@mui/material/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import { STRINGS } from "../../Strings";
import RowSkeleton from "../../shared/components/Skeletons/RowSkeleton";
import { getAllWorkClients, getList } from "../Users/User.helper";
import { getAllClientList } from "./Client.helper";
import CompanyDetails from "./CompanyDetails/CompanyDetails";
import { getBase64File, imageUpload } from "./UserDetails/User.helper";
import { patonaAdmin } from "../../Constants";

let initialValues = {
  Id: null,
  Name: "",
  WebSite: "",
  ACN: "",
  Description: "",
  Address: "",
  CountryCode: "",
  StateCode: "",
  PinCode: "",
  City: "",
  LogoImage: "",
  IsActive: false,
  ShortCode: "",
  logoImageFile: "",
  isLogoUpdated: false,
  logoImageFileName: "",
  accountManagerUId: "",
};

type CompanyDetailsProps = {
  id: number;
  name: string;
  shortCode: string;
  webSite: string;
  address: string;
  countryCode: string;
  stateCode: string;
  city: string;
  pinCode: string;
  acn: string;
  description: string;
  logoImageFileName: string;
  logoImageFileURL: string;
  logoImage: any;
  isActive: boolean;
  accountManagerUId: string;
};

const EditClient = (props) => {
  const location: any = useLocation();
  const dispatch = useDispatch();
  const alert = useAlert();
  const classes = useStyles();
  const navigate = useNavigate();
  let [loading, setLoading] = useState(true);
  let [formValues, setFormValues] = useState(null);
  const [value, setValue] = React.useState(0);
  const { clientId, isEdit } = location.state;
  const [fileName, setFileName] = React.useState<any>("");
  const [uploadfile, setUploadfile] = React.useState<any>("");
  const [selectFile, setFile] = React.useState<any>("");
  const [editLogoFile, setEditLogoFile] = React.useState<any>("");
  const [isPicUpdated, setIsPicUpdated] = React.useState<boolean>(false);
  const [clientDetails, setClientDetails] =
    React.useState<CompanyDetailsProps>();

  const [accountManagerUId, setAccountManagerUId] = useState("");

  const [selectedCountry, setSelectedCountry] = React.useState("");
  const [selectedState, setSelectedState] = React.useState("");
  const [selectedCity, setSelectedCity] = React.useState("");

  const [availableCountries, setAvailableCountries] = React.useState<any>([]);
  const [availableState, setAvailableState] = React.useState<any>([]);
  const [availableCities, setAvailableCities] = React.useState<any>([]);

  const { isUserListLoading, userList } = useSelector(
    (state) =>
      //@ts-ignore
      state.user,
  );

  useEffect(() => {
    getList(dispatch, "getActiveUsers", 1, patonaAdmin, 50);
    getClientDetails(clientId);
    loadCountries();
  }, [clientId, isEdit]);

  const loadCountries = () => {
    setAvailableCountries(Country.getAllCountries());
  };

  const loadStates = async (contry) => {
    let states = await State.getStatesOfCountry(contry);
    setAvailableState(states);
  };

  const loadCities = async (state) => {
    let cities = await City.getCitiesOfState(selectedCountry, state);
    setAvailableCities(cities);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const cancelEdit = () => {
    navigate("", {
      state: {
        clientId: clientId,
        isEdit: false,
      },
    });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: formValues || initialValues,
      validationSchema: addClientSchema,
      enableReinitialize: true,
      onSubmit: (values, action) => {
        console.log(values);
        if (selectedCountry !== "") {
          values.CountryCode = selectedCountry;
        }
        if (selectedState !== "") {
          values.StateCode = selectedState;
        }
        if (selectedCity !== "") {
          values.City = selectedCity;
        }
        if (accountManagerUId !== "") {
          values.accountManagerUId = accountManagerUId;
        }
        values.isLogoUpdated = isPicUpdated;
        values.logoImageFileName = fileName;
        values.logoImageFile = uploadfile;
        updateClient(values);
        action.resetForm();
      },
    });

  const updateClient = async (data) => {
    setLoading(true);
    const response = await updateClientRecord(data);
    if (response) {
      if (response.status === 200) {
        alert.success("Client updated successfully.");
        getAllWorkClients(dispatch);
        getAllClientList(dispatch);
        //window.location.reload();
      } else {
        setLoading(false);
        alert.error(response.data);
      }
    } else {
      alert.error("There are some issue while updating the client.");
    }
  };

  const getClientDetails = async (clientId) => {
    const response = await getClientById(clientId);

    if (response && response.status === 200) {
      setClientDetails(response.data);
      setLoading(false);
      initialValues = {
        Id: clientId,
        Name: response.data.name,
        WebSite: response.data.webSite,
        ACN: response.data.acn,
        Description: response.data.description,
        Address: response.data.address,
        CountryCode: response.data.countryCode,
        StateCode: response.data.stateCode,
        PinCode: response.data.pinCode,
        City: response.data.city,
        LogoImage: response.data.logoImage,
        IsActive: response.data.isActive,
        isLogoUpdated: false,
        logoImageFileName: response.data.logoImageFileName,
        logoImageFile: response.data.logoImageFileURL,
        ShortCode: response.data.shortCode,
        accountManagerUId: response.data.accountManagerId,
      };
      setSelectedCountry(response.data.countryCode);
      setSelectedState(response.data.stateCode);
      setSelectedCity(response.data.city);
      setEditLogoFile(response.data.logoImageFileURL);
      loadStates(response.data.countryCode);
      loadCities(response.data.stateCode);
      setAccountManagerUId(response.data.accountManagerId);
      //@ts-ignore
      setFormValues(initialValues);
    } else {
      setLoading(false);
      if (!!response) alert.error(response.data.message);
    }
  };

  function showLogo() {
    if (fileName) {
      return (
        <img
          className={classes.user_pic}
          src={getBase64File(fileName, uploadfile)}
          alt={fileName}
        />
      );
    } else if (editLogoFile) {
      return <img className={classes.user_pic} src={editLogoFile} alt={""} />;
    } else {
      return (
        <Typography variant="h6" className={classes.companyLogoText}>
          {STRINGS.Logo}
        </Typography>
      );
    }
  }

  const getClientID = (id) => {
    navigate("/edit-client", {
      state: {
        isEdit: true,
        clientId: id,
      },
    });
  };

  const editClientInfo = () => {
    return (
      <Box
        className={classes.display_card}
        component="form"
        noValidate
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Box
                display="flex"
                justifyContent="flex-start"
                sx={{ ml: 2, mb: 2 }}
              >
                <Box className={classes.companyLogo}>{showLogo()}</Box>
                <Grid ml={2}>
                  <Typography variant="h6">{STRINGS.CompanyLogo}</Typography>
                  <Typography variant="caption">{STRINGS.ImageSize}</Typography>
                  <Box
                    display={"flex"}
                    sx={{ mt: 2 }}
                    className="upload-btn-wrapper"
                  >
                    <button className="profile_btn">
                      <FileUploadOutlinedIcon
                        sx={{
                          mr: 1,
                          width: 18,
                          height: 18,
                        }}
                      ></FileUploadOutlinedIcon>
                      <Typography variant="caption">
                        {STRINGS.Upload}
                      </Typography>
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      onChange={(e) =>
                        imageUpload(
                          e,
                          setFileName,
                          setFile,
                          alert,
                          setIsPicUpdated,
                          setUploadfile,
                        )
                      }
                    />
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              error={Boolean(touched.Name && errors.Name)}
              helperText={touched.Name && errors.Name}
              fullWidth
              id="Name"
              label={STRINGS.CompanyName}
              name="Name"
              variant="outlined"
              autoComplete="off"
              inputProps={{ maxLength: 25 }}
              value={values.Name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              error={Boolean(touched.Address && errors.Address)}
              helperText={touched.Address && errors.Address}
              fullWidth
              id="Address"
              label={STRINGS.CompanyAddress}
              name="Address"
              variant="outlined"
              autoComplete="off"
              inputProps={{ maxLength: 50 }}
              value={values.Address}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={6} md={6}>
            <FormControl fullWidth>
              <InputLabel>Country</InputLabel>
              <Select
                id="CountryCode"
                name="CountryCode"
                label={STRINGS.Country}
                value={selectedCountry}
                sx={{ width: "100%" }}
                onChange={(e) => {
                  setSelectedCountry(e.target.value);
                  loadStates(e.target.value);
                }}
              >
                {availableCountries?.map((value, key) => {
                  return (
                    <MenuItem value={value.isoCode} key={key}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl fullWidth>
              <InputLabel>State</InputLabel>
              <Select
                id="StateCode"
                name="StateCode"
                label={STRINGS.State}
                value={selectedState}
                onChange={(e) => {
                  setSelectedState(e.target.value);
                  loadCities(e.target.value);
                }}
              >
                {availableState?.map((e, key) => {
                  return (
                    <MenuItem value={e.isoCode} key={key}>
                      {e.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={6} md={6}>
            <FormControl fullWidth>
              <InputLabel>City</InputLabel>
              <Select
                id="City"
                name="City"
                label={STRINGS.City}
                value={selectedCity}
                onChange={(e) => {
                  setSelectedCity(e.target.value);
                }}
              >
                {availableCities?.map((e, key) => {
                  return (
                    <MenuItem value={e.name} key={key}>
                      {e.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={6} md={6}>
            <TextField
              error={Boolean(touched.PinCode && errors.PinCode)}
              helperText={touched.PinCode && errors.PinCode}
              fullWidth
              id="PinCode"
              label={STRINGS.PostalCode}
              name="PinCode"
              variant="outlined"
              autoComplete="off"
              inputProps={{ maxLength: 8 }}
              value={values.PinCode}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={6} md={6}>
            <TextField
              error={Boolean(touched.WebSite && errors.WebSite)}
              helperText={touched.WebSite && errors.WebSite}
              fullWidth
              id="WebSite"
              label={STRINGS.CompanyWebsite}
              name="WebSite"
              variant="outlined"
              autoComplete="off"
              inputProps={{ maxLength: 35 }}
              value={values.WebSite}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <TextField
              error={Boolean(touched.ShortCode && errors.ShortCode)}
              helperText={touched.ShortCode && errors.ShortCode}
              fullWidth
              id="ShortCode"
              label={STRINGS.ClientCode}
              name="ShortCode"
              variant="outlined"
              autoComplete="off"
              inputProps={{ maxLength: 9 }}
              value={values.ShortCode}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={6} sm={12} md={6}>
            <TextField
              error={Boolean(touched.ACN && errors.ACN)}
              helperText={touched.ACN && errors.ACN}
              fullWidth
              id="ACN"
              label={STRINGS.ACN}
              name="ACN"
              variant="outlined"
              autoComplete="off"
              inputProps={{ maxLength: 20 }}
              value={values.ACN}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <FormControl fullWidth>
              <InputLabel>Account Manager</InputLabel>
              <Select
                id="accountManagerUId"
                name="accountManagerUId"
                label={"Account Manager"}
                value={accountManagerUId}
                onChange={(e) => {
                  setAccountManagerUId(e.target.value);
                }}
              >
                {userList?.map((e, key) => {
                  return (
                    <MenuItem value={e.userId} key={key}>
                      {e.userName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              multiline
              rows={3}
              error={Boolean(touched.Description && errors.Description)}
              helperText={touched.Description && errors.Description}
              fullWidth
              id="Description"
              label={STRINGS.AboutCompany}
              name="Description"
              variant="outlined"
              autoComplete="off"
              value={values.Description}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-end">
          <Button variant="text" sx={{ mt: 2 }} onClick={() => cancelEdit()}>
            {STRINGS.Cancel}
          </Button>
          <Button type="submit" variant="contained" sx={{ mt: 2 }}>
            {STRINGS.Update}
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Grid container component="main">
        <Container maxWidth="xl">
          {!clientDetails ? (
            <Card>
              <CardContent>
                <RowSkeleton />
              </CardContent>
            </Card>
          ) : (
            <Box
              className={classes.company_panel}
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Link className={classes.back_icon} to="/client">
                <Typography
                  variant="h6"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <ArrowBackIosNewIcon
                    sx={{
                      mr: "10px",
                    }}
                  />

                  {clientDetails.name}
                </Typography>
              </Link>

              <Grid
                item
                md={4}
                xs={4}
                sx={{ display: "flex" }}
                alignItems={"flex-end"}
                alignSelf={"flex-end"}
                justifyContent={"flex-end"}
              >
                <Button
                  variant="text"
                  onClick={() => getClientID(clientDetails.id)}
                >
                  {STRINGS.Edit}
                </Button>
              </Grid>
            </Box>
          )}

          {!clientDetails ? (
            <Card sx={{ mt: 3 }}>
              <CardContent>
                <Skeleton
                  variant="rectangular"
                  sx={{ width: "100%", height: "300px", mt: 3 }}
                />
              </CardContent>
            </Card>
          ) : (
            <>
              <Box sx={{ width: "100%", mt: 3 }}>
                {isEdit ? (
                  editClientInfo()
                ) : (
                  <CompanyDetails
                    details={clientDetails}
                    userList={userList}
                    isUserListLoading={isUserListLoading}
                  />
                )}
              </Box>
            </>
          )}
        </Container>
      </Grid>
    </>
  );
};

export default EditClient;
