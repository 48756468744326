const MyFilesIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5536 3.36811C3.44539 3.5227 3.42993 4.18744 3.42993 9.01062C3.42993 14.3826 3.42993 14.4831 3.58452 14.6377C3.73911 14.7923 3.83959 14.7923 9.60578 14.7923C15.0782 14.7923 15.4802 14.7845 15.627 14.6531C15.743 14.5527 16.029 13.6715 16.7169 11.2213C17.2348 9.3971 17.6522 7.83575 17.6522 7.74299C17.6522 7.44154 17.343 6.92367 17.0647 6.74589C16.8947 6.64541 16.6164 6.56811 16.3072 6.54492L15.8125 6.49855L15.7739 6.16618C15.7275 5.70241 15.4183 5.26183 15.0087 5.06087C14.6763 4.90628 14.5758 4.89855 11.028 4.89855H7.38742L7.20964 4.37294C6.85409 3.28309 6.69177 3.19806 4.95264 3.19806C3.71592 3.19806 3.66955 3.20579 3.5536 3.36811ZM6.25119 4.22608C6.31302 4.32657 6.39032 4.51207 6.41351 4.65121L6.46761 4.89855H5.60964C5.13814 4.89855 4.66665 4.92947 4.55843 4.97584L4.35747 5.05314V4.55072V4.04831H5.24636C6.08887 4.04831 6.13525 4.05604 6.25119 4.22608ZM14.7845 5.9343C14.8773 6.01932 14.9468 6.17391 14.9468 6.30531V6.52174H10.9816C6.4367 6.52174 6.45988 6.52174 5.81834 7.11691C5.38549 7.52657 5.33911 7.63478 4.78259 9.57488L4.37293 11.0435L4.3652 8.59323L4.35747 6.15072L4.59708 5.94976L4.8367 5.74879L9.72945 5.76425C14.4135 5.78744 14.6299 5.79517 14.7845 5.9343ZM16.686 7.52657C16.8174 7.67343 16.7942 7.7971 15.9439 10.7884L15.0628 13.9034L9.78356 13.9266C5.20771 13.942 4.51206 13.9266 4.51206 13.8338C4.51206 13.5942 6.11979 8.0599 6.24346 7.87439C6.32075 7.75845 6.52172 7.60386 6.6995 7.51884C7.00868 7.37971 7.26375 7.37198 11.7855 7.37971C16.4077 7.37971 16.5546 7.38744 16.686 7.52657Z"
        className="fill-color stroke-color"
        strokeWidth="0.2"
      />
    </svg>
  );
};

export default MyFilesIcon;
