import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
} from "@mui/material";
import { useEffect, useState } from "react";

const CommentModal = ({ open, onClose, onSave, employeeDetails }) => {
  const [localComment, setLocalComment] = useState(
    employeeDetails.comment || "",
  );

  useEffect(() => {
    setLocalComment(employeeDetails.comment || "");
  }, [employeeDetails]);

  const handleLocalSave = () => {
    onSave(employeeDetails.id, localComment);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          margin: "16px",
        },
      }}
    >
      <DialogTitle sx={{ padding: "16px" }}>
        Comment for {employeeDetails?.name}
      </DialogTitle>
      <DialogContent
        sx={{
          margin: 0,
          padding: "0 16px",
        }}
      >
        <TextareaAutosize
          autoFocus
          minRows={3}
          placeholder="Comment here"
          style={{
            width: "94%",
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "8px",
            resize: "none",
          }}
          value={localComment}
          onChange={(event) => setLocalComment(event.target.value)}
        />
      </DialogContent>
      <DialogActions sx={{ padding: "16px" }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleLocalSave} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommentModal;
