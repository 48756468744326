import _ from "lodash";
import { all, delay, put, takeLatest } from "redux-saga/effects";
import { Status } from "../../services/ApiStatus";
import {
  approvalById,
  approvalLeaveSummary,
  approvals,
  calenderApprovals,
  getTeamMemberLeaveInfo,
  getLeaveShortInfo,
  leaveActionService,
  getTeamMemberShortLeaveInfo,
  getLeaveTypesToApply,
  getApproverDetail,
  getApprovalTeammember,
} from "../../services/Approvals";
import { sagaActions } from "../sagaActions";
import {
  approvalCount,
  getApprovalDetailFail,
  getApprovalDetailPending,
  getApprovalDetailSuccess,
  getApprovalListFail,
  getApprovalListPending,
  getApprovalListSuccess,
  getCalenderApprovalDetailPending,
  getCalenderApprovalFail,
  getCalenderApprovalSuccess,
  getLeaveInfoFail,
  getLeaveInfoPending,
  getLeaveInfoSuccess,
  getLeaveTypesForApplyLeavesFail,
  getLeaveTypesForApplyLeavesPending,
  getLeaveTypesForApplyLeavesSuccess,
  getLeavesSummaryFail,
  getLeavesSummarySuccess,
  leaveActionFail,
  leaveActionPending,
  leaveActionSuccess,
  paging,
  teamMemberLeaveShortInfoFail,
  teamMemberLeaveShortInfoPending,
  teamMemberLeaveShortInfoSuccess,
} from "./approvalSlice";

function* fetchApprovalsList(payload: any = "") {
  try {
    if (!_.isUndefined(payload?.payload?.load)) {
      yield put(getApprovalListPending());
    }
    const response = yield approvals(payload.payload);
    if (!!response && response?.status === Status.Success) {
      const { paginationFilter } = response.data;
      const page = Math.ceil(
        paginationFilter.count / paginationFilter.pageSize,
      );
      const isPaging = page > 1 ? true : false;
      yield put(
        paging({
          page: page,
          isPaging: isPaging,
          pageSize: paginationFilter.pageSize,
          currentPage: paginationFilter.pageNumber,
        }),
      );
      yield put(approvalCount(paginationFilter.count));
      yield put(getApprovalListSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(getApprovalListFail(response?.message));
        yield put(paging({ page: 0, isPaging: false }));
        yield put(approvalCount(0));
      }
    }
  } catch (error: any) {
    yield put(getApprovalListFail(error?.message));
    yield put(paging({ page: 0, isPaging: false }));
    yield put(approvalCount(0));
  }
}

function* fetchApprovalDetailById(payload: any = "") {
  try {
    yield put(leaveActionSuccess(""));
    yield put(getApprovalDetailPending());
    if (!_.isUndefined(payload?.payload?.email)) {
      const leaveSummaryresponse = yield approvalLeaveSummary(payload.payload);
      if (
        !!leaveSummaryresponse &&
        leaveSummaryresponse?.status === Status.Success
      ) {
        yield put(getLeavesSummarySuccess(leaveSummaryresponse?.data));
      } else {
        if (
          !!leaveSummaryresponse &&
          leaveSummaryresponse?.code !== Status.Unauthorized
        ) {
          yield put(getLeavesSummaryFail());
        }
      }
    }

    if (!_.isUndefined(payload.payload.id)) {
      const response = yield approvalById(payload.payload);
      if (!!response && response?.status === Status.Success) {
        console.log("response", response);

        yield put(getApprovalDetailSuccess(response.data));
      } else {
        if (!!response && response?.code !== Status.Unauthorized) {
          yield put(getApprovalDetailFail(response?.message));
        }
      }
    }
  } catch (error: any) {
    yield put(getApprovalDetailFail(error?.message));
    yield put(getLeavesSummaryFail());
  }
}

function* fetchCalenderApprovalsList(payload: any = "") {
  try {
    if (!_.isUndefined(payload?.payload?.load)) {
      yield put(getCalenderApprovalDetailPending());
    }
    const response = yield calenderApprovals(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(getCalenderApprovalSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(getCalenderApprovalFail(response?.message));
      }
    }
  } catch (error: any) {
    yield put(getCalenderApprovalFail(error?.message));
  }
}

function* getLeaveEmployeeInfo(payload: any = "") {
  try {
    if (!_.isUndefined(payload?.payload?.load)) {
      yield put(getLeaveInfoPending());
    }
    const response = yield getLeaveShortInfo(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(getLeaveInfoSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(getLeaveInfoFail(response?.message));
      }
    }
  } catch (error: any) {
    yield put(getLeaveInfoFail(error?.message));
  }
}

function* getTeamMemberUpcomingPastLeaveInfo(payload: any = "") {
  try {
    if (!_.isUndefined(payload?.payload)) {
      yield put(getLeaveInfoPending());
    }
    const response = yield getTeamMemberLeaveInfo(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(getLeaveInfoSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(getLeaveInfoFail(response?.message));
      }
    }
  } catch (error: any) {
    yield put(getLeaveInfoFail(error?.message));
  }
}

function* teamMemberLeaveShortInfo(payload: any) {
  try {
    if (!_.isUndefined(payload?.payload)) {
      yield put(teamMemberLeaveShortInfoPending());
    }
    let response = yield getTeamMemberShortLeaveInfo(payload.payload);
    if (!!response && response?.status === Status.Success) {
      let approverDetails = yield getApproverDetail(
        response.data.leaveRecordId,
      );
      if (!!approverDetails && approverDetails?.status === Status.Success) {
        yield put(
          teamMemberLeaveShortInfoSuccess({
            ...response.data,
            ...approverDetails.data,
          }),
        );
      } else {
        if (
          !!approverDetails &&
          approverDetails?.code !== Status.Unauthorized
        ) {
          yield put(teamMemberLeaveShortInfoFail(response?.message));
        }
      }
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(teamMemberLeaveShortInfoFail(response?.message));
      }
    }
  } catch (error: any) {
    yield put(getLeaveInfoFail(error?.message));
  }
}

function* leaveAction(leaveActionpayload: any = "") {
  try {
    yield put(leaveActionPending());

    const response = yield leaveActionService(leaveActionpayload.payload);
    if (!!response && response?.status === Status.Success) {
      yield delay(5000);
      const request = {
        payload: { id: leaveActionpayload.payload.id },
      };
      yield fetchApprovalDetailById(request);
      yield put(leaveActionSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(leaveActionFail(response?.message));
      }
    }
  } catch (error: any) {
    yield put(leaveActionFail(error?.message));
  }
}

function* leaveTypeForApplyLeave(payload: any) {
  try {
    yield put(getLeaveTypesForApplyLeavesPending());
    const response = yield getLeaveTypesToApply(payload.payload);
    if (!!response && response?.status === Status.Success) {
      yield put(getLeaveTypesForApplyLeavesSuccess(response.data));
    } else {
      if (!!response && response?.code !== Status.Unauthorized) {
        yield put(getLeaveTypesForApplyLeavesFail(response?.message));
      }
    }
  } catch (error: any) {
    yield put(getLeaveTypesForApplyLeavesFail(error?.message));
  }
}

function* applyLeaveTeammember(payload: any) {
  try {
    const result = yield getApprovalTeammember(payload.payload);
    return result;
  } catch (error) {
    console.error("Error applying leave:", error);
  }
}

export default function* watcherSaga() {
  yield all([
    yield takeLatest(sagaActions.GET_APPOROVALS_LIST, fetchApprovalsList),
    yield takeLatest(
      sagaActions.GET_APPOROVALS_DETAIL,
      fetchApprovalDetailById,
    ),
    yield takeLatest(
      sagaActions.GET_CALENDER_APPOROVALS_LIST,
      fetchCalenderApprovalsList,
    ),
    yield takeLatest(sagaActions.GET_LEAVE_INFO, getLeaveEmployeeInfo),
    yield takeLatest(sagaActions.LEAVE_ACTION, leaveAction),
    yield takeLatest(
      sagaActions.GET_UPCOMING_PAST_LEAVE_LIST,
      getTeamMemberUpcomingPastLeaveInfo,
    ),
    yield takeLatest(
      sagaActions.GET_TEAM_MEMBER_SHORT_LEAVE_INFO,
      teamMemberLeaveShortInfo,
    ),
    yield takeLatest(
      sagaActions.GET_LEAVE_TYPE_TO_APPLY_LEAVES,
      leaveTypeForApplyLeave,
    ),
    yield takeLatest(sagaActions.APPLY_LEAVES, applyLeaveTeammember),
  ]);
}
