import Moment from "moment";
import { Dispatch } from "@reduxjs/toolkit";
import { sagaActions } from "../../redux/sagaActions";
import { Client } from "../../Types/Client/Client";
import { rowPerPage } from "../../Constants";

export const getAllClientList = async (dispatch: Dispatch<any>) => {
  try {
    const payload = {
      load: 0,
    };
    dispatch({ type: sagaActions.FETCH_CLIENTS, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getAllClientAdminsList = async (
  dispatch: Dispatch<any>,
  clientId = 0,
) => {
  try {
    const payload = {
      clientId: clientId,
    };
    dispatch({ type: sagaActions.FETCH_CLIENTS_ADMINS, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const pagingList = async (
  page: number,
  dispatch: Dispatch<any>,
  pageSize = rowPerPage,
) => {
  try {
    const payload = {
      PageNumber: page,
      pageSize,
    };
    dispatch({ type: sagaActions.FETCH_CLIENTS, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const sortClient = async (
  sort,
  key,
  dispatch: Dispatch<any>,
  pageSize = rowPerPage,
) => {
  try {
    const payload = {
      isDesc: sort,
      columnName: key,
      pageSize,
    };
    dispatch({ type: sagaActions.FETCH_CLIENTS, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const searchClient = async (search, dispatch: Dispatch<any>) => {
  try {
    const payload = {
      SearchValue: search,
    };
    dispatch({ type: sagaActions.FETCH_CLIENTS, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const disableEnableClient = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.DISABLE_ENABLE_CLIENT, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const clientHeader = () => {
  const columns = [
    {
      id: 1,
      name: "Client",
      soryKey: "CompanyName",
      type: "heading",
      sortable: true,
      display: true,
      color: "#000000",
      fontSize: "0.875rem",
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 2,
      name: "Client code",
      soryKey: "Clientcode",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: "0.875rem",
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 3,
      name: "Location",
      soryKey: "Country",
      type: "heading",
      sortable: true,
      display: true,
      color: "#000000",
      fontSize: "0.875rem",
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 4,
      name: "Website",
      soryKey: "WebSite",
      type: "heading",
      display: true,
      sortable: true,
      color: "#000000",
      fontSize: "0.875rem",
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 5,
      name: "ACN",
      soryKey: "ACN",
      type: "heading",
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: "0.875rem",
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 6,
      name: "",
      type: "rightAlignText",
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: "0.875rem",
      fontWeight: "500",
      textTransform: "capitalize",
    },
  ];
  return columns;
};

export const clientRow = (rowValue: Array<Client>) => {
  const row =
    rowValue.length > 0 &&
    rowValue?.map((rowV) => {
      let columns: any = [];
      columns.push(
        {
          id: rowV.id,
          name: rowV.name ? rowV.name : "-",
          imageName: rowV.logoImageFileName ? rowV.logoImageFileName : "-",
          imageUrl: rowV.logoImageURL ? rowV.logoImageURL : "-",
          type: "title",
          experience: false,
          display: true,
          avatar: true,
          editable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
        },
        {
          id: rowV.id,
          name: rowV?.shortCode ? rowV?.shortCode : "-",
          type: "text",
          experience: false,
          display: true,
          editable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
        },
        {
          id: rowV.id,
          name: rowV?.countryCode ? rowV?.countryCode : "-",
          type: "text",
          experience: false,
          display: true,
          editable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
        },
        {
          id: rowV.id,
          name: rowV.webSite ? rowV.webSite : "-",
          type: "text",
          experience: false,
          display: true,
          editable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "lowercase",
        },
        {
          id: rowV.id,
          name: rowV?.acn ? rowV?.acn : "-",
          type: "text",
          experience: false,
          display: true,
          editable: false,
          editableType: "number",
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
        },
        {
          id: rowV.id,
          name: "",
          type: "action",
          experience: false,
          display: true,
          editable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
          peopleId: rowV?.id,
          disable: rowV?.isActive,
        },
      );
      const isDisabled = !rowV?.isActive;
      return {
        ...rowV,
        isDisabled: isDisabled,
        teamMemberId: rowV.id,
        columns,
      };
    });

  return row && row.length > 0 ? row : [];
};
