import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { useStyles } from "../styles";

const ImmediateActionFeedbackContent = ({ details, onClose }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.modalHeader}>
        <Typography variant="h5" className={classes.customTypography}>
          Immediate action
        </Typography>

        <IconButton onClick={onClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </Box>
      <Box className={classes.modalBody}>
        <Typography variant="h6">Reason for immediate action</Typography>

        <Box className={classes.commentBox}>
          <Typography variant="body2" color="#707D84" mb={1}>
            Reason
          </Typography>

          <Typography variant="caption">
            {details.immediateActionReason}
          </Typography>
        </Box>

        <Typography variant="caption" fontWeight={600}>
          Feedback from: {details.from}
        </Typography>
      </Box>
    </>
  );
};

export default ImmediateActionFeedbackContent;
