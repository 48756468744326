import StarIcon from "@mui/icons-material/Star";
import { Button, MenuItem, Paper, Rating, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-outlined": {
    padding: "0 !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

const PerformanceSubHeaderMobile = ({
  onBulkRatingChange,
  onSubmit,
  feedback,
}) => {
  const [bulkRating, setBulkRating] = useState(0);
  const selected = feedback ? Object.keys(feedback) : [];
  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
      }}
    >
      <CustomSelect
        value={bulkRating}
        onChange={onBulkRatingChange}
        renderValue={(value) => <StarIcon sx={{ color: "#faaf00" }} />}
        sx={{ width: 50, border: "none", ml: 2 }}
        onBlur={(event) => {
          event.stopPropagation(); // Fix for "Failed to execute 'contains' on 'Node': parameter 1 is not of type 'Node'."
        }}
      >
        {[5, 4, 3, 2, 1].map((rating) => (
          <MenuItem key={rating} value={rating}>
            <Rating value={rating} readOnly precision={1} emptyIcon={<></>} />
          </MenuItem>
        ))}
      </CustomSelect>

      {selected.length > 0 && (
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          sx={{ backgroundColor: "#002DFF", height: 40 }}
        >
          Submit
        </Button>
      )}
    </Paper>
  );
};

export default PerformanceSubHeaderMobile;
