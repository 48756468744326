import { createSlice } from "@reduxjs/toolkit";
import { rowPerPage } from "../../Constants";

const initialState = {
  approvalList: [],
  leavesSummary: [],
  approvalDetail: {},
  leaveInfoList: [],
  isLeaveInfoLoading: false,
  leaveInfoError: "",
  isApprovalDetailLoading: false,
  approvalDetailError: "",
  isApprovalListLoading: false,
  approvalListError: "",
  isPaging: false,
  page: 0,
  approvalPageSize: rowPerPage,
  approvalCurrentPage: 0,
  totalApproval: 0,
  isCalenderApprovalListLoading: false,
  calenderApprovalList: [],
  calenderApprovalListError: "",
  leaveActionPending: false,
  leaveInfo: [],
  bodyPayload: {
    leaveStatusList: ["Pending", "Approved", "Rejected"],
  },
  teamMemberLeaveShortInfo: {},
  teamMemberLeaveShortInfoError: "",
  isTeamMemberLeaveShortInfoLoading: false,
  leaveTypes: [],
  leaveTypesError: "",
  leaveTypesLoading: false,
};

const approvalSlice = createSlice({
  name: "approval",
  initialState,
  reducers: {
    setLeaveBodyPayload: (state, { payload }) => {
      state.bodyPayload = payload;
    },
    leaveActionPending: (state) => {
      state.leaveActionPending = true;
    },
    leaveActionSuccess: (state, { payload }) => {
      state.leaveActionPending = false;
    },
    leaveActionFail: (state, { payload }) => {
      state.isLeaveInfoLoading = false;
      state.leaveInfo = [];
    },
    getLeaveInfoPending: (state) => {
      state.isLeaveInfoLoading = true;
    },
    getLeaveInfoDonePending: (state) => {
      state.isLeaveInfoLoading = false;
    },
    getLeaveInfoSuccess: (state, { payload }) => {
      state.isLeaveInfoLoading = false;
      state.leaveInfoList = payload;
      state.leaveInfoError = "";
    },
    getLeaveInfoFail: (state, { payload }) => {
      state.isLeaveInfoLoading = false;
      state.leaveInfoList = [];
      state.leaveInfoError = payload;
    },
    paging: (state, { payload }) => {
      state.isPaging = payload.isPaging;
      state.page = payload.page;
      state.approvalPageSize = payload.pageSize;
      state.approvalCurrentPage = payload.currentPage;
    },
    approvalCount: (state, { payload }) => {
      state.totalApproval = payload;
    },
    getApprovalListPending: (state) => {
      state.isApprovalListLoading = true;
    },
    getApprovalListNoPending: (state) => {
      state.isApprovalListLoading = false;
    },
    getApprovalListSuccess: (state, { payload }) => {
      state.isApprovalListLoading = false;
      state.approvalList = payload.leaveApprovals;
      state.approvalListError = "";
    },
    getApprovalListFail: (state, { payload }) => {
      state.isApprovalListLoading = false;
      state.approvalListError = payload;
    },
    clearApprovalList: (state) => {
      state.approvalList = [];
    },
    getApprovalDetailPending: (state) => {
      state.isApprovalDetailLoading = true;
    },
    getApprovalDetailDonePending: (state) => {
      state.isApprovalDetailLoading = false;
    },
    getApprovalDetailSuccess: (state, { payload }) => {
      state.isApprovalDetailLoading = false;
      state.approvalDetail = payload;
      state.approvalDetailError = "";
    },
    getApprovalDetailFail: (state, { payload }) => {
      state.isApprovalDetailLoading = false;
      state.approvalDetailError = payload;
    },
    clearApprovalDetail: (state) => {
      state.approvalDetail = {};
      state.approvalDetailError = "";
    },
    getLeavesSummarySuccess: (state, { payload }) => {
      state.leavesSummary = payload;
    },
    getLeavesSummaryFail: (state) => {
      state.leavesSummary = [];
    },
    getCalenderApprovalDetailPending: (state) => {
      state.isCalenderApprovalListLoading = true;
    },
    getCalenderApprovalSuccess: (state, { payload }) => {
      state.isCalenderApprovalListLoading = false;
      state.calenderApprovalList = payload.dateData;
      state.calenderApprovalListError = "";
    },
    getCalenderApprovalFail: (state, { payload }) => {
      state.isCalenderApprovalListLoading = false;
      state.calenderApprovalList = [];
      state.calenderApprovalListError = payload;
    },
    teamMemberLeaveShortInfoPending: (state) => {
      state.isTeamMemberLeaveShortInfoLoading = true;
    },
    teamMemberLeaveShortInfoSuccess: (state, { payload }) => {
      state.isTeamMemberLeaveShortInfoLoading = false;
      state.teamMemberLeaveShortInfo = payload;
      state.teamMemberLeaveShortInfoError = "";
    },
    teamMemberLeaveShortInfoFail: (state, { payload }) => {
      state.isTeamMemberLeaveShortInfoLoading = false;
      state.teamMemberLeaveShortInfo = {};
      state.teamMemberLeaveShortInfoError = payload;
    },
    getLeaveTypesForApplyLeavesPending: (state) => {
      state.leaveTypesLoading = true;
    },
    getLeaveTypesForApplyLeavesSuccess: (state, { payload }) => {
      state.leaveTypesLoading = false;
      state.leaveTypes = payload;
      state.leaveTypesError = "";
    },
    getLeaveTypesForApplyLeavesFail: (state, { payload }) => {
      state.leaveTypesLoading = false;
      state.leaveTypes = [];
      state.leaveTypesError = payload;
    },
  },
});

export const {
  getLeaveInfoDonePending,
  getApprovalDetailDonePending,
  getApprovalListNoPending,
  getLeaveInfoPending,
  getLeaveInfoSuccess,
  getLeaveInfoFail,
  getCalenderApprovalFail,
  getCalenderApprovalSuccess,
  getCalenderApprovalDetailPending,
  getLeavesSummaryFail,
  getLeavesSummarySuccess,
  clearApprovalDetail,
  getApprovalDetailFail,
  getApprovalDetailSuccess,
  getApprovalDetailPending,
  paging,
  approvalCount,
  getApprovalListSuccess,
  getApprovalListFail,
  clearApprovalList,
  getApprovalListPending,
  leaveActionPending,
  leaveActionSuccess,
  leaveActionFail,
  setLeaveBodyPayload,
  teamMemberLeaveShortInfoPending,
  teamMemberLeaveShortInfoSuccess,
  teamMemberLeaveShortInfoFail,
  getLeaveTypesForApplyLeavesPending,
  getLeaveTypesForApplyLeavesSuccess,
  getLeaveTypesForApplyLeavesFail,
} = approvalSlice.actions;

export default approvalSlice.reducer;
