import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Autocomplete, TextField } from "@mui/material";

import { sagaActions } from "../../../../../redux/sagaActions";

const SearchableEmployeeField = ({ onSelect }) => {
  const dispatch = useDispatch();
  const { searchableList = [] } = useSelector(
    (state: any) => state.performance.allEmployees,
  );

  // This value is available when the user clicks on the "Give feedback" button in the Performance Main Page
  const [selectedEmployee, setSelectedEmployee] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (searchableList.length > 0) {
      setIsLoading(false);
    }
  }, [searchableList]);

  const fetchEmployeeList = (searchString: string = "") => {
    dispatch({
      type: sagaActions.SEARCH_EMPLOYEE_BY_NAME,
      payload: {
        searchValue: searchString,
        pageNumber: 1,
      },
    });
  };

  const handleEmployeeChange = (newValue: any) => {
    setSelectedEmployee(newValue);
    onSelect({
      id: newValue?.employeeId,
      clientId: newValue?.clientId,
      lastScore: newValue?.lastScore,
      isLastMonthFeedbackSubmitted: newValue?.isLastMonthFeedbackSubmitted,
    });
  };

  const handleInputChange = (newInputValue: string) => {
    if (newInputValue.length > 2) {
      setIsLoading(true);
      setTimeout(() => {
        fetchEmployeeList(newInputValue);
      }, 1000);
    }
  };

  return (
    <Autocomplete
      loading={isLoading}
      options={searchableList}
      getOptionLabel={(option: any) => option.name}
      style={{ margin: "normal", width: "100%" }}
      value={selectedEmployee}
      onChange={(_, value) => handleEmployeeChange(value)}
      onInputChange={(_, value) => handleInputChange(value)}
      renderInput={(params) => (
        <TextField {...params} label="Search Employee name" />
      )}
    />
  );
};

export default SearchableEmployeeField;
