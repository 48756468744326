import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { sagaActions } from "../../redux/sagaActions";
import AnnualFeedbackTable from "./annual/AnnualFeedbackTable";
import Layout from "./common/Layout";
import PerformanceTab from "./common/tabs/PerformanceTab";
import PerformanceTabPanel from "./common/tabs/PerformanceTabPanel";
import PerformanceHeader from "./header/PerformanceHeader";
import AllPerformanceTable from "./overview/PerformanceOverview";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const DesktopView = () => {
  let query = useQuery();
  const tabName = query.get("tab");
  const location: any = useLocation();

  const dispatch = useDispatch();
  const [selectedEmploymentType, setSelectedEmploymentType] = useState("All");
  const [searchString, setSearchString] = useState("");
  const pageRendered = useRef(0);
  const [tabValue, setTabValue] = useState<number>(() =>
    tabName === "annual-feedback" ? 0 : 1,
  );

  const { clientCode } = useSelector(
    //@ts-ignore
    (state) => state.client,
  );
  const { pageSize } = useSelector(
    //@ts-ignore
    (state) => state.performance.allEmployees,
  );

  const fetchEmployeePerformance = (
    pageNumber: number = 1,
    sortColumn: string = "name",
    isDesc: boolean = false,
    pageSize: number = 10,
  ) => {
    dispatch({
      type: sagaActions.FETCH_ALL_EMPLOYEES_PERFORMANCE,
      payload: {
        employeeType:
          selectedEmploymentType === "All" ? "" : selectedEmploymentType,
        pageNumber: pageNumber,
        searchValue: searchString.trim(),
        columnName: sortColumn,
        forAnualFeedback: tabValue === 0,
        isDesc,
        pageSize,
      },
    });
  };

  useEffect(() => {
    if (
      pageRendered.current === 0 &&
      location.state !== null &&
      location.state.pageNumber !== undefined
    ) {
      fetchEmployeePerformance(
        location.state.pageNumber,
        "name",
        false,
        location.state.pageSize,
      );
      pageRendered.current = 1;
    } else {
      fetchEmployeePerformance();
    }
  }, [selectedEmploymentType, clientCode, searchString, tabValue]);

  const handlePageChange = (parmPageNumber: number) => {
    fetchEmployeePerformance(parmPageNumber, "name", false, pageSize);
  };

  const handlePagesizeChange = (parmPageSize: number) => {
    fetchEmployeePerformance(1, "name", false, parmPageSize);
  };

  const handleSort = (column) => {
    const columnDetails = column[0];

    if (!columnDetails) return;

    fetchEmployeePerformance(
      1,
      columnDetails.field,
      columnDetails.sort === "desc",
    );
  };

  const handleTabChange = (
    event: React.SyntheticEvent,
    newTabValue: number,
  ) => {
    setTabValue(newTabValue);
  };

  const onRefresh = () => {
    fetchEmployeePerformance();
  };

  return (
    <>
      <Layout
        header={
          <PerformanceHeader
            refresh={onRefresh}
            selectedEmploymentType={selectedEmploymentType}
            setSelectedEmploymentType={setSelectedEmploymentType}
            setSearchString={setSearchString}
            tabValue={tabValue}
          />
        }
        subHeader={
          <PerformanceTab
            handleTabChange={handleTabChange}
            tabValue={tabValue}
          />
        }
        body={
          <>
            <PerformanceTabPanel tabValue={tabValue} index={0}>
              <AnnualFeedbackTable
                handlePageChange={handlePageChange}
                handleSort={handleSort}
                handlePagesizeChange={handlePagesizeChange}
              />
            </PerformanceTabPanel>
            <PerformanceTabPanel tabValue={tabValue} index={1}>
              <AllPerformanceTable
                handlePageChange={handlePageChange}
                handleSort={handleSort}
                handlePagesizeChange={handlePagesizeChange}
              />
            </PerformanceTabPanel>
          </>
        }
      />
    </>
  );
};

export default DesktopView;
