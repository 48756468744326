import React, { useState, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import Moment from "moment";
import { Bar, Line, getElementsAtEvent } from "react-chartjs-2";

import { useNavigate } from "react-router-dom";

//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
);

const InvoiceGraph = (props) => {
  const {
    graphdata,
    height = "69%",
    type,
    filter = false,
    ratio = 3,
    rightRange = false,
    callBack = () => null,
  } = props;

  const options = {
    responsive: true,
    aspectRatio: ratio,
    scales: {
      y: {
        position: "left" as const,
      },
    },
  };

  const options2 = {
    responsive: true,
    aspectRatio: ratio,
    scales: {
      y: {
        position: "left" as const,
      },
      y1: {
        position: "right" as const,
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const navigate = useNavigate();
  const chartRef = useRef();
  const onClick = (event) => {
    //@ts-ignore

    if (getElementsAtEvent(chartRef?.current, event).length > 0 && filter) {
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const currentDate = new Date();
      var year = currentDate.getFullYear();
      var month = months.indexOf(
        graphdata?.labels[
          //@ts-ignore
          getElementsAtEvent(chartRef?.current, event)[0].index
        ],
      );
      var firstDay = new Date(year, month, 1);
      var lastDay = new Date(year, month + 1, 0);

      const payload = {
        dateFrom: Moment(new Date(firstDay)).format("YYYY-MM-DD"),
        dateTo: Moment(new Date(lastDay)).format("YYYY-MM-DD"),
      };
      callBack(payload);
    }
  };

  //@ts-ignore
  return type === "line" ? (
    <Line height={height} options={options} data={graphdata} />
  ) : (
    <Bar
      height={height}
      options={rightRange ? options2 : options}
      data={graphdata}
      onClick={onClick}
      ref={chartRef}
    />
  );
};

export default InvoiceGraph;
