import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import moment from "moment";
import { FC, memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import {
  EventObject,
  useAddInterviewMeetingMutation,
  useUpdateInterviewMeetingMutation,
} from "../../redux/Hire/candidateMeetingApi";
import FormikInput from "../../shared/components/FormikInput";
import { userAccess } from "../../utils/CommonFunction";

const timeGeneration = () => {
  const items: Array<{
    value: number;
    name: string;
  }> = [];

  new Array(24).fill(0).forEach((acc, index) => {
    let time = moment({ hour: index });
    items.push({ value: time.valueOf(), name: time.format("h:mm A") });
    time = time.add({ minutes: 15 });
    items.push({ value: time.valueOf(), name: time.format("h:mm A") });
    time = time.add({ minutes: 15 });
    items.push({ value: time.valueOf(), name: time.format("h:mm A") });
    time = time.add({ minutes: 15 });
    items.push({ value: time.valueOf(), name: time.format("h:mm A") });
  });
  return items;
};

const duration = [
  {
    name: "30 minutes",
    value: 30,
  },
  {
    name: "45 minutes",
    value: 45,
  },
  {
    name: "1 hour",
    value: 60,
  },
];

const validationSchema = yup.object({
  title: yup.string().required("Title is required"),
  description: yup.string(),
  meetingStartDate: yup.string().required("Date is required"),
  attendeesEmail: yup
    .array()
    .of(yup.string().email())
    .min(1, "Add atleast 1 member")
    .max(4, "Only 4 members are allowed"),
  duration: yup.number().required("Duration is required"),
  startTime: yup.number().required("Start time is required"),
  name: yup.string().email("should be an email"),
});

type Props = {
  candidateId: string;
  candidateName: string;
  clientId: string;
  clientName: string;
  jobId: string;
  roleName: string;
  candidateEmail: string;
  meetingDetails?: EventObject;
  closeReschedule: () => void;
};

const ScheduleCall: FC<Props> = ({
  candidateId,
  candidateName,
  clientId,
  clientName,
  jobId,
  roleName,
  candidateEmail,
  meetingDetails,
  closeReschedule,
}) => {
  const [initialValues, setInitialValues] = useState<any>(
    meetingDetails
      ? {
          title: meetingDetails.title,
          description: meetingDetails.description,
          meetingStartDate: moment(new Date(meetingDetails.meetingStartedOn)),
          startTime: moment(meetingDetails.meetingStartedOn).valueOf(),
          attendeesEmail: meetingDetails.attendeesEmail.split(","),
          duration: moment
            .duration(
              moment(meetingDetails.meetingEndedOn).diff(
                moment(meetingDetails.meetingStartedOn),
              ),
            )
            .asMinutes(),
          name: "",
        }
      : {
          title: "1st Round Interview",
          description:
            "Your call will be recorded for analyzing and safety purpose",
          meetingStartDate: "",
          startTime: "",
          attendeesEmail: [],
          duration: "",
          name: "",
        },
  );

  const [addMeetingApi, { isLoading, isSuccess }] =
    useAddInterviewMeetingMutation();
  const [
    updateMeeting,
    { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess },
  ] = useUpdateInterviewMeetingMutation();

  useEffect(() => {
    if (isSuccess || isUpdateSuccess) {
      closeReschedule();
    }
  }, [closeReschedule, isSuccess, isUpdateSuccess]);

  const { accessToken } = useSelector(
    (state) =>
      //@ts-ignore
      state.login,
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(data) => {
        const meetingRequest = {
          id: meetingDetails ? meetingDetails.id : undefined,
          candidateId,
          candidateName,
          clientId: "999",
          clientName,
          description: data.description,
          interviewerName: data.attendeesEmail[0],
          jobId,
          eventId: meetingDetails ? meetingDetails.eventId : undefined,
          link: meetingDetails ? meetingDetails.link : undefined,
          role: roleName,
          scheduledBy: userAccess().firstName + " " + userAccess().lastName,
          token: accessToken,
          userId: userAccess().userId,
          attendeesEmail: data.attendeesEmail.includes(candidateEmail)
            ? data.attendeesEmail
            : data.attendeesEmail.concat(candidateEmail).filter((el) => el),
          meetingStartDate: moment(data.meetingStartDate)
            .hour(moment(data.startTime).hour())
            .minute(moment(data.startTime).minute())
            .toISOString(),
          meetingEndDate: moment(data.meetingStartDate)
            .hour(moment(data.startTime).hour())
            .minute(moment(data.startTime).minute())
            .add({ minutes: data.duration })
            .toISOString(),
          title: data.title,
        };
        if (meetingDetails) {
          updateMeeting(meetingRequest);
        } else {
          addMeetingApi(meetingRequest);
        }
      }}
    >
      {(formikProps) => (
        <Form>
          <Grid container spacing={2}>
            {meetingDetails && (
              <Grid item xs={12}>
                <Typography fontWeight={500} variant="body2">
                  Reschedule the call
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <FormikInput
                label="Add title"
                name="title"
                formikProps={formikProps}
                options={undefined}
                fullWidth
              />
            </Grid>
            <Grid container item xs={12} gap={2}>
              <FormikInput
                label="Add description"
                name="description"
                formikProps={formikProps}
                options={undefined}
                inputType="textarea"
                rows={3}
                multiline
                width={"100%"}
                fullWidth
                className="full-width"
              />
            </Grid>
            <Grid container item xs={12} gap={1} alignItems={"center"}>
              <Grid item xs={2.5}>
                <Typography variant="body2" fontWeight={500}>
                  Select day and time
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <FormikInput
                  inputType="date-picker"
                  label="Interview Date"
                  inputFormat="MM/DD/YYYY"
                  formikProps={formikProps}
                  name="meetingStartDate"
                  options={undefined}
                  disablePast={true}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikInput
                  label="Start time"
                  name="startTime"
                  className="common-select-dropdown"
                  formikProps={formikProps}
                  options={timeGeneration()}
                  inputType="select"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <FormikInput
                  label="Duration"
                  name="duration"
                  className="common-select-dropdown"
                  formikProps={formikProps}
                  options={duration}
                  inputType="select"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} gap={1} alignItems={"center"}>
              <Grid item xs={2.5}>
                <Typography variant="body2" fontWeight={500}>
                  Add guest
                </Typography>
              </Grid>
              <Grid container item xs={9}>
                <Grid item xs={12} container>
                  <Grid item flex={1}>
                    <FormikInput
                      label="Attendee Email"
                      name="name"
                      formikProps={formikProps}
                      fullWidth
                      options={undefined}
                    />
                  </Grid>

                  <Button
                    disabled={
                      !formikProps.values.name.length &&
                      !formikProps.values.attendeesEmail.includes(
                        formikProps.values.name,
                      )
                    }
                    variant="text"
                    onClick={() => {
                      formikProps.setFieldValue(
                        "attendeesEmail",
                        formikProps.values.attendeesEmail.concat(
                          formikProps.values.name,
                        ),
                        false,
                      );
                      formikProps.setFieldValue("name", "");
                    }}
                  >
                    Add Guest
                  </Button>
                </Grid>
                <Grid container item xs={12} gap={1}>
                  {formikProps.values.attendeesEmail.map((item) => (
                    <Chip
                      key={item}
                      label={item}
                      onDelete={() => {
                        formikProps.setFieldValue(
                          "attendeesEmail",
                          formikProps.values.attendeesEmail.filter(
                            (e) => e !== item,
                          ),
                        );
                      }}
                    />
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ mt: 3, mb: 2 }}
            gap={2}
          >
            {meetingDetails ? (
              <Button type="button" sx={{ mt: 2 }} onClick={closeReschedule}>
                Cancel
              </Button>
            ) : null}

            <Button
              type="submit"
              disabled={isLoading || isUpdateLoading}
              variant="contained"
              sx={{ mt: 2 }}
            >
              {isLoading || isUpdateLoading ? (
                <CircularProgress color="inherit" size={20}></CircularProgress>
              ) : (
                `${meetingDetails ? "Update" : "Send"} invite`
              )}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default memo(ScheduleCall);
