import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { STRINGS } from "../../Strings";

const style = {
  width: "20%",
  overflowY: "hidden",
  height: "25%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const useStyle = makeStyles((theme) => ({
  deleteIcon: {
    height: 90,
    width: 100,
  },
}));

const DeleteMemberPopUp = (props) => {
  const classes = useStyle();
  return (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={props.show}
      onClose={props.close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        justifyContent={"center"}
        display={"block"}
        alignContent={"center"}
        alignItems={"center"}
        alignSelf={"center"}
      >
        <Box
          component="div"
          sx={{ display: "flex", flexDirection: "column" }}
          alignContent={"center"}
          alignItems={"center"}
          alignSelf={"center"}
        >
          <img
            className={classes.deleteIcon}
            src="/images/delete-member.png"
            alt="/images/delete-member.png"
          ></img>

          <Typography
            variant="h6"
            sx={{
              align: "center",
            }}
          >
            {STRINGS.RemoveTeamMember}
          </Typography>

          <Typography
            variant="caption"
            sx={{
              align: "center",
            }}
            py={1}
          >
            {STRINGS.ConfirmRemoveMember}
          </Typography>

          <Box display="flex" justifyContent="center">
            <Button
              variant="text"
              sx={{ mt: 2, mr: 2 }}
              onClick={props.deleteMember}
            >
              {STRINGS.Remove}
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
              onClick={props.close}
            >
              {STRINGS.Cancel}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteMemberPopUp;
