import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { STRINGS } from "../../Strings";

const style = {
  overflowY: "scroll",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

type SuccessMessageProps = {
  show: boolean;
  close: () => void;
  type: string;
  email: string;
};

const SuccessMessage: React.FunctionComponent<SuccessMessageProps> = (
  props,
) => {
  return (
    <>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          alignSelf: "center",
          textAlign: "center",
        }}
        open={props.show}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box p={2}>
            <img src="/images/successinvitation.png" alt="Invitation" />
          </Box>
          <Typography id="modal-modal-title" variant="h4" component="h2">
            {STRINGS.InvitationSent}
          </Typography>
          <Typography id="modal-modal-title" variant="body2" component="div">
            {STRINGS.EmailSentTo}”{" "}
            <Box fontWeight="bold" display="inline">
              {props.email}
            </Box>{" "}
            ”. {STRINGS.PleaseCheckAccount}
          </Typography>

          <Box justifyContent="flex-end" textAlign={"right"}>
            <Button
              onClick={props.close}
              type="submit"
              variant="outlined"
              sx={{ mt: 2 }}
            >
              {STRINGS.OK}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SuccessMessage;
