import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboardActivity: [],
  dashboardJobs: [],
  dashboardHeader: {},
  isDashboardListLoading: false,
  isDashboardCalendarLoading: false,
  dashboardCalendar: [],
  isDashboardFilterLoading: false,
  publicHolidays: {
    Pune: [],
    Philippines: [],
    isLoading: false,
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    dashboardActivity: (state, { payload }) => {
      state.dashboardActivity = payload;
      state.isDashboardListLoading = false;
    },
    dashboardJobs: (state, { payload }) => {
      state.dashboardJobs = payload;
      state.isDashboardListLoading = false;
    },
    clearDashboardActivity: (state) => {
      state.dashboardActivity = [];
      state.isDashboardListLoading = false;
    },
    clearDashboardJobs: (state) => {
      state.dashboardJobs = [];
      state.isDashboardListLoading = false;
    },
    getDashboardListPending: (state) => {
      state.isDashboardListLoading = true;
    },
    getDashboardFilterPending: (state) => {
      state.isDashboardFilterLoading = true;
    },
    getDashboardHeaderSuccess: (state, { payload }) => {
      state.dashboardHeader = payload;
      state.isDashboardFilterLoading = false;
    },
    getDashboardHeaderFail: (state) => {
      state.dashboardHeader = {};
      state.isDashboardFilterLoading = false;
    },
    getDashboardCalendarPending: (state) => {
      state.isDashboardCalendarLoading = true;
    },
    getDashboardCalendarSuccess: (state, { payload }) => {
      state.dashboardCalendar = payload;
      state.isDashboardCalendarLoading = false;
    },
    getDashboardCalendarFail: (state) => {
      state.dashboardCalendar = [];
      state.isDashboardCalendarLoading = false;
    },
    setPublicHolidays: (state, { payload }) => {
      state.publicHolidays[payload.key] = payload.data;
    },
    setHolidayListLoading: (state, { payload }) => {
      state.publicHolidays.isLoading = payload;
    },
    resetPublicHolidays: (state) => {
      state.publicHolidays = initialState.publicHolidays;
    },
  },
});

export const {
  getDashboardCalendarFail,
  getDashboardCalendarSuccess,
  getDashboardCalendarPending,
  dashboardActivity,
  dashboardJobs,
  clearDashboardActivity,
  clearDashboardJobs,
  getDashboardListPending,
  getDashboardFilterPending,
  getDashboardHeaderSuccess,
  getDashboardHeaderFail,
  setPublicHolidays,
  setHolidayListLoading,
  resetPublicHolidays,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
