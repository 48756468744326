import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { changePasswordSchema } from "../../../schemas/change-password";
import { useFormik } from "formik";

import {
  Typography,
  Grid,
  Box,
  Button,
  Container,
  TextField,
  CircularProgress,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { UserData } from "../../../Types/Users/User";
import { updatePassword } from "./User.helper";
import { userAccess } from "../../../utils/CommonFunction";
import { STRINGS } from "../../../Strings";

let initialValues = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
  userId: userAccess().userId,
};

const style = {
  overflowY: "scroll",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

type ChangeUserPasswordProps = {
  cancel?: () => void;
  user: UserData;
  isLoading: false;
};

const ChangeUserPassword: React.FunctionComponent<ChangeUserPasswordProps> = (
  props,
) => {
  const { cancel = () => null, user, isLoading = false } = props;
  const alert = useAlert();
  const dispatch = useDispatch();
  const [formAction, setFormAction] = useState<any>();

  const { timeZone, changePasswordLoading, passwordChangeSuccess } =
    useSelector(
      //@ts-ignore
      (state) => state.user,
    );

  const {
    values,
    errors,
    dirty,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: changePasswordSchema,
    enableReinitialize: true,
    onSubmit: (values, action) => {
      values.userId = userAccess().userId;
      updatePassword(values, dispatch);
      setFormAction(action);
    },
  });

  useEffect(() => {
    if (!_.isNull(passwordChangeSuccess) && changePasswordLoading) {
      //@ts-ignore
      if (!passwordChangeSuccess?.succeeded) {
        const errors = passwordChangeSuccess.errors.join(",");
        alert.error(errors);
      } else {
        alert.success("Your password has been updated successfully");
        formAction.resetForm();
      }
    }
  }, [passwordChangeSuccess]);

  return (
    <>
      <Grid container component="main">
        <Container maxWidth="xl">
          <>
            <Box
              sx={style}
              justifyContent={"center"}
              display={"block"}
              alignContent={"center"}
              alignItems={"center"}
              alignSelf={"center"}
            >
              <Box component="form" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={6} sm={6} md={6}>
                    <Typography variant="h4">
                      {STRINGS.ChangePassword}
                    </Typography>
                    <Typography variant="caption">
                      {STRINGS.PasswordSuggestion} :{" "}
                      {STRINGS.PasswordSuggestion1},
                    </Typography>
                    <br />
                    <Typography variant="caption">
                      {STRINGS.PasswordSuggestion2},
                    </Typography>
                    <br />
                    <Typography variant="caption">
                      {STRINGS.PasswordSuggestion3},
                    </Typography>
                    <br />
                    <Typography variant="caption">
                      {STRINGS.PasswordSuggestion4},
                    </Typography>
                    <br />
                    <Typography variant="caption">
                      {STRINGS.PasswordSuggestion5}.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <img src="/images/change-pass.svg" alt="icon" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      error={Boolean(touched.oldPassword && errors.oldPassword)}
                      helperText={touched.oldPassword && errors.oldPassword}
                      margin="normal"
                      fullWidth
                      id="oldPassword"
                      type={"password"}
                      label={STRINGS.CurrentPassword}
                      name="oldPassword"
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{ maxLength: 25 }}
                      value={values.oldPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      error={Boolean(touched.newPassword && errors.newPassword)}
                      helperText={touched.newPassword && errors.newPassword}
                      margin="normal"
                      fullWidth
                      id="newPassword"
                      type={"password"}
                      label={STRINGS.NewPassword}
                      name="newPassword"
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{ maxLength: 25 }}
                      value={values.newPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword,
                      )}
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      margin="normal"
                      fullWidth
                      id="confirmPassword"
                      type={"password"}
                      label={STRINGS.ConfirmNewPassword}
                      name="confirmPassword"
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{ maxLength: 25 }}
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>

                <Box display="flex" justifyContent="flex-end">
                  <Button onClick={cancel} variant="text" sx={{ mt: 2 }}>
                    {STRINGS.Cancel}
                  </Button>

                  <Button
                    disabled={!dirty}
                    type="submit"
                    variant="contained"
                    sx={{ mt: 2 }}
                  >
                    {changePasswordLoading && (
                      <CircularProgress size={18} color="warning" />
                    )}
                    {!changePasswordLoading && STRINGS.Update}
                  </Button>
                </Box>
              </Box>
            </Box>
          </>
        </Container>
      </Grid>
    </>
  );
};

export default ChangeUserPassword;
