import { Avatar, Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STRINGS } from "../../../Strings";
import { UserData } from "../../../Types/Users/User";
import { userAccess } from "../../../utils/CommonFunction";
import { getMemberById, imageReturnFromZoho } from "../../People/People.helper";
import { useStyles } from "../CompanyDetails/styles";

type UserDetailsProps = {
  enableEdit?: () => void;
  enablePassword?: () => void;
  user: UserData;
  isLoading: false;
};

const UserDetails: React.FunctionComponent<UserDetailsProps> = (props) => {
  const {
    enableEdit = () => null,
    enablePassword = () => null,
    user,
    isLoading = false,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const { details, isMemLoading } = useSelector(
    //@ts-ignore
    (state) => state.people,
  );

  useEffect(() => {
    getMemberById(userAccess().empId, true, dispatch);
  }, []);

  const obj = {
    userInfo: {
      Name: isMemLoading ? (
        <Skeleton height={25} />
      ) : (
        `${details.firstName || "NA"} ${details.lastName || "NA"}`
      ),
      Address: isMemLoading ? (
        <Skeleton height={25} />
      ) : (
        details.permanent_Address || "NA"
      ),
      "Personal number": isMemLoading ? (
        <Skeleton height={25} />
      ) : (
        details.personalMobileNumber || "NA"
      ),
      "Personal email Id": isMemLoading ? (
        <Skeleton height={25} />
      ) : (
        details.personalEmailID || "NA"
      ),
      LinkedIn: isMemLoading ? (
        <Skeleton height={25} />
      ) : (
        details.linkedin || "NA"
      ),
    },
  };

  const workInfo = {
    name: isMemLoading ? <Skeleton height={25} /> : details.jobTitle || "NA",
    experience: isMemLoading ? (
      <Skeleton height={25} />
    ) : (
      details.experience || "NA"
    ),
    employment: isMemLoading ? (
      <Skeleton height={25} />
    ) : (
      details.employeeType || "NA"
    ),
    joining: isMemLoading ? (
      <Skeleton height={25} />
    ) : (
      details.joiningDate || "NA"
    ),
    salary: isMemLoading ? <Skeleton height={25} /> : details.salaryPA || "NA",
    email: isMemLoading ? (
      <Skeleton height={25} />
    ) : (
      details.personalEmailID || "NA"
    ),
  };

  return (
    <Box className={classes.display_card}>
      <Grid container spacing={2} ml={3}>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isMemLoading ? (
            <Skeleton variant="circular" height={150} width={150} />
          ) : (
            <Avatar
              variant="rounded"
              alt={details.profilePhotoFileName}
              src={imageReturnFromZoho(details.profilePhotoURL)}
              sx={{ height: "150px", width: "150px" }}
            />
          )}
          <Typography>Profile</Typography>
        </Grid>
        <Grid item md={2} xs={2}>
          {Object.entries(obj.userInfo).map((heading) => (
            <Typography fontWeight={700} mb={3}>
              {heading[0]}
            </Typography>
          ))}
        </Grid>
        <Grid item md={5} xs={5}>
          {Object.entries(obj.userInfo).map((value) => (
            <Typography mb={3}>{value[1]}</Typography>
          ))}
        </Grid>
      </Grid>

      <Grid container spacing={2} ml={3} mt={2}>
        <Grid item>
          <Typography variant="h6">Emergency contact details</Typography>
        </Grid>
        {details?.emergencyContactDetails?.map((contacts) => {
          return (
            <Grid item container>
              <Grid item sm={2}>
                <Typography fontWeight={700}>Contact name</Typography>
              </Grid>
              <Grid item sm={2}>
                <Typography>
                  {isMemLoading ? (
                    <Skeleton height={25} />
                  ) : (
                    contacts.emergency_Contact_Name || "NA"
                  )}
                </Typography>
              </Grid>
              <Grid item sm={2} fontWeight={700}>
                <Typography fontWeight={700}>Contact number</Typography>
              </Grid>
              <Grid item sm={2}>
                <Typography>
                  {isMemLoading ? (
                    <Skeleton height={25} />
                  ) : (
                    contacts.contact_Number || "NA"
                  )}
                </Typography>
              </Grid>
              <Grid item sm={2}>
                <Typography fontWeight={700}>Relation</Typography>
              </Grid>
              <Grid item sm={2}>
                <Typography>
                  {isMemLoading ? (
                    <Skeleton height={25} />
                  ) : (
                    contacts.relationship || "NA"
                  )}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>

      <Grid container spacing={2} ml={3}>
        <Grid item>
          <Typography variant="h6" mt={5}>
            Work information
          </Typography>
        </Grid>
        <Grid container item>
          <Grid item sm={2}>
            <Typography fontWeight={700}>Job title</Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography>
              {isMemLoading ? <Skeleton height={25} /> : workInfo.name}
            </Typography>
          </Grid>

          <Grid item sm={2}>
            <Typography fontWeight={700}>Experience level</Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography>
              {isMemLoading ? <Skeleton height={25} /> : workInfo.experience}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item>
          <Grid item sm={2}>
            <Typography fontWeight={700}>Employment type</Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography>
              {isMemLoading ? <Skeleton height={25} /> : workInfo.employment}
            </Typography>
          </Grid>

          <Grid item sm={2}>
            <Typography fontWeight={700}>Joining date</Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography>
              {isMemLoading ? <Skeleton height={25} /> : workInfo.joining}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item>
          <Grid item sm={2}>
            <Typography fontWeight={700}>Salary</Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography>
              {isMemLoading ? <Skeleton height={25} /> : workInfo.salary}
            </Typography>
          </Grid>

          <Grid item sm={2}>
            <Typography fontWeight={700}>Work email id</Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography>
              {isMemLoading ? <Skeleton height={25} /> : workInfo.email}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid sm={2}>
            <Typography fontWeight={700}>Skills</Typography>
          </Grid>
          <Grid sm={8}>
            <Typography>
              {isMemLoading ? (
                <Skeleton height={25} />
              ) : (
                details.skillSet || "NA"
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} ml={3} mt={2}>
        <Grid item>
          <Typography variant="h6">Emergency history</Typography>
        </Grid>
        {details?.workExperiences?.map((contacts) => {
          return (
            <>
              <Grid container item>
                <Grid item sm={2}>
                  <Typography fontWeight={700}>Job title</Typography>
                </Grid>
                <Grid item sm={2}>
                  <Typography>
                    {isMemLoading ? (
                      <Skeleton height={25} />
                    ) : (
                      contacts.jobtitle || "NA"
                    )}
                  </Typography>
                </Grid>
                <Grid item sm={2}>
                  <Typography fontWeight={700}>Company</Typography>
                </Grid>
                <Grid item sm={2}>
                  <Typography>
                    {isMemLoading ? (
                      <Skeleton height={25} />
                    ) : (
                      contacts.employer || "NA"
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item>
                <Grid item sm={2}>
                  <Typography fontWeight={700}>Joining date</Typography>
                </Grid>
                <Grid item sm={2}>
                  <Typography>
                    {isMemLoading ? (
                      <Skeleton height={25} />
                    ) : (
                      contacts.fromDate || "NA"
                    )}
                  </Typography>
                </Grid>
                <Grid item sm={2}>
                  <Typography fontWeight={700}>End date</Typography>
                </Grid>
                <Grid item sm={2}>
                  <Typography>
                    {isMemLoading ? (
                      <Skeleton height={25} />
                    ) : (
                      contacts.todate || "NA"
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </>
          );
        })}
      </Grid>

      <Grid container justifyContent={"end"}>
        <Grid item sm={3}>
          <Button onClick={enablePassword} variant="text" sx={{ mt: 2 }}>
            {STRINGS.ChangePassword}
          </Button>
          <Button onClick={enableEdit} variant="text" sx={{ mt: 2 }}>
            {STRINGS.Edit}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserDetails;
