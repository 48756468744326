import * as Yup from "yup";

export const registrationSchema = Yup.object({
  FirstName: Yup.string().min(2).max(25).required("Please enter your name"),
  LastName: Yup.string().min(2).max(25).required("Please enter your last name"),
  Email: Yup.string().email().required("Please enter your email"),
  CompanyName: Yup.string()
    .min(2)
    .max(25)
    .required("Please enter your company name"),
  role: Yup.string().min(2).required("Please enter your role"),
});
