import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import moment from "moment";
import { useSelector } from "react-redux";
import FormikInput from "../../../../shared/components/FormikInput";
import SkeletonLoader from "../../common/loader/SkeletonLoader";

const AnnualFeedbackDetailsFilledForm = ({
  handleRedirectBack,
  employeeDetails,
}) => {
  const { isLoading, annualFeedbackDetails } = useSelector(
    //@ts-ignore
    (state) => state.performance.employee,
  );

  return isLoading ? (
    <SkeletonLoader />
  ) : (
    <Box
      sx={{
        padding: "0px 15px",
      }}
    >
      <Formik initialValues={{}} onSubmit={() => {}}>
        {(formikProps) => {
          return (
            <Form>
              <Box className="annaul-feeback-no-filled-form-header">
                <Typography variant="h5">
                  Annual feedback to {employeeDetails.employeeName}
                </Typography>
                <Typography variant="h6" fontWeight={500} fontSize={"14px"}>
                  Feedback Date :{" "}
                  {moment(employeeDetails.date).format("DD-MM-YYYY")}
                </Typography>
              </Box>
              <Box className="annual-feedback-details-filled-form">
                {annualFeedbackDetails.performanceAnualFeedbackQuestionsCommentsReq?.map(
                  (item, index) => (
                    <Box>
                      {item.salaryIncreaseNomination == 0 ? (
                        <>
                          {item.questionScore === null ? (
                            <Typography
                              variant="h6"
                              style={{ marginBottom: "10px" }}
                            >
                              {`Q${index + 1}. ` + item.questionText}
                            </Typography>
                          ) : (
                            <FormikInput
                              label={`Q${index + 1}. ` + item.questionText}
                              name={`1${index + 1}Rating`}
                              formikProps={formikProps}
                              rating={item.questionScore}
                              className="annual-feedback-rating-form"
                              options={undefined}
                              inputType="custom-rating"
                              precision={0.5}
                              disabled
                            />
                          )}

                          <Typography
                            variant="body1"
                            className="comment-box-custom-form-control"
                          >
                            {item.questionComment}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography
                            variant="h6"
                            style={{ marginBottom: "10px" }}
                          >
                            {`Q${index + 1}. ` + item.questionText}
                          </Typography>

                          {item.salaryIncreaseNomination == 1 &&
                            item.salaryIncreaseNominationType == 1 && (
                              <p>
                                Salary increase from {item.month}, amount $
                                {item.amount}
                              </p>
                            )}

                          {item.salaryIncreaseNomination == 1 &&
                            item.salaryIncreaseNominationType == 2 && (
                              <p>
                                Salary increase annually, amount ${item.amount}
                              </p>
                            )}

                          {item.salaryIncreaseNomination == 2 &&
                            item.salaryIncreaseNominationType == 1 && (
                              <p>
                                Salary increase from {item.month},{" "}
                                {item.percentage}%
                              </p>
                            )}

                          {item.salaryIncreaseNomination == 2 &&
                            item.salaryIncreaseNominationType == 2 && (
                              <p>
                                Salary increase annually, {item.percentage}%
                              </p>
                            )}

                          {item.salaryIncreaseNomination == 3 && (
                            <>
                              <p>No raise</p>
                              <p>{item.questionComment}</p>
                            </>
                          )}
                        </>
                      )}
                    </Box>
                  ),
                )}
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AnnualFeedbackDetailsFilledForm;
