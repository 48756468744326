import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { CircularProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { City, Country, State } from "country-state-city";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { STRINGS } from "../../../Strings";
import { companyUpdatedDone } from "../../../redux/Company/companySlice";
import { addClientSchema } from "../../../schemas/add-client";
import { updateCompanyDetails } from "../../Client/ProfileLanding/Profile.helper";
import {
  getBase64File,
  imageUpload,
} from "../../Client/UserDetails/User.helper";
import { clientStyles } from "./styles.js";

let initialValues = {
  Id: 0,
  Name: "",
  WebSite: "",
  ACN: "",
  Description: "",
  Address: "",
  CountryCode: "",
  StateCode: "",
  PinCode: "",
  City: "",
  LogoImage: "",
  IsActive: "false",
  logoImageFile: "",
  logoImageFileName: "",
  isLogoUpdated: false,
  ShortCode: "",
};

type EditCompanyDetailsProps = {
  pressNext: () => void;
};

const FillCompanyDetails: React.FunctionComponent<EditCompanyDetailsProps> = (
  props,
) => {
  const alert = useAlert();
  const classes = clientStyles();
  const dispatch = useDispatch();
  let [formValues, setFormValues] = useState(null);
  const [fileName, setFileName] = React.useState<any>("");
  const [uploadfile, setUploadfile] = React.useState<any>("");
  const [selectFile, setFile] = React.useState<any>("");
  const [isPicUpdated, setIsPicUpdated] = React.useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = React.useState("");
  const [selectedState, setSelectedState] = React.useState("");
  const [selectedCity, setSelectedCity] = React.useState("");
  const [formAction, setFormAction] = React.useState<any>();

  const [availableCountries, setAvailableCountries] = React.useState<any>([]);
  const [availableState, setAvailableState] = React.useState<any>([]);
  const [availableCities, setAvailableCities] = React.useState<any>([]);

  // const availableState = data.countries.find((c) => c.id === selectedCountry);
  // const availableCities = availableState?.states?.find(
  //   (s) => s.id === selectedState
  // );

  const { isLoading, company, updateLoading, isCompanyUpdated } = useSelector(
    //@ts-ignore
    (state) => state.company,
  );
  useEffect(() => {
    loadCountries();
  }, []);

  const loadCountries = () => {
    setAvailableCountries(Country.getAllCountries());
    setAvailableState(null);
    setAvailableCities(null);
  };

  const loadStates = async (contry) => {
    let states = await State.getStatesOfCountry(contry);
    setAvailableState(states);
    setAvailableCities(null);
  };

  const loadCities = async (state) => {
    let cities = await City.getCitiesOfState(selectedCountry, state);
    setAvailableCities(cities);
  };

  useEffect(() => {
    if (isCompanyUpdated && !_.isEmpty(formAction)) {
      alert.success("Company updated successfully.");
      formAction.resetForm();
      dispatch(companyUpdatedDone());
      props.pressNext();
    }
  }, [isCompanyUpdated]);

  useEffect(() => {
    if (!_.isNull(company)) {
      initialValues = {
        Id: company.id,
        Name: company.name,
        WebSite: company.webSite,
        ACN: company.acn,
        Description: company.description,
        Address: company.address,
        CountryCode: company.countryCode,
        StateCode: company.stateCode,
        PinCode: company.pinCode,
        City: company.city,
        LogoImage: company.logoImage,
        IsActive: company.isActive,
        isLogoUpdated: false,
        logoImageFileName: company.logoImageFileName,
        logoImageFile: company.logoImageFileURL,
        ShortCode: company.shortCode,
      };
      setFieldValue("Id", company.id);
      setFieldValue("CountryCode", company.countryCode);
      setFieldValue("StateCode", company.stateCode);
      setFieldValue("City", company.city);
      setSelectedCountry(company.countryCode);
      setSelectedState(company.stateCode);
      setSelectedCity(company.city);
      loadStates(company.countryCode);
      loadCities(company.stateCode);
      //@ts-ignore
      setFormValues(initialValues);
    }
  }, [company]);

  const {
    values,
    errors,
    dirty,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: formValues || initialValues,
    validationSchema: addClientSchema,
    enableReinitialize: true,
    onSubmit: (values, action) => {
      if (selectedCountry !== "") {
        values.CountryCode = selectedCountry;
      }
      if (selectedState !== "") {
        values.StateCode = selectedState;
      }
      if (selectedCity !== "") {
        values.City = selectedCity;
      }
      values.isLogoUpdated = isPicUpdated;
      values.logoImageFileName = fileName;
      values.logoImageFile = uploadfile;
      updateCompanyDetails(values, dispatch);
      setFormAction(action);
    },
  });

  return (
    <Grid
      container
      component="main"
      sx={{ background: "#ffffff", pt: 2, pb: 2, m: 2 }}
    >
      <Container maxWidth="xl">
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={12} md={12}>
              <Box display="flex" justifyContent="flex-start">
                <Box className={classes.companyLogo}>
                  {fileName ? (
                    <img
                      className={classes.user_pic}
                      src={getBase64File(fileName, uploadfile)}
                      alt={fileName}
                    />
                  ) : (
                    <Typography
                      variant="h6"
                      className={classes.companyLogoText}
                    >
                      {STRINGS.Logo}
                    </Typography>
                  )}
                </Box>
                <Grid ml={2}>
                  <Typography variant="h6">{"Company logo"}</Typography>
                  <Typography variant="caption">
                    ({STRINGS.ImageSize})
                  </Typography>
                  <Box
                    display={"flex"}
                    sx={{ mt: 2 }}
                    className="upload-btn-wrapper"
                  >
                    <button className="profile_btn">
                      <FileUploadOutlinedIcon
                        sx={{
                          mr: 1,
                          width: 18,
                          height: 18,
                        }}
                      ></FileUploadOutlinedIcon>
                      <Typography variant="caption">
                        {STRINGS.Upload}
                      </Typography>
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      onChange={(e) =>
                        imageUpload(
                          e,
                          setFileName,
                          setFile,
                          alert,
                          setIsPicUpdated,
                          setUploadfile,
                        )
                      }
                    />
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item sm={6} md={6}>
              <TextField
                error={Boolean(touched.Name && errors.Name)}
                helperText={touched.Name && errors.Name}
                margin="normal"
                fullWidth
                id="Name"
                label={STRINGS.CompanyName}
                name="Name"
                variant="outlined"
                autoComplete="off"
                inputProps={{ maxLength: 25 }}
                value={values.Name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item sm={6} md={6}>
              <TextField
                error={Boolean(touched.Address && errors.Address)}
                helperText={touched.Address && errors.Address}
                margin="normal"
                fullWidth
                id="Address"
                label={STRINGS.CompanyAddress}
                name="Address"
                variant="outlined"
                autoComplete="off"
                inputProps={{ maxLength: 50 }}
                value={values.Address}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel>{STRINGS.Country}</InputLabel>
                <Select
                  id="CountryCode"
                  name="CountryCode"
                  label={STRINGS.Country}
                  value={selectedCountry}
                  onChange={(e) => {
                    setSelectedCountry(e.target.value);
                    loadStates(e.target.value);
                  }}
                >
                  {availableCountries?.map((value, key) => {
                    return (
                      <MenuItem value={value.isoCode} key={key}>
                        {value.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel>{STRINGS.State}</InputLabel>
                <Select
                  id="StateCode"
                  name="StateCode"
                  label={STRINGS.State}
                  value={selectedState}
                  onChange={(e) => {
                    setSelectedState(e.target.value);
                    loadCities(e.target.value);
                  }}
                >
                  {availableState?.map((e, key) => {
                    return (
                      <MenuItem value={e.isoCode} key={key}>
                        {e.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
            <Grid item sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel>{STRINGS.City}</InputLabel>
                <Select
                  id="City"
                  name="City"
                  label={STRINGS.City}
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                >
                  {availableCities?.map((e, key) => {
                    return (
                      <MenuItem value={e.name} key={key}>
                        {e.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={6} md={6}>
              <TextField
                error={Boolean(touched.PinCode && errors.PinCode)}
                helperText={touched.PinCode && errors.PinCode}
                fullWidth
                id="PinCode"
                label={STRINGS.PostalCode}
                name="PinCode"
                variant="outlined"
                autoComplete="off"
                inputProps={{ maxLength: 8 }}
                value={values.PinCode}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item sm={6} md={6}>
              <TextField
                error={Boolean(touched.WebSite && errors.WebSite)}
                helperText={touched.WebSite && errors.WebSite}
                margin="normal"
                fullWidth
                id="WebSite"
                label={STRINGS.CompanyWebsite}
                name="WebSite"
                variant="outlined"
                autoComplete="off"
                inputProps={{ maxLength: 35 }}
                value={values.WebSite}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                error={Boolean(touched.ShortCode && errors.ShortCode)}
                helperText={touched.ShortCode && errors.ShortCode}
                fullWidth
                id="ShortCode"
                label={STRINGS.ClientCode}
                name="ShortCode"
                variant="outlined"
                autoComplete="off"
                inputProps={{ maxLength: 9 }}
                value={values.ShortCode}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                error={Boolean(touched.ACN && errors.ACN)}
                helperText={touched.ACN && errors.ACN}
                fullWidth
                id="ACN"
                label={STRINGS.ACN}
                name="ACN"
                variant="outlined"
                autoComplete="off"
                inputProps={{ maxLength: 9 }}
                value={values.ACN}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>
          <TextField
            multiline
            rows={3}
            error={Boolean(touched.Description && errors.Description)}
            helperText={touched.Description && errors.Description}
            margin="normal"
            fullWidth
            id="Description"
            label={STRINGS.AboutCompany}
            name="Description"
            variant="outlined"
            autoComplete="off"
            inputProps={{ maxLength: 100 }}
            value={values.Description}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Box display="flex" justifyContent="flex-end">
            <Button type="submit" variant="contained" sx={{ mt: 2 }}>
              {updateLoading && <CircularProgress size={18} color="warning" />}
              {!updateLoading && STRINGS.Next}
            </Button>
          </Box>
        </Box>
      </Container>
    </Grid>
  );
};

export default FillCompanyDetails;
