import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isAuth: false,
  error: "",
  accessToken: {},
  refreshToken: {},
  authToken: "", // this is the token that is used to call the refresh token API
  userInfo: {},
  forgotPassPending: false,
  forgotPassSuccess: [],
  forgotPassError: "",
  resetPassPending: false,
  resetPassSuccess: [],
  resetPassError: "",
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginPending: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.isAuth = true;
      state.error = "";
      state.accessToken = payload?.accessTokens?.token;
      state.refreshToken = payload?.accessTokens?.refreshToken;
      state.userInfo = payload?.userInfo;
    },
    loginFail: (state, { payload }) => {
      state.isAuth = false;
      state.isLoading = false;
      state.error = payload;
      state.authToken = "";
    },
    logOut: (state) => {
      state.isAuth = false;
      state.accessToken = {};
      state.refreshToken = {};
      state.userInfo = {};
      state.authToken = "";
      state.error = "";
    },
    setRefreshToken: (state, { payload }) => {
      state.isAuth = true;
      state.accessToken = payload?.token;
      state.refreshToken = payload?.refreshToken;
    },
    clearError: (state) => {
      state.isLoading = false;
      state.error = "";
    },
    forgotPasswordRequestPending: (state) => {
      state.forgotPassPending = true;
      state.forgotPassError = "";
    },
    forgotPasswordRequestSuccess: (state, { payload }) => {
      state.forgotPassPending = false;
      state.forgotPassSuccess = payload;
    },
    forgotPasswordRequestFail: (state, { payload }) => {
      state.forgotPassPending = false;
      state.forgotPassError = payload;
    },
    resetPasswordRequestPending: (state) => {
      state.resetPassPending = true;
      state.resetPassError = "";
    },
    resetPasswordRequestSuccess: (state, { payload }) => {
      state.resetPassPending = false;
      state.resetPassSuccess = payload;
    },
    resetPasswordRequestFail: (state, { payload }) => {
      state.resetPassPending = false;
      state.resetPassError = payload;
    },
    setAuthToken: (state, { payload }) => {
      state.authToken = payload;
    },
  },
});

const { reducer, actions } = loginSlice;

export const {
  forgotPasswordRequestSuccess,
  resetPasswordRequestSuccess,
  resetPasswordRequestFail,
  resetPasswordRequestPending,
  forgotPasswordRequestPending,
  forgotPasswordRequestFail,
  loginPending,
  loginSuccess,
  loginFail,
  logOut,
  setRefreshToken,
  clearError,
  setAuthToken,
} = actions;

export default reducer;
