export const OPEN_MENU = [
  {
    id: "clone",
    name: "Clone Request",
  },
];

export const CLOSED_MENU = [
  {
    id: "clone",
    name: "Clone Request",
  },
];

export const TASK_MENU = [
  {
    id: "view",
    name: "View",
  },
  {
    id: "addComment",
    name: "Add Comment",
  },
  {
    id: "delete",
    name: "Delete",
  },
];

export const CONDITIONAL_TASK_MENU = [
  {
    id: "view",
    name: "View",
  },
  {
    id: "addComment",
    name: "Add Comment",
  },
];
