import {
  Box,
  Modal,
  useMediaQuery,
  Grid,
  Typography,
  TextField,
  FormHelperText,
} from "@mui/material";
import Button from "@mui/material/Button";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePicker } from "@mui/x-date-pickers";
import * as yup from "yup";

import { STRINGS } from "../../../Strings";
import SelectBox from "../../../shared/components/SelectBox/SelectBox";
import {
  addTimeLogs,
  getProjectList,
  getTaskList,
} from "../TeamMemberTimesheet.helper";
import { userAccess } from "../../../utils/CommonFunction";
import { addTimeLogClearMessage } from "../../../redux/Timesheet/timesheetSlice";

const LogTimeModal = ({
  open,
  setOpenLogTime,
  logTimeDate,
  setLogTimeDate,
}: any) => {
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const dispatch = useDispatch();
  const { projectNameList, taskList, addTimeLogMessage } = useSelector(
    //@ts-ignore
    (state) => state.timesheet,
  );
  const { email } = userAccess();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "scroll",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.2)",
    padding: "16px",
    outline: "none",
    width: isMobile ? "300px" : "600px",
    maxWidth: "90vw",
    maxHeight: "90vh",
    "&.MuiBox-root": {
      border: "none",
      "&:focus-visible": {
        outlineColor: "white",
      },
    },
  };

  const formik = useFormik({
    initialValues: {
      projectId: "",
      workDate: logTimeDate,
      empEmailId: email,
      jobId: "",
      hours: "",
      desc: "",
    },
    validationSchema: yup.object().shape({
      projectId: yup.string().required("Project Name is required"),
      jobId: yup.string().required("Task Name is required"),
      workDate: yup.date().required("Date is required"),
      hours: yup.string().required("Hours are required").nullable(),
    }),
    onSubmit: (values) => {
      let payload = {
        workDate: dayjs(values.workDate).format("YYYY-MM-DD"),
        hours: dayjs(values.hours).format("HH:mm"),
        empEmailId: values.empEmailId,
        jobId: values.jobId,
        desc: values.desc,
      };
      addTimeLogs(dispatch, payload);
    },
  });

  useEffect(() => {
    if (addTimeLogMessage.includes("successfully")) {
      setOpenLogTime(!open);
      formik.resetForm();
    }
  }, [addTimeLogMessage]);

  const handleClose = () => {
    setOpenLogTime(!open);
    setLogTimeDate(dayjs());
    formik.resetForm();
  };

  const shouldDisableDate = (date: Date) => {
    const dayOfWeek = dayjs(date).day();
    return dayOfWeek === 0 || dayOfWeek === 6;
  };

  useEffect(() => {
    getProjectList(dispatch, { empEmailId: email });
  }, []);

  useEffect(() => {
    if (formik.values.projectId) {
      getTaskList(dispatch, {
        projectId: formik.values.projectId,
        empEmailId: email,
      });
    }
  }, [formik.values.projectId]);

  useEffect(() => {
    dispatch(addTimeLogClearMessage());
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            textAlign="center"
          >
            Log Time
          </Typography>
        </Grid>
        <Grid container spacing={2} sx={{ flexGrow: 1, mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <SelectBox
              label="Project Name"
              name="projectId"
              value={formik.values.projectId}
              onChange={formik.handleChange}
              options={projectNameList.map((project) => ({
                id: project.id,
                value: project.id,
                label: project.name,
              }))}
              error={formik.errors.projectId}
              touched={formik.touched.projectId}
              ariaLabel="Project Name"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <SelectBox
              label="Task Name"
              name="jobId"
              value={formik.values.jobId}
              onChange={formik.handleChange}
              options={taskList.map((task) => ({
                id: task.id,
                value: task.id,
                label: task.name,
              }))}
              error={formik.errors.jobId}
              touched={formik.touched.jobId}
              ariaLabel="Task Name"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date"
                inputFormat="DD-MM-YYYY"
                value={formik.values.workDate}
                onChange={(date) => {
                  formik.setFieldValue("workDate", date);
                }}
                shouldDisableDate={shouldDisableDate}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                ampm={false}
                openTo="hours"
                views={["hours", "minutes"]}
                inputFormat="HH:mm"
                mask="__:__"
                value={formik.values.hours}
                onChange={(newTime) => formik.setFieldValue("hours", newTime)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    required
                    error={Boolean(formik.touched.hours && formik.errors.hours)}
                    helperText={formik.touched.hours && formik.errors.hours}
                    name="hours"
                    onBlur={formik.handleBlur}
                    sx={{
                      "& .MuiInputAdornment-root": {
                        display: "none",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} mt={3}>
          <TextField
            id="desc"
            name="desc"
            label="Description"
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            value={formik.values.desc}
            onChange={formik.handleChange}
          />
        </Grid>
        {addTimeLogMessage && (
          <Box ml={1}>
            <FormHelperText sx={{ color: "red" }}>
              {addTimeLogMessage}
            </FormHelperText>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "Right " }}>
          <Button variant="text" sx={{ mt: 2 }} onClick={handleClose}>
            {STRINGS.Cancel}
          </Button>
          <Button
            variant="text"
            sx={{ mt: 2 }}
            onClick={() => formik.handleSubmit()}
          >
            {STRINGS.Save}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LogTimeModal;
