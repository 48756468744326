import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { usePermissions } from "../../../utils/permission";

function performanceTabAriaProps(index: number) {
  return {
    id: `performance-tab-${index}`,
    "aria-controls": `performance-tabpanel-${index}`,
  };
}
const CandiateDetailsTab = ({ handleTabChange, tabValue }) => {
  const { Hire_SeeCandidateDocument, Hire_ListInterviewQuestions } =
    usePermissions();
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          background: "#fff",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          marginTop: "12px",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="fullWidth"
          aria-label="basic tabs example"
        >
          <Tab label="Candidate information" {...performanceTabAriaProps(0)} />
          {Hire_ListInterviewQuestions && (
            <Tab label="Interview details" {...performanceTabAriaProps(1)} />
          )}
          {Hire_SeeCandidateDocument && (
            <Tab label="Documents" {...performanceTabAriaProps(2)} />
          )}
        </Tabs>
      </Box>
    </Box>
  );
};

export default CandiateDetailsTab;
