import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  holidayCard: {
    "&.MuiPaper-root": {
      boxSizing: "border-box",
      height: 92,
      backgroundColor: "#FFFAEA",
      cursor: "pointer",
      padding: 12,
      ["@media (max-width:800px)"]: {
        marginLeft: 0,
        padding: 8,
      },
    },
  },
  holidayCardContent: {
    "&.MuiCardContent-root": {
      padding: 0,

      "& .MuiGrid-item:last-child": {
        textAlign: "right",
      },
    },
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 512,
    backgroundColor: "white",
    borderRadius: 16,
    outline: "none",
    padding: 24,
    ["@media (max-width:800px)"]: {
      width: "calc(100% - 48px)",
      padding: "16px 10px",
    },
  },
  tabsContainer: {
    borderBottom: "1px solid #707D84",
  },
  tab: {
    flexGrow: 1,
    flexBasis: 0,
    maxWidth: "none",
    width: "auto",
    fontSize: 16,
    ["@media (max-width:800px)"]: {
      fontSize: 12,
    },
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  tableContainer: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    height: "100%",

    "& .MuiTableRow-head .MuiTableCell-root": {
      textTransform: "none",
      fontSize: 12,
      padding: 10,
    },
    "& .MuiTableRow-root .MuiTableCell-root": {
      textTransform: "none",
      fontSize: 12,
      borderBottom: "1px solid #8A969A",
      padding: 10,
    },
    "& .MuiTableRow-root .MuiTableCell-root:first-child": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100px",
    },
  },
  tableHeader: {
    backgroundColor: "#D8DCF0",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  tabContentContainer: {
    height: "100%",
  },
  tabContent: {
    height: "calc(100% - 148px)",
  },
  closeIcon: {
    fontSize: 30,
    color: "black",
    ["@media (max-width:800px)"]: {
      fontSize: 24,
    },
  },
  customTypography: {
    fontWeight: 500,
    ["@media (max-width:800px)"]: {
      fontSize: "24px !important",
    },
  },
}));

export { useStyles };
