import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Search from "../../shared/components/Search/Search";
import { usePermissions } from "../../utils/permission";

const HireHeader = ({ requestTypes, setSelectedRequestType, onSearch }) => {
  const navigate = useNavigate();
  const handleNewRequestClick = () => {
    navigate("/hire/new");
  };

  const { Hire_AddJobRequest } = usePermissions();

  const onRequestTypeChange = (event) => {
    setSelectedRequestType(event.target.value);
  };

  return (
    <Box className="header-container">
      <Box className="filter-container">
        <Search
          placeHolder="Search by name, client or job title"
          getSearch={onSearch}
        />

        <FormControl>
          <InputLabel>Request type</InputLabel>
          <Select
            className="filter-dropdown"
            label="Request type"
            defaultValue="Open"
            onChange={onRequestTypeChange}
          >
            {requestTypes.map((item) => {
              return (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>

      {Hire_AddJobRequest ? (
        <Button
          variant="contained"
          className="header-button"
          onClick={handleNewRequestClick}
        >
          New Request
        </Button>
      ) : null}
    </Box>
  );
};

export default HireHeader;
