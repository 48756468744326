import {
  Button,
  Container,
  Grid,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { teamMemberLeaveShortInfoFail } from "../../redux/Approval/approvalSlice";
import DataGrid from "../../shared/components/DataGrid/DataGrid";
import DetailCard from "../../shared/components/DetailCard/DetailCard";
import MobileSkeleton from "../../shared/components/Skeletons/MobileSkeleton";
import TableSkeleton from "../../shared/components/Skeletons/TableSkeleton";
import { TabPanel } from "../../shared/components/TabPanel/TabPanel";
import { STRINGS } from "../../Strings";
import {
  getApporovalDetails,
  getTeamMemberLeaveDetails,
} from "../Approvals/Approvals.helper";
import ApplyLeaveModal from "./ApplyLeaveModal";
import { useStyles } from "./styles";
import {
  getTeamMemberLeaveShortInfo,
  LeaveColoumn,
  leaveHistoryGridRow,
  UpcomingLeaveColoumn,
  upcomingLeaveGridRow,
} from "./TeamMemberApprovals.helper";

const TeamMemberApprovals: React.FunctionComponent<any> = (props) => {
  const [tabValue, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [dateSpecificDataId, setDateSpecificDataId] = useState(null);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const classes = useStyles();

  const { userInfo } = useSelector(
    //@ts-ignore
    (state) => state.login,
  );
  const {
    leavesSummary,
    leaveInfoList,
    isLeaveInfoLoading,
    teamMemberLeaveShortInfo,
    isTeamMemberLeaveShortInfoLoading,
  } = useSelector(
    //@ts-ignore
    (state) => state.approval,
  );
  const skeleton = isMobile ? <MobileSkeleton /> : <TableSkeleton />;

  useEffect(
    useCallback(() => {
      let request = {
        email: userInfo.email,
        zohoId: userInfo.zohoId,
      };
      getApporovalDetails(dispatch, { ...request });
      getTeamMemberLeaveDetails(dispatch, {
        ...request,
        isForPast: false,
      });
    }, []),
    [],
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    getTeamMemberLeaveDetails(dispatch, {
      email: userInfo.email,
      zohoId: userInfo.zohoId,
      isForPast: newValue ? true : false,
    });
  };

  const handleSpecificDateInfo = (data) => {
    getTeamMemberLeaveShortInfo(dispatch, data.id);
    setDateSpecificDataId(data?.id);
    setOpen(!open);
  };

  const handleOpenClose = () => {
    //@ts-ignore
    dispatch(teamMemberLeaveShortInfoFail());
    setDateSpecificDataId(null);
    setOpen(!open);
  };

  return (
    <>
      <Container maxWidth="xl">
        <Grid
          container
          mt={2}
          mb={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item width={"100%"} display="flex" justifyContent={"end"}>
            <Button variant="contained" onClick={() => setOpen(!open)}>
              {STRINGS.ApplyLeave}
            </Button>
          </Grid>
        </Grid>
        <Tabs
          value={tabValue}
          variant="fullWidth"
          onChange={handleChange}
          sx={{ mb: 1, backgroundColor: "#FFFFFF" }}
        >
          <Tab label="Upcoming leaves" className={classes.tabFonts} />
          <Tab label="Leave History" className={classes.tabFonts} />
        </Tabs>
      </Container>
      <TabPanel value={tabValue} index={0}>
        {isLeaveInfoLoading ? (
          skeleton
        ) : (
          <Grid
            sx={{ display: !isMobile ? "flex" : null, gap: 2, pl: 1, pr: 1 }}
          >
            <Grid
              xs={isMobile ? 12 : 6}
              container
              justifyContent={"center"}
              height={leaveInfoList.length == 0 ? "150px" : "auto"}
              mt={-2}
            >
              <DataGrid
                header={UpcomingLeaveColoumn}
                row={upcomingLeaveGridRow(leaveInfoList)}
                cellClick={handleSpecificDateInfo}
              />
            </Grid>
            <Grid
              xs={isMobile ? 12 : 6}
              container
              spacing={isMobile ? 0 : "0.625rem"}
              rowSpacing={isMobile ? 2 : 0}
              paddingTop={1}
            >
              {leavesSummary?.map((item) => <DetailCard item={item} />)}
            </Grid>
          </Grid>
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {isLeaveInfoLoading ? (
          skeleton
        ) : (
          <Grid
            xs={isMobile ? 12 : 12}
            container
            rowSpacing={isMobile ? 2 : 0}
            justifyContent={"center"}
            pl={1}
            pr={1}
          >
            <DataGrid
              header={LeaveColoumn}
              row={leaveHistoryGridRow(leaveInfoList)}
              cellClick={handleSpecificDateInfo}
            />
          </Grid>
        )}
      </TabPanel>
      <ApplyLeaveModal
        open={open}
        handleOpenClose={handleOpenClose}
        dateSpecificDataId={dateSpecificDataId}
        teamMemberLeaveShortInfo={teamMemberLeaveShortInfo}
        isTeamMemberLeaveShortInfoLoading={isTeamMemberLeaveShortInfoLoading}
      />
    </>
  );
};

export default TeamMemberApprovals;
