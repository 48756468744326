import { all, put, takeLatest } from "redux-saga/effects";

import { Status } from "../../services/ApiStatus";
import {
  getAllEmployeesPerformance,
  getEmployeeFeedbackDetails,
  getEmployeesFeedbackList,
  getEmployeesPerformanceGraphData,
  getFeedbackSubmittedMonths,
  submitBulkFeedback,
  submitFeedback,
  getAllPerformanceAnualQuestions,
  getEmployeeAnnualDetails,
  submitAnnaulFeedback,
} from "../../services/Performance";
import { sagaActions } from "../sagaActions";
import {
  pushEmployeesPerformanceSuccess,
  setAllEmployeesPerformanceFailure,
  setAllEmployeesPerformanceSuccess,
  setIsLoading,
  setIsLoadingMobile,
  setSearchingList,
} from "./slices/allEmployeesSlice";
import {
  setIsLoading as setQuestionLoading,
  setAllQuestions,
} from "./slices/annualQuestionsSlice";
import {
  setEmployeeChartData,
  setEmployeeFeedbackDetails,
  setEmployeePerformanceFailure,
  setIsLoading as setEmployeePerformanceIsLoading,
  setEmployeePerformanceSuccess,
  setFeedbackSubmittedMonths,
  setSubmitFeedbackInfo,
  setEmployeeAnnualFeedbackDetails,
  setSubmitAnnualFeedbackInfo,
} from "./slices/employeeSlice";
import { setSubmitBulkFeedbackInfo } from "./slices/multipleEmployeesSlice";

function* handleGetAllEmployeesPerformance(payload: any = {}) {
  try {
    yield put(setIsLoading(true));
    const response = yield getAllEmployeesPerformance(payload?.payload);

    if (!!response && response?.status === Status.Success) {
      const params = {
        ...response.data,
        pageNumber: payload?.payload.pageNumber,
        pageSize: payload?.payload.pageSize,
      };
      yield put(setAllEmployeesPerformanceSuccess(params));
    } else {
      yield put(
        setAllEmployeesPerformanceFailure(
          response?.message || "Something went wrong!",
        ),
      );
    }
  } catch (error: any) {
    yield put(
      setAllEmployeesPerformanceFailure(
        error?.message || "Something went wrong!",
      ),
    );
  } finally {
    yield put(setIsLoading(false));
  }
}

function* handleGetAllEmployeesPerformanceMobile(payload: any = {}) {
  try {
    yield put(setIsLoadingMobile(true));
    const response = yield getAllEmployeesPerformance(payload?.payload);

    if (!!response && response?.status === Status.Success) {
      const params = {
        ...response.data,
        pageNumber: payload?.payload.pageNumber,
      };
      yield put(pushEmployeesPerformanceSuccess(params));
    } else {
      yield put(
        setAllEmployeesPerformanceFailure(
          response?.message || "Something went wrong!",
        ),
      );
    }
  } catch (error: any) {
    yield put(
      setAllEmployeesPerformanceFailure(
        error?.message || "Something went wrong!",
      ),
    );
  } finally {
    yield put(setIsLoadingMobile(false));
  }
}

function* handleEmployeePerformance(payload: any = {}) {
  try {
    yield put(setEmployeePerformanceIsLoading(true));
    const response = yield getEmployeesFeedbackList(payload?.payload);

    if (!!response && response?.status === Status.Success) {
      yield put(setEmployeePerformanceSuccess(response.data));
    } else {
      yield put(
        setEmployeePerformanceFailure(
          response?.message || "Something went wrong!",
        ),
      );
    }
  } catch (error: any) {
    yield put(
      setEmployeePerformanceFailure(error?.message || "Something went wrong!"),
    );
  }
}

function* handleEmployeePerformanceGraph(payload: any = {}) {
  try {
    const response = yield getEmployeesPerformanceGraphData(payload?.payload);

    if (!!response && response?.status === Status.Success) {
      yield put(setEmployeeChartData(response.data));
    } else {
      yield put(setEmployeeChartData([]));
    }
  } catch (error: any) {
    console.log("error", error);
  }
}

function* handleEmployeeFeedbackDetails(payload: any = {}) {
  try {
    const response = yield getEmployeeFeedbackDetails(payload?.payload);

    if (!!response && response?.status === Status.Success) {
      yield put(setEmployeeFeedbackDetails(response.data));
    }
  } catch (error: any) {
    yield put(setEmployeeFeedbackDetails({}));
  }
}

function* handleAddFeedback(payload: any = {}) {
  try {
    yield put(
      setSubmitFeedbackInfo({ isLoading: true, error: null, submitted: false }),
    );
    const response = yield submitFeedback(payload?.payload);

    if (!!response && response?.status === Status.Success) {
      yield put(
        setSubmitFeedbackInfo({
          isLoading: false,
          error: null,
          submitted: true,
        }),
      );
    } else {
      yield put(
        setSubmitFeedbackInfo({
          isLoading: false,
          error: response?.message || "Something went wrong!",
          submitted: true,
        }),
      );
    }
  } catch (error: any) {
    yield put(
      setSubmitFeedbackInfo({
        isLoading: false,
        error: error?.message || "Something went wrong!",
        submitted: true,
      }),
    );
  }
}

function* handleSearchEmployeeByName(payload: any = {}) {
  try {
    const response = yield getAllEmployeesPerformance(payload?.payload);

    if (!!response && response?.status === Status.Success) {
      yield put(setSearchingList(response.data.employees || []));
    } else {
      yield put(setSearchingList([]));
    }
  } catch (error: any) {
    yield put(setSearchingList([]));
  }
}

function* handleGetSubmittedMonths(payload: any = {}) {
  try {
    const response = yield getFeedbackSubmittedMonths(payload?.payload);

    if (!!response && response?.status === Status.Success) {
      yield put(setFeedbackSubmittedMonths(response.data || []));
    } else {
      yield put(setSearchingList([]));
    }
  } catch (error: any) {
    yield put(setSearchingList([]));
  }
}

function* handleSubmitBulkFeedback(payload: any = {}) {
  try {
    yield put(
      setSubmitBulkFeedbackInfo({
        isLoading: true,
        error: null,
        submitted: false,
      }),
    );
    const response = yield submitBulkFeedback(payload?.payload);

    if (!!response && response?.status === Status.Success) {
      yield put(
        setSubmitBulkFeedbackInfo({
          isLoading: false,
          error: null,
          submitted: true,
        }),
      );
    } else {
      yield put(
        setSubmitBulkFeedbackInfo({
          isLoading: false,
          error: response?.message || "Something went wrong!",
          submitted: false,
        }),
      );
    }
  } catch (error: any) {
    yield put(
      setSubmitBulkFeedbackInfo({
        isLoading: false,
        error: error?.message || "Something went wrong!",
        submitted: false,
      }),
    );
  }
}

function* handleSubmitAnnualFeedback(payload: any = {}) {
  try {
    yield put(
      setSubmitAnnualFeedbackInfo({
        isLoading: true,
        error: null,
        submitted: false,
      }),
    );
    const response = yield submitAnnaulFeedback(payload?.payload);

    if (!!response && response?.status === Status.Success) {
      yield put(
        setSubmitAnnualFeedbackInfo({
          isLoading: false,
          error: null,
          submitted: true,
        }),
      );
    } else {
      yield put(
        setSubmitAnnualFeedbackInfo({
          isLoading: false,
          error: response?.message || "Something went wrong!",
          submitted: false,
        }),
      );
    }
  } catch (error: any) {
    yield put(
      setSubmitAnnualFeedbackInfo({
        isLoading: false,
        error: error?.message || "Something went wrong!",
        submitted: false,
      }),
    );
  }
}

function* handleGetAllPerformanceAnnaulQuestions(payload: any = {}) {
  try {
    yield put(setQuestionLoading(true));
    const response = yield getAllPerformanceAnualQuestions(payload?.payload);

    if (!!response && response?.status === Status.Success) {
      yield put(setAllQuestions(response.data));
      yield put(setQuestionLoading(false));
    } else {
      yield put(setAllQuestions([]));
      yield put(setQuestionLoading(false));
    }
  } catch (error: any) {
    console.log("error", error);
  }
}

function* handleGetEmployeeAnnualFeedbackDetails(payload: any = {}) {
  try {
    yield put(setIsLoading(true));
    const response = yield getEmployeeAnnualDetails(payload?.payload);

    if (!!response && response?.status === Status.Success) {
      yield put(setEmployeeAnnualFeedbackDetails(response.data));
      yield put(setIsLoading(false));
    } else {
      yield put(setEmployeeAnnualFeedbackDetails({}));
      yield put(setIsLoading(false));
    }
  } catch (error: any) {
    console.log("error", error);
  }
}

export default function* watcherSaga() {
  yield all([
    yield takeLatest(
      sagaActions.FETCH_ALL_EMPLOYEES_PERFORMANCE,
      handleGetAllEmployeesPerformance,
    ),
    yield takeLatest(
      sagaActions.FETCH_ALL_EMPLOYEES_PERFORMANCE_MOBILE,
      handleGetAllEmployeesPerformanceMobile,
    ),
    yield takeLatest(
      sagaActions.FETCH_EMPLOYEE_PERFORMANCE,
      handleEmployeePerformance,
    ),
    yield takeLatest(
      sagaActions.FETCH_EMPLOYEE_PERFORMANCE_GRAPH,
      handleEmployeePerformanceGraph,
    ),
    yield takeLatest(
      sagaActions.FETCH_EMPLOYEE_PERFORMANCE_FEEDBACK,
      handleEmployeeFeedbackDetails,
    ),
    yield takeLatest(sagaActions.ADD_FEEDBACK, handleAddFeedback),
    yield takeLatest(
      sagaActions.SEARCH_EMPLOYEE_BY_NAME,
      handleSearchEmployeeByName,
    ),
    yield takeLatest(
      sagaActions.GET_SUBMITTED_MONTHS,
      handleGetSubmittedMonths,
    ),
    yield takeLatest(
      sagaActions.SUBMIT_BULK_FEEDBACK,
      handleSubmitBulkFeedback,
    ),
    yield takeLatest(
      sagaActions.FETCH_ALL_ANNUAL_QUESIONS_PERFORMANCE,
      handleGetAllPerformanceAnnaulQuestions,
    ),
    yield takeLatest(
      sagaActions.FETCH_ANNUAL_FEEDBACK_DETAILS,
      handleGetEmployeeAnnualFeedbackDetails,
    ),
    yield takeLatest(
      sagaActions.SUBMIT_ANNUAL_FEEDBACK,
      handleSubmitAnnualFeedback,
    ),
  ]);
}
