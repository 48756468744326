import { CircularProgress, Collapse, Grid, Typography } from "@mui/material";
import { memo, useEffect } from "react";
import { useAlert } from "react-alert";
import { TransitionGroup } from "react-transition-group";
import { useGetTaskByIdQuery } from "../../redux/Task/TaskApi";
import TaskComment from "./Components/TaskComment";
import TaskCommentsModal from "./TaskCommentsModal";

const TaskCommentsPage = ({ taskId }) => {
  const {
    isLoading,
    data: taskInfo,
    isError,
  } = useGetTaskByIdQuery(
    { taskId },
    {
      skip: !taskId,
    },
  );
  const alert = useAlert();

  useEffect(() => {
    if (isError) {
      alert.error("Error fetching comments");
    }
  }, [alert, isError]);

  return (
    <Grid container item gap={1} py={1}>
      {isLoading && (
        <Grid container item alignItems={"center"} justifyContent={"center"}>
          <CircularProgress />
        </Grid>
      )}
      {!isLoading && taskInfo?.data.comments.length === 0 ? (
        <Grid container item alignItems={"center"} justifyContent={"center"}>
          <Typography variant="body1">No comments added</Typography>
        </Grid>
      ) : null}

      {!isLoading && taskInfo && taskInfo?.data.comments.length > 0 ? (
        <TransitionGroup
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "8px",
          }}
        >
          {taskInfo?.data.comments.map((comment, index) => (
            <Collapse key={`comment-${comment.id}`}>
              <TaskComment
                taskComment={comment}
                last={index === taskInfo.data.comments.length - 1}
              ></TaskComment>
            </Collapse>
          ))}
        </TransitionGroup>
      ) : null}
      <Collapse
        in={!isLoading}
        sx={{
          width: "100%",
        }}
      >
        <TaskCommentsModal taskId={taskId} />
      </Collapse>
    </Grid>
  );
};

export default memo(TaskCommentsPage);
