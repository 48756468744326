import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { STRINGS } from "../../Strings";
import { useAlert } from "react-alert";
import SelectFile from "./SelectFile";
import { applyForJob } from "../../services/Opportunities";
import { userAccess } from "../../utils/CommonFunction";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: "8px",
  boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.2)",
  padding: "16px",
  outline: "none",
  width: "auto",
  maxWidth: "90vw",
  maxHeight: "90vh",
};

const validationSchema = yup.object({
  resume: yup.mixed().required("Resume is required"),
});

const ApplyJobModal = ({ handleOpenClose, open, jobDetails }) => {
  const alert = useAlert();
  const { firstName, lastName } = userAccess();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      resume: null,
      certificate: null,
      reasonForChange: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("username", firstName + lastName);
      //@ts-ignore
      formData.append("resumeFile", values.resume);
      //@ts-ignore
      formData.append("resumeFileName", values.resume?.name || "");
      //@ts-ignore
      formData.append("certification", values.certificate);
      //@ts-ignore
      formData.append("certificationFileName", values.certificate?.name || "");
      formData.append("candidateName", firstName + lastName);
      formData.append("client", jobDetails?.company_Name || "");
      formData.append(
        "expectedJoiningDate",
        jobDetails?.expectedJoiningDate || "",
      );
      formData.append("jobTitle", jobDetails?.job_Title || "");
      formData.append("reasonForChange", values.reasonForChange);
      setLoading(true);
      try {
        const response: any = await applyForJob(formData);
        if (response?.status === 200) {
          setLoading(false);
          handleOpenClose();
          resetForm();
          alert.success("Application submitted successfully.");
        } else {
          const errorMessage =
            response?.data?.errorMessage ||
            "An error occurred. Please try again.";
          alert.error(errorMessage);
        }
      } catch (error) {
        alert.error("An error occurred. Please try again.");
      }
    },
  });

  const handleFileChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      formik.setFieldValue(field, file || null);
    };

  const handleFileClear = (field: string) => () => {
    formik.setFieldValue(field, null);
    const fileInput = document.getElementById(
      `${field}-upload`,
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleOpenClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box component="form" noValidate onSubmit={formik.handleSubmit}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            textAlign="center"
          >
            {STRINGS.ApplyJob}
          </Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <SelectFile
                id="resume-upload"
                label="Upload Resume*"
                handleFileChange={handleFileChange("resume")}
                selectedFile={formik.values.resume}
                handleClear={handleFileClear("resume")}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectFile
                id="certificate-upload"
                label="Upload Certificate"
                handleFileChange={handleFileChange("certificate")}
                selectedFile={formik.values.certificate}
                handleClear={handleFileClear("certificate")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="reasonForChange"
                name="reasonForChange"
                label="Reason for change"
                multiline
                rows={5}
                variant="outlined"
                fullWidth
                value={formik.values.reasonForChange}
                onChange={formik.handleChange}
                error={
                  formik.touched.reasonForChange &&
                  Boolean(formik.errors.reasonForChange)
                }
                helperText={
                  formik.touched.reasonForChange &&
                  formik.errors.reasonForChange
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="text"
                  sx={{ mt: 2 }}
                  onClick={() => {
                    handleOpenClose();
                    formik.resetForm();
                  }}
                >
                  {STRINGS.Cancel}
                </Button>
                {!loading ? (
                  <Button variant="contained" sx={{ mt: 2 }} type="submit">
                    {STRINGS.Submit}
                  </Button>
                ) : (
                  <Button
                    disabled
                    variant="contained"
                    sx={{
                      mt: 2,
                    }}
                  >
                    <CircularProgress size={18} />
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default ApplyJobModal;
