import {
  Card,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useGetAllMeetingsQuery } from "../../redux/Hire/candidateMeetingApi";
import ScheduleCall from "./ScheduleCall";
import ScheduleCallDetails from "./ScheduleCallDetails";
import ScheduleCallDelete from "./ScheduledCallDelete";

const ScheduledCallView = ({
  candidateId,
  candidateDetail,
  clientName,
  jobId,
  roleName,
}) => {
  const { data, isFetching, isError, refetch } = useGetAllMeetingsQuery({
    candidateId,
  });

  const [showReschedule, setShowReschedule] = useState(false);
  const [showDeleteReason, setShowDeleteReason] = useState(false);

  const openDeleteReason = useCallback(() => {
    setShowReschedule(false);
    setShowDeleteReason(true);
  }, []);
  const closeDeleteReason = useCallback(() => {
    setShowDeleteReason(false);
  }, []);

  const openReschedule = useCallback(() => {
    setShowReschedule(true);
  }, []);
  const closeReschedule = useCallback(() => {
    setShowReschedule(false);
  }, []);

  const closeDeleteReasonAndOpenReschedule = useCallback(() => {
    setShowDeleteReason(false);
    setShowReschedule(true);
  }, []);
  useEffect(() => {
    refetch();
    closeReschedule();
  }, [candidateId, closeReschedule, refetch]);

  const alert = useAlert();

  useEffect(() => {
    if (isError) {
      alert.error("Error fetching interview details");
    }
  }, [alert, isError]);

  return (
    <Card
      className="tbl-card"
      sx={{
        minWidth: 275,
        marginBottom: 2,
        marginTop: 2,
        boxShadow: 10,
        width: "100%",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: "#ffffff",
          paddingLeft: "1.5rem",
          paddingBottom: "0.5rem",
          paddingTop: "0.5rem",
          justifyContent: "flex-start",
          minHeight: 52,
        }}
      >
        <Grid item xs={11} display={"flex"} justifyContent={"space-between"}>
          <Typography variant="subtitle2" component="div">
            Schedule call with candidate
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid
        p={"1.5rem"}
        container
        flexDirection={"column"}
        alignItems={"center"}
        className="tbl-body"
        gap={2}
      >
        {isFetching && <CircularProgress size={36}></CircularProgress>}
        {(data?.data.length || 0) > 0 && !isFetching && (
          <ScheduleCallDetails
            meetingDetails={data?.data[0]}
            openReschedule={openReschedule}
            openDeleteReason={openDeleteReason}
          ></ScheduleCallDetails>
        )}
        {((data?.data.length || 0) === 0 || showReschedule) &&
          !showDeleteReason &&
          !isFetching && (
            <ScheduleCall
              candidateId={candidateDetail?.candidateId}
              candidateName={candidateDetail?.fullName}
              clientId={""}
              clientName={clientName}
              jobId={jobId}
              roleName={roleName}
              candidateEmail={candidateDetail?.email}
              meetingDetails={data?.data[0]}
              closeReschedule={closeReschedule}
            ></ScheduleCall>
          )}

        {showDeleteReason && (
          <ScheduleCallDelete
            closeDeleteReason={closeDeleteReason}
            meetingDetails={data?.data[0]}
            closeDeleteReasonAndOpenReschedule={
              closeDeleteReasonAndOpenReschedule
            }
          />
        )}
      </Grid>
    </Card>
  );
};

export default memo(ScheduledCallView);
