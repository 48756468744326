import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../services/service";
const BASE_URL_RECRUITLY = process.env.REACT_APP_RECRUITLY_URL;

type Candidate = {
  id: string;
};

type CandidateListResponse = {
  data: {
    candidates: Array<Candidate>;
    filter: {
      count: number;
      pageNumber: number;
      pageSize: number;
    };
  };
  message: string;
  successfull: boolean;
  status: number;
};

type CandidateListRequest = {
  JobRef: string;
  JobPipeLineCode: string;
  PageNumber: number;
  PageSize: number;
  removeRejected: boolean;
};

type AddCandidateResponse = {};
type AddCandidateRequest = {
  Id?: number;
  candidateId?: string;
  reference?: string;
  fullName?: string;
  email?: string;
  mobile?: string;
  inPipeline?: boolean;
  dob?: string;
  availableImmediately?: boolean;

  //address //1st
  cityName?: string;
  regionName?: string;
  countryCode?: string;
  countryName?: string;
  latitude?: string;
  longitude?: string;

  //employment //1st
  companyName?: string;
  startDate?: string;
  jobTitle?: string;
  salary?: string;
  jobType?: string;

  //education
  startYear?: number;
  startMonth?: number;
  endYear?: number;
  endMonth?: number;
  grade?: string;
  major?: string;
  organisation?: string;
  comments?: string;
  textCv?: string;
  cvSubmitted?: boolean;
  uniqueId?: number;

  // pipelines
  jobReference?: string;
  pipelineId?: string;
  status?: string;
  subStatus?: string;
  rejected?: boolean;
  placed?: boolean;
  hasInternalCV?: boolean;

  yearsOfExperience?: number;
  source?: string;
  client?: string;
};

type GetCandidateResponse = {
  data: AddCandidateRequest;
  message: string;
  success: boolean;
  status: Number;
};
type GetCandidateRequest = {
  CandidateId: string;
};

type GetCVFromRecruitlyRequest = {
  CandidateId: string;
};

type GetCVFromRecruitlyResponse = {
  data: {
    html: string;
  };
  message: string;
  success: boolean;
  status: Number;
};

export const candidateListApi = createApi({
  reducerPath: "candidateList",
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL_RECRUITLY || "",
  }),
  tagTypes: ["candidateList"],
  endpoints: (builder) => ({
    getCandidateList: builder.query<
      CandidateListResponse,
      CandidateListRequest
    >({
      query: (request) => ({
        url: "getCandidateListPerStageByJobRef",
        method: "POST",
        body: request,
      }),
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        return `${endpointName}?jobId=${queryArgs.JobRef}&pipelineCode=${queryArgs.JobPipeLineCode}`;
      },
      providesTags: (result, error, arg) => [
        { type: "candidateList", id: `${arg.JobRef}-${arg.JobPipeLineCode}` },
      ],
      onQueryStarted(arg, api) {
        if (arg.PageNumber === 0)
          api.updateCachedData((candidateResponse) => {
            candidateResponse.data.candidates = [];
          });
      },

      merge: (currentCache, response) => {
        return {
          ...currentCache,
          data: {
            ...currentCache.data,
            candidates: currentCache.data.candidates.concat(
              response.data.candidates || [],
            ),
          },
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.JobRef !== previousArg?.JobRef ||
          currentArg?.JobPipeLineCode !== previousArg?.JobPipeLineCode ||
          currentArg?.PageNumber !== previousArg?.JobPipeLineCode
        );
      },
    }),

    addCandidate: builder.mutation<AddCandidateResponse, AddCandidateRequest>({
      query: (request) => ({
        url: "addCandidate",
        method: "POST",
        body: request,
      }),
      invalidatesTags: (result, error, req) => [
        { type: "candidateList", id: `${req.jobReference}-${req.status}` },
      ],
    }),
    getCandidate: builder.query<GetCandidateResponse, GetCandidateRequest>({
      query: (request) => ({
        url: "getCandidateById",
        method: "POST",
        body: request,
      }),
    }),
    getCandidateCVfromRecruitly: builder.query<
      GetCVFromRecruitlyResponse,
      GetCVFromRecruitlyRequest
    >({
      query: (request) => ({
        url: "getCandidateCVDetails",
        method: "post",
        body: request,
      }),
    }),
  }),
});

export const {
  useGetCandidateListQuery,
  useAddCandidateMutation,
  useGetCandidateQuery,
  useGetCandidateCVfromRecruitlyQuery,
} = candidateListApi;
