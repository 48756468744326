import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { CLOSED_MENU, OPEN_MENU, TASK_MENU } from "../constants";
import { usePermissions } from "../../../utils/permission";

const RowMenu = ({
  isOpenRequest = true,
  isTaskItem = false,
  isOnHoldRequest = false,
  isClosedRequest = false,
  onMenuClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { Task_DeleteTask, Task_AddComments } = usePermissions();
  const [menuItems, setMenuItems] = useState<{ id: string; name: string }[]>(
    [],
  );

  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClick = (type) => {
    onMenuClick(type);
    handleClose();
  };

  useEffect(() => {
    if (isOpenRequest) {
      setMenuItems(OPEN_MENU);
    } else if (isOnHoldRequest) {
      setMenuItems(CLOSED_MENU);
    } else if (isClosedRequest) {
      setMenuItems(CLOSED_MENU);
    } else if (isTaskItem) {
      const modified_Task_Menu = TASK_MENU.filter((item) => {
        if (Task_DeleteTask) return true;
        if (!Task_AddComments && item.id === "addComment") return false;
        if (!Task_DeleteTask && item.id === "delete") return false;
        return true;
      });
      setMenuItems(modified_Task_Menu);
    }
  }, [isOpenRequest, isOnHoldRequest, isClosedRequest, isTaskItem]);

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.id} onClick={() => onClick(item.id)}>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default RowMenu;
