import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";

import {
  Box,
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import _ from "lodash";
import Moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import RowSkeleton from "../../shared/components/Skeletons/RowSkeleton";
import { formatDate } from "../../utils/dateFormatter";
import { usePermissions } from "../../utils/permission";
import { leaveAction } from "./Approvals.helper";

export default function ApprovalStages(props) {
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "70%" : "50%",
    bgcolor: "background.paper",
    border: "1px solid #000",
    p: 4,
    boxShadow: "1px 4px 20px rgba(132, 132, 132, 0.2)",
    borderRadius: "10px",
  };
  const { leaveActionPending } = useSelector(
    //@ts-ignore
    (state) => state.approval,
  );

  const dispatch = useDispatch();
  const { approvalDetail } = props;
  const [reason, setReason] = useState("");
  const [showReason, setShowReason] = useState(false);
  const [showErrorReason, setShowErrorReason] = useState(false);
  const { Leave_ApproveOrReject } = usePermissions();

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12} sm={6} md={6}>
        <Grid container xs={12} spacing={2}>
          <Grid item xs={isMobile ? 2 : 1}>
            <Box
              sx={{
                width: 30,
                height: 30,
                borderRadius: 15,
                backgroundColor: "#00E08C",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CheckIcon sx={{ color: "#ffffff" }} />
            </Box>
          </Grid>
          <Grid item xs={isMobile ? 10 : 11}>
            <Typography variant="h6" sx={{ pb: 1 }}>
              {"Candidate applied"}
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", pb: 1 }}
            >
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {"Applied on"}
              </Typography>
              <Typography variant="body1">
                {Moment(approvalDetail?.dateOfRequest, "DD/MM/YYYY").format(
                  "ll",
                )}
              </Typography>
            </Box>
            {approvalDetail?.reasonforleave && (
              <>
                <Grid item xs={12} p={0}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Reason
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {approvalDetail?.reasonforleave}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        {!leaveActionPending &&
          approvalDetail?.approvalStatus === "Pending" && (
            <Grid container xs={12} spacing={2} sx={{ pl: isMobile ? 0 : 4 }}>
              <Grid item xs={isMobile ? 2 : 1}>
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 15,
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <img
                    src="/images/pending_approve.png"
                    style={{ height: 30, width: 30 }}
                    alt="reset"
                  />
                </Box>
              </Grid>
              {Leave_ApproveOrReject && (
                <Grid item xs={isMobile ? 10 : 11}>
                  <Typography variant="h6" sx={{ pb: 1 }}>
                    {"Manager  approval awaiting"}
                  </Typography>
                  <Box sx={{ display: "flex", pb: 1, mt: 2 }}>
                    <Button
                      variant="contained"
                      sx={{ marginRight: 5, backgroundColor: "#00E08C" }}
                      onClick={() => {
                        const request = {
                          leaveRecordID: approvalDetail?.leaveRecordId,
                          status: "1",
                          remark: "",
                          id: approvalDetail?.id,
                        };
                        leaveAction(dispatch, request);
                      }}
                    >
                      {"Accept"}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "#E61A1A" }}
                      onClick={() => setShowReason(true)}
                    >
                      {"Reject"}
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>
          )}
        {leaveActionPending && approvalDetail?.approvalStatus === "Pending" && (
          <RowSkeleton rowHeight={100} />
        )}
        {approvalDetail?.approvalStatus === "Rejected" && (
          <Grid container xs={12} spacing={2} sx={{ pl: isMobile ? 0 : 4 }}>
            <Grid item xs={isMobile ? 2 : 1}>
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  borderRadius: 15,
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <img
                  src="/images/reject.png"
                  style={{ height: 35, width: 35 }}
                  alt="reset"
                />
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 10 : 11}>
              <Typography variant="h6" sx={{ pb: 1 }}>
                {"Manager rejected leave request"}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  pb: 1,
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6">{"Rejected on"}</Typography>
                <Typography variant="body1">
                  {formatDate(
                    approvalDetail?.leaveApproverRejectiondata
                      ?.approvedRejectedTime,
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  pb: 1,
                }}
              >
                <Typography variant="h6">{"Reason"}</Typography>
                <Typography variant="body1">
                  {approvalDetail?.leaveApproverRejectiondata?.comment}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
        {approvalDetail?.approvalStatus === "Approved" && (
          <Grid container xs={12} spacing={2} sx={{ pl: isMobile ? 0 : 4 }}>
            <Grid item xs={isMobile ? 2 : 1}>
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  borderRadius: 15,
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <img
                  src="/images/approved.png"
                  style={{ height: 30, width: 30 }}
                  alt="reset"
                />
              </Box>
            </Grid>
            <Grid item xs={isMobile ? 10 : 11}>
              <Typography variant="h6" sx={{ pb: 1 }}>
                {"Manager approved leave request"}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  pb: 1,
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6">{"Approved on"}</Typography>
                <Typography variant="body1">
                  {Moment(
                    approvalDetail?.leaveApproverRejectiondata
                      ?.approvedRejectedTime,
                    "DD-MM-YYYY hh:mm A",
                  ).format("ll")}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={showReason}
        onClose={() => {
          setShowReason(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
            Mention reason
          </Typography>
          <TextField
            margin="normal"
            fullWidth
            multiline
            label={`Enter Reason`}
            name="reason"
            variant="outlined"
            autoComplete="off"
            value={reason}
            onChange={(e) => {
              setReason(e.target.value);
              setShowErrorReason(false);
            }}
            inputProps={{
              style: {
                minHeight: 150,
                maxHeight: 150,
              },
            }}
          />
          {showErrorReason && (
            <Typography variant="caption" sx={{ color: "red" }}>
              {"Please enter the reason"}
            </Typography>
          )}
          <Box
            sx={{ display: "flex", pb: 1, pt: 2, justifyContent: "flex-end" }}
          >
            <Button
              variant="text"
              sx={{ marginRight: 5 }}
              onClick={() => setShowReason(false)}
            >
              {"Cancel"}
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#E61A1A" }}
              onClick={() => {
                if (!_.isEmpty(reason)) {
                  const request = {
                    leaveRecordID: approvalDetail?.leaveRecordId,
                    status: "0",
                    remark: reason,
                    id: approvalDetail?.id,
                  };
                  leaveAction(dispatch, request);
                  setShowReason(false);
                } else {
                  setShowErrorReason(true);
                }
              }}
            >
              {"Reject and save reason"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
}
