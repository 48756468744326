import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../../services/service";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

type JobTitleRequest = {
  titleSearch: string;
};

type JobTitle = {
  jobTitle: string;
  jobId: number;
};

type JobTitleResponse = {
  data: Array<JobTitle>;
};

type SalaryMatrixRequest = {
  title: string;
  experienceLevel: string;
  minExperience?: number;
  maxExperience?: 0;
  region: string;
};

type MatrixResponse = {
  averageSalary: number;
  employeeType: string;
  experienceLevel: string;
  jobMatrixId: number;
  maxExperience: number;
  maxSalary: number;
  minExperience: number;
  minSalary: number;
  region: string;
  title: string;
  yrOfExperience: string;
};

type SalaryMatrixResponse = {
  data: Array<MatrixResponse>;
  message: string;
  status: number;
  success: boolean;
};

export const hireApi = createApi({
  reducerPath: "hireJobRequest",
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL || "",
  }),
  endpoints: (builder) => ({
    getJobTitles: builder.query<JobTitleResponse, JobTitleRequest>({
      query: ({ titleSearch }) => ({
        url: "/getAllJobTitle",
        method: "POST",
        body: {
          titleSearch,
        },
      }),
    }),

    getSalaryMatrix: builder.query<SalaryMatrixResponse, SalaryMatrixRequest>({
      query: ({ title, experienceLevel, region }) => ({
        url: "/getSalaryRangeMatrix",
        method: "POST",
        body: {
          title,
          experienceLevel,
          region,
        },
      }),
    }),
  }),
});

export const { useGetJobTitlesQuery, useLazyGetSalaryMatrixQuery } = hireApi;
