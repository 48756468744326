import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import moment from "moment";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import {
  EventObject,
  useDeleteInterviewMeetingMutation,
} from "../../redux/Hire/candidateMeetingApi";
import FormikInput from "../../shared/components/FormikInput";
import { userAccess } from "../../utils/CommonFunction";

const initialValues = {
  deletedReason: "",
};

const validationSchema = yup.object({
  deletedReason: yup.string().required("Reason is required"),
});

type Props = {
  meetingDetails?: EventObject;
  closeDeleteReason: () => void;
  closeDeleteReasonAndOpenReschedule: () => void;
};

const ScheduleCallDelete: FC<Props> = ({
  meetingDetails,
  closeDeleteReason,
  closeDeleteReasonAndOpenReschedule,
}) => {
  const [deleteMeetingApi, { isLoading, isSuccess }] =
    useDeleteInterviewMeetingMutation();

  useEffect(() => {
    if (isSuccess) {
      closeDeleteReason();
    }
  }, [closeDeleteReason, isSuccess]);

  const { accessToken } = useSelector(
    (state) =>
      //@ts-ignore
      state.login,
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(data) => {
        deleteMeetingApi({
          deletedAt: moment().toISOString(),
          deletedBy: userAccess().userId,
          deletedReason: data.deletedReason,
          eventId: meetingDetails?.eventId || "",
          interviewId: meetingDetails?.id || 0,
          token: accessToken,
        });
      }}
    >
      {(formikProps) => (
        <Form
          style={{
            width: "100%",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <FormikInput
                label="Mention reason for your action"
                name="deletedReason"
                formikProps={formikProps}
                options={undefined}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid
            container
            mt={4}
            mb={3}
            justifyContent={"space-between"}
            alignItems={"center"}
            bgcolor={"#FAF9F9"}
            height={"60px"}
            px={2}
          >
            <Grid item>
              <Typography variant="body2" fontWeight={"600"}>
                You are deleting the call with the candidate.
              </Typography>
              <Typography variant="body2" fontWeight={"600"}>
                Would you like to reschedule the call
              </Typography>
            </Grid>
            <Grid item>
              <Button
                type="button"
                variant="outlined"
                onClick={closeDeleteReasonAndOpenReschedule}
              >
                Reschedule
              </Button>
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ mt: 3, mb: 2 }}
            gap={2}
          >
            {meetingDetails ? (
              <Button type="button" sx={{ mt: 2 }} onClick={closeDeleteReason}>
                Cancel
              </Button>
            ) : null}

            <Button
              type="submit"
              disabled={isLoading}
              variant="contained"
              sx={{ mt: 2 }}
            >
              {isLoading ? (
                <CircularProgress color="inherit" size={20}></CircularProgress>
              ) : (
                "Save Reason"
              )}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ScheduleCallDelete;
