import React from "react";

import {
  Typography,
  Grid,
  Box,
  Card,
  Divider,
  useMediaQuery,
} from "@mui/material";
import Moment from "moment";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { getBase64Download } from "./People.helper";
import TextSkeleton from "../../shared/components/Skeletons/TextSkeleton";
import { STRINGS } from "../../Strings";
import { useSelector } from "react-redux";
import { dateFormat } from "../../Constants";

const ViewMember: React.FunctionComponent<any> = (props) => {
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const { viewSkills, headerColor = "#fffaea" } = props;
  const { details } = useSelector(
    //@ts-ignore
    (state) => state.people,
  );

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12} sm={6} md={6}>
        <Card
          sx={{
            marginBottom: 3,
            marginTop: 2,
            boxShadow: 10,
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              backgroundColor: headerColor,
              paddingLeft: "1.5rem",
              paddingBottom: "0.5rem",
              paddingTop: "0.5rem",
              justifyContent: "flex-start",
              minHeight: 52,
            }}
          >
            <Grid item xs={8}>
              <Typography variant="subtitle2" component="div">
                {STRINGS.PersonalInformation}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Box
            className="tbl-body"
            sx={{
              padding: "1.5rem",
            }}
          >
            <Grid container spacing={2} sx={{ display: "flex" }}>
              <Grid item xs={6}>
                <Typography variant="body2" component="div">
                  {STRINGS.PersonalNumber}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {props.isMemLoading ? (
                  <TextSkeleton />
                ) : (
                  <Typography variant="body2" component="div">
                    {details?.personalMobileNumber}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", paddingTop: "1.0rem" }}
            >
              <Grid item xs={6}>
                <Typography variant="body2" component="div">
                  {STRINGS.PersonalEmailId}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {props.isMemLoading ? (
                  <TextSkeleton />
                ) : (
                  <Typography variant="body2" component="div">
                    {details?.personalEmailID}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        </Card>
        <Card
          sx={{
            marginBottom: isMobile ? 0 : 3,
            marginTop: 2,
            boxShadow: 10,
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              backgroundColor: headerColor,
              paddingLeft: "1.5rem",
              paddingBottom: "0.5rem",
              paddingTop: "0.5rem",
              justifyContent: "space-between",
              minHeight: 52,
              paddingRight: 2,
            }}
          >
            <Grid item>
              <Typography variant="subtitle2" component="div">
                {"Job request"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" component="div">
                {"Request date"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" component="div">
                {"Start date"}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Box
            className="tbl-body"
            sx={{
              padding: "1.5rem",
            }}
          >
            {details?.workExperiences?.map((work) => {
              return (
                <Grid
                  container
                  spacing={2}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid item>
                    {props.isMemLoading ? (
                      <TextSkeleton />
                    ) : (
                      <Typography variant="body2" component="div">
                        {work?.jobtitle}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    {props.isMemLoading ? (
                      <TextSkeleton />
                    ) : (
                      <Typography variant="body2" component="div">
                        {work?.todate
                          ? Moment(work?.todate).format(dateFormat)
                          : "-"}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    {props.isMemLoading ? (
                      <TextSkeleton />
                    ) : (
                      <Typography variant="body2" component="div">
                        {work?.fromDate
                          ? Moment(work?.fromDate).format(dateFormat)
                          : "-"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Card
          sx={{
            marginBottom: 3,
            marginTop: isMobile ? 0 : 2,
            boxShadow: 10,
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              backgroundColor: headerColor,
              paddingLeft: "1.5rem",
              paddingBottom: "0.5rem",
              paddingTop: "0.5rem",
              justifyContent: "flex-start",
              minHeight: 52,
            }}
          >
            <Grid item xs={8}>
              <Typography variant="subtitle2" component="div">
                {STRINGS.WorkInformation}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Box
            className="tbl-body"
            sx={{
              padding: "1.5rem",
            }}
          >
            <Grid container spacing={2} sx={{ display: "flex" }}>
              <Grid item xs={6}>
                <Typography variant="body2" component="div">
                  {STRINGS.JobTitle}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {props.isMemLoading ? (
                  <TextSkeleton />
                ) : (
                  <Typography variant="body2" component="div">
                    {details.jobTitle}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", paddingTop: "1.0rem" }}
            >
              <Grid item xs={6}>
                <Typography variant="body2" component="div">
                  {STRINGS.Experience}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {props.isMemLoading ? (
                  <TextSkeleton />
                ) : (
                  <Typography variant="body2" component="div">
                    {details.experience}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", paddingTop: "1.0rem" }}
            >
              <Grid item xs={6}>
                <Typography variant="body2" component="div">
                  {STRINGS.Salary}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {props.isMemLoading ? (
                  <TextSkeleton />
                ) : (
                  details.salaryPA && (
                    <Typography variant="body2" component="div">
                      {`$${details.salaryPA}`}
                    </Typography>
                  )
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", paddingTop: "1.0rem" }}
            >
              <Grid item xs={6}>
                <Typography variant="body2" component="div">
                  {STRINGS.JoiningDate}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {props.isMemLoading ? (
                  <TextSkeleton />
                ) : (
                  <Typography variant="body2" component="div">
                    {details.joiningDate}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", paddingTop: "1.0rem" }}
            >
              <Grid item xs={6}>
                <Typography variant="body2" component="div">
                  {STRINGS.Skills}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {props.isMemLoading ? (
                  <TextSkeleton />
                ) : (
                  <Typography variant="body2" component="div">
                    {viewSkills}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", paddingTop: "1.0rem" }}
            >
              <Grid item xs={6}>
                <Typography variant="body2" component="div">
                  {STRINGS.Resume}
                </Typography>
              </Grid>
              {details.file && (
                <Grid item xs={5}>
                  {props.isMemLoading ? (
                    <TextSkeleton />
                  ) : (
                    <Typography variant="body2" component="div">
                      {details.fileName}
                    </Typography>
                  )}
                </Grid>
              )}
              {details.file && (
                <Grid item xs={1}>
                  {props.isMemLoading ? (
                    <TextSkeleton />
                  ) : (
                    <FileDownloadOutlinedIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        getBase64Download(details.fileName, details.file);
                      }}
                      style={{ color: "#002DFF" }}
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ViewMember;
