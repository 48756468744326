import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { userAccess } from "../../utils/CommonFunction";

import DataGrid from "../../shared/components/DataGrid/DataGrid";

import { useLocation, useNavigate } from "react-router-dom";
import {
  clientAdmin,
  leaveTypesArray,
  patonaAdmin,
  rowPerPage,
  timesheetPeriods,
  timesheetStatusArray,
} from "../../Constants";

import _ from "lodash";
import MobileSkeleton from "../../shared/components/Skeletons/MobileSkeleton";
import TableSkeleton from "../../shared/components/Skeletons/TableSkeleton";
import {
  Columns,
  copyToClicpBoard,
  getTimesheetList,
  timesheetRow,
} from "./Timesheet.helper";
import TimesheetListMobile from "./TimesheetListMobile";
import { useStyles } from "./style";
import { usePermissions } from "../../utils/permission";

type ApprovalsProps = {
  getUsersType?: string;
  staticCompany?: string;
  back?: (boolean) => void;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

const Timesheet: React.FunctionComponent<ApprovalsProps> = (props) => {
  const { getUsersType = patonaAdmin } = props;
  const classes = useStyles();
  const alert = useAlert();
  const location: any = useLocation();
  const { dashboardFilter = "" } = location?.state || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const skeleton = isMobile ? <MobileSkeleton /> : <TableSkeleton />;
  const now = new Date();
  const { Timesheet_ListView } = usePermissions();

  const [leaveType, setLeaveType] = React.useState<string[]>(leaveTypesArray);
  const [leavePeriod, setLeavePeriod] = React.useState<string>("All");
  const [filterDisabled, setFilterDisabled] = React.useState<boolean>(false);

  const [searched, setSearched] = useState<string>("");
  const [leaveStatus, setLeaveStatus] = React.useState<string>("All");

  const [currentView, setCurrentView] = useState("list");
  const [anchorEditColumnEl, setAnchorEditColumnEl] =
    React.useState<null | HTMLElement>(null);
  const openEditCoulmn = Boolean(anchorEditColumnEl);
  const handleEditColumnClose = () => {
    setAnchorEditColumnEl(null);
  };

  const {
    isTimesheetListLoading,
    timesheetList,
    timesheetListError,
    isPaging,
    page,
    timesheetPageSize,
    timesheetCurrentPage,
  } = useSelector(
    //@ts-ignore
    (state) => state.timesheet,
  );

  useEffect(() => {
    if (!_.isEmpty(dashboardFilter)) {
      let payload = {
        load: 0,
        timesheetApprovalStatus: ["approved"],
        period: "All",
        pageSize: timesheetPageSize ? timesheetPageSize : rowPerPage,
        isDesc: false,
      };

      if (dashboardFilter === "Pending") {
        payload = {
          ...payload,
          timesheetApprovalStatus: ["Pending"],
        };
      }

      getTimesheetList(dispatch, payload);
    }
  }, [dashboardFilter]);

  useEffect(() => {
    const payload = { load: 0 };
    getTimesheetList(dispatch, payload);

    return () => {
      setMobileUserList([]);
    };
  }, []);

  const handleLeaveStatusChange = (
    event: SelectChangeEvent<typeof leaveStatus>,
  ) => {
    const {
      target: { value },
    } = event;
    // setLeaveStatus(
    //   // On autofill we get a stringified value.
    //   typeof value === "string" ? value.split(",") : value
    // );
    setLeaveStatus(value);
  };

  const handleLeavePeriodChange = (
    event: SelectChangeEvent<typeof leavePeriod>,
  ) => {
    setFilterDisabled(false);
    const {
      target: { value },
    } = event;

    // disabled={filterDisabled}
    setLeavePeriod(value);
  };

  const applyFilter = () => {
    const payload = {
      load: 0,
      timesheetApprovalStatus: leaveStatus,
      period: leavePeriod,
      pageSize: timesheetPageSize ? timesheetPageSize : rowPerPage,
      searchValue: searched,
    };
    getTimesheetList(dispatch, payload);
  };

  const resetFilter = () => {
    setLeavePeriod("All");
    setLeaveStatus("All");
    setFilterDisabled(false);
    getTimesheetList(dispatch, {
      load: 0,
    });
  };

  //mobile
  const [Showfloat, setShowfloat] = React.useState<boolean>(false);
  const [mobileUserList, setMobileUserList] = React.useState<any>([]);
  const [mobilePageLoad, setMobilePageLoad] = React.useState<number>(1);

  useEffect(() => {
    if (mobilePageLoad === 1) {
      setMobileUserList(timesheetList);
    } else {
      const newList = [...mobileUserList, ...timesheetList];
      setMobileUserList(newList);
    }
  }, [timesheetList]);

  const loadList = () => {
    const loadPage = mobilePageLoad + 1;

    const payload = {
      load: 0,
      timesheetApprovalStatus: leaveStatus,
      period: leavePeriod,
      pageSize: timesheetPageSize ? timesheetPageSize : rowPerPage,
      searchValue: searched,
      pageNumber: loadPage,
    };
    getTimesheetList(dispatch, payload);
    setMobilePageLoad(loadPage);
    if (loadPage > 1) {
      setShowfloat(true);
    }
  };

  return (
    <>
      <Grid container component="main">
        <Container maxWidth="xl">
          {/* Search Bar Header */}

          <>
            <Box
              mt={1}
              mb={1}
              className={
                userAccess().client || getUsersType === clientAdmin
                  ? "page-header-pink"
                  : "page-header-blue-timesheet"
              }
            >
              {/* Filter Header */}
              <Grid
                container
                alignItems="center"
                xs={12}
                justifyContent={"space-between"}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ display: isMobile ? "block" : "flex" }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ mr: isMobile ? 0 : 2 }}
                  >
                    <FormControl sx={{ m: 1 }} fullWidth>
                      <InputLabel id="Leave-Status">Status</InputLabel>
                      <Select
                        fullWidth
                        id="Leave-Status"
                        value={leaveStatus}
                        label={"Status"}
                        // renderValue={(selected) => selected.join(", ")}
                        // MenuProps={MenuProps}
                        onChange={handleLeaveStatusChange}
                      >
                        {timesheetStatusArray?.map((status, index) => {
                          return (
                            <MenuItem key={status} value={status}>
                              {/* <Checkbox
                                checked={leaveStatus.indexOf(status) > -1}
                              /> */}
                              {/* <ListItemText primary={status} /> */}
                              {status}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4} md={6}>
                    <FormControl
                      sx={{
                        m: 1,
                      }}
                      fullWidth
                    >
                      <InputLabel id="Period">Period</InputLabel>
                      <Select
                        fullWidth
                        id="Period"
                        variant="outlined"
                        className={classes.select}
                        inputProps={{
                          classes: {
                            icon: classes.icon,
                          },
                        }}
                        value={leavePeriod}
                        label={"Period"}
                        onChange={handleLeavePeriodChange}
                      >
                        {timesheetPeriods?.map((period) => {
                          return (
                            <MenuItem key={period} value={period}>
                              {period}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3} md={1.5}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2}
                    sx={{
                      display: {
                        md: "flex",
                      },
                    }}
                    justifyContent={"space-between"}
                  >
                    <Button
                      variant="contained"
                      sx={{ height: 40 }}
                      onClick={() => {
                        setMobileUserList([]);
                        applyFilter();
                      }}
                    >
                      Filter
                    </Button>
                    <Button variant="text" onClick={resetFilter}>
                      <img
                        src="/images/reset.png"
                        style={{ height: 25, width: 25 }}
                        alt="reset"
                      />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box mt={1} mb={1}>
              <Card>
                <CardContent>
                  {isTimesheetListLoading ? (
                    skeleton
                  ) : isMobile ? (
                    <Box sx={{ mt: 2 }}>
                      <TimesheetListMobile
                        listData={timesheetRow(mobileUserList)}
                        totalPageCount={2}
                        onLoadmore={loadList}
                        currentPage={1}
                        isLoading={false}
                      />
                    </Box>
                  ) : (
                    Timesheet_ListView && (
                      <DataGrid
                        header={Columns}
                        row={timesheetRow(timesheetList)}
                        getDetails={(id) => {
                          console.log(id, "++++id");
                          navigate(`/timesheets/timesheet-details/${id}`);
                        }}
                        showPaging={isPaging}
                        page={page}
                        defaultRowPerPageCount={timesheetPageSize}
                        currentPage={timesheetCurrentPage}
                        rowPerPage={(row) => {
                          const payload = {
                            load: 0,
                            timesheetApprovalStatus: leaveStatus,
                            period: leavePeriod,
                            pageSize: row,
                            searchValue: searched,
                          };
                          getTimesheetList(dispatch, payload);
                        }}
                        getPageList={(page) => {
                          const payload = {
                            load: 0,
                            pageNumber: page,
                            timesheetApprovalStatus: leaveStatus,
                            period: leavePeriod,
                            pageSize: timesheetPageSize,
                            searchValue: searched,
                          };
                          getTimesheetList(dispatch, payload);
                          setMobileUserList([]);
                        }}
                        sortTable={(sort, key) => {
                          const payload = {
                            load: 0,
                            isDesc: sort,
                            columnName: key,
                            timesheetApprovalStatus: leaveStatus,
                            period: leavePeriod,
                            pageSize: timesheetPageSize,
                            searchValue: searched,
                          };
                          getTimesheetList(dispatch, payload);
                          setMobileUserList([]);
                        }}
                        search={(value) => {
                          const payload = {
                            timesheetApprovalStatus: leaveStatus,
                            period: leavePeriod,
                            pageSize: timesheetPageSize,
                            searchValue: value,
                          };
                          getTimesheetList(dispatch, payload);
                          setMobileUserList([]);
                        }}
                      />
                    )
                  )}
                </CardContent>
              </Card>
            </Box>
          </>

          <Menu
            id="menu"
            anchorEl={anchorEditColumnEl}
            keepMounted
            open={openEditCoulmn}
            onClose={handleEditColumnClose}
            onClick={handleEditColumnClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              disabled={currentView === "calendar"}
              onClick={() => {
                setCurrentView("calendar");
              }}
            >
              Calendar View
            </MenuItem>
            <MenuItem
              disabled={currentView === "list"}
              onClick={() => {
                setCurrentView("list");
                getTimesheetList(dispatch, {
                  load: 0,
                  leaveTypeList: leaveType,
                  timesheetApprovalStatus: leaveStatus,
                });
              }}
            >
              List View
            </MenuItem>
            <MenuItem
              onClick={() => {
                copyToClicpBoard(alert);
              }}
            >
              Share Calendar
            </MenuItem>
          </Menu>
        </Container>
      </Grid>
    </>
  );
};

export default Timesheet;
