import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Fab from "@mui/material/Fab";
import Menu from "@mui/material/Menu";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { STRINGS } from "../../Strings";
import { editMember } from "../../redux/People/peopleSlice";
import DataGrid from "../../shared/components/DataGrid/DataGrid";
import MobileDataGrid from "../../shared/components/DataGrid/MobileDataGrid";
import Search from "../../shared/components/Search/Search";
import MobileSkeleton from "../../shared/components/Skeletons/MobileSkeleton";
import TableSkeleton from "../../shared/components/Skeletons/TableSkeleton";
import { TabPanel } from "../../shared/components/TabPanel/TabPanel";
import { userAccess } from "../../utils/CommonFunction";
import { usePermissions } from "../../utils/permission";
import DeleteMemberPopUp from "./DeleteMemberPopUp";
import {
  deletedMemberGridRow,
  disableMember,
  enableMember,
  filterMemberList,
  getAllDeleteMemberList,
  getAllMemberList,
  getMemberById,
  getSelectPageDeleteMemberList,
  getSelectPageRowList,
  imageReturnFromZoho,
  memberDeletedGridHeader,
  memberGridHeader,
  memberGridRow,
  removeMember,
  restoreMember,
  scrollToTop,
} from "./People.helper";
import { styles } from "./PeopleStyle";

const defaultMemberAction = [
  { name: "Edit", action: "getDetails", display: true },
  {
    name: "Disable",
    action: "disableTeamMember",
    display: true,
  },
  { name: "Enable", action: "enableTeamMember", display: false },
  {
    name: "Remove team member",
    action: "deleteMember",
    display: true,
  },
];

const defaultMemberDeletedAction = [
  { name: "Edit", action: "getDetails", display: true },
  { name: "Disable", action: "disableTeamMember", display: true },
  { name: "Enable", action: "enableTeamMember", display: false },
  { name: "Move to team member", action: "moveToTeamMember", display: true },
];

const PeopleList: React.FunctionComponent = () => {
  const {
    isMemLoading,
    members,
    isPaging,
    page,
    totalMember,
    totalDeletedMember,
    deletedPage,
    isDeletedLoading,
    deletedMember,
    isDeletedPaging,
    memberPageSize,
    memberCurrentPage,
    deletedMemberPageSize,
    deletedMemberCurrentPage,
    details,
  } = useSelector(
    //@ts-ignore
    (state) => state.people,
  );

  const classes = styles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const [showAddModal, setShowAddModal] = useState(false);
  const [deleteMemberId, setDeleteMemberId] = useState<number>(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tabValue, setValue] = useState(0);
  const [clients, setClients] = React.useState<any>([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const [memberAction, setMemberAction] =
    React.useState<any>(defaultMemberAction);
  const [memberDeletedAction, setMemberDeletedAction] = React.useState<any>(
    defaultMemberDeletedAction,
  );
  const [mobilePageLoad, setMobilePageLoad] = React.useState<number>(1);
  const [mobileDeletePageLoad, setMobileDeletePageLoad] =
    React.useState<number>(1);
  const [Showfloat, setShowfloat] = React.useState<boolean>(false);
  const [showMobileSearch, setShowMobileSearch] =
    React.useState<boolean>(false);
  const [isDesc, setIsDesc] = React.useState<boolean>(false);
  const [ShowDeletedfloat, setShowDeletedfloat] =
    React.useState<boolean>(false);
  const [mobileMemberList, setMobileMemberList] = React.useState<any>([]);
  const [mobileDeletedMemberList, setMobileDeletedMemberList] =
    React.useState<any>([]);
  const [selectedClients, setSelectedClients] = React.useState<any>("All");
  const [anchorEditColumnEl, setAnchorEditColumnEl] =
    React.useState<null | HTMLElement>(null);
  const openEditCoulmn = Boolean(anchorEditColumnEl);

  const { TeamMember_AddMember } = usePermissions();
  const handleEditColumnClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEditColumnEl(event.currentTarget);
  };
  const handleEditColumnClose = () => {
    setAnchorEditColumnEl(null);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue) {
      getAllDeleteMemberList(dispatch);
    } else {
      getAllMemberList(dispatch);
    }
    setSelectedClients("All");
    setValue(newValue);
  };
  useEffect(() => {
    getAllMemberList(dispatch);
  }, []);

  useEffect(() => {
    if (mobilePageLoad === 1) {
      setMobileMemberList(members);
    } else {
      const newList = [...mobileMemberList, ...members];
      setMobileMemberList(newList);
    }
  }, [members]);

  useEffect(() => {
    if (mobileDeletePageLoad === 1) {
      setMobileDeletedMemberList(deletedMember);
    } else {
      const newList = [...mobileDeletedMemberList, ...deletedMember];
      setMobileDeletedMemberList(newList);
    }
  }, [deletedMember]);

  const loadList = () => {
    const loadPage = mobilePageLoad + 1;
    getSelectPageDeleteMemberList(loadPage, dispatch);
    setMobilePageLoad(loadPage);
    if (loadPage > 1) {
      setShowfloat(true);
    }
  };

  const loadDeletedList = () => {
    const loadPage = mobileDeletePageLoad + 1;
    getSelectPageDeleteMemberList(loadPage, dispatch);
    setMobileDeletePageLoad(loadPage);
    if (loadPage > 1) {
      setShowDeletedfloat(true);
    }
  };

  const getPageList = (page) => {
    const pageSize = tabValue === 0 ? memberPageSize : deletedMemberPageSize;
    getSelectPageDeleteMemberList(
      page,
      dispatch,
      "",
      tabValue,
      false,
      "",
      pageSize,
    );
  };

  const getSearch = (input) => {
    const pageSize = tabValue === 0 ? memberPageSize : deletedMemberPageSize;
    getSelectPageDeleteMemberList(
      1,
      dispatch,
      input,
      tabValue,
      false,
      "",
      pageSize,
    );
  };

  const getDetails = (id, edit = false, details) => {
    if (!userAccess().isTeamMember) {
      dispatch(editMember(id));
      navigate("/edit-member", {
        state: {
          memberID: id,
          isPatonaTeamMember: details?.isPatonaTeamMember,
          clients: clients,
          edit: edit,
        },
      });
    } else {
      handleModal();
      getMemberById(id, true, dispatch);
    }
  };

  const deleteMember = (id) => {
    setDeleteMemberId(id);
    setShowDeleteModal(true);
  };

  const deleteMemberPermanent = () => {
    const payload = { id: deleteMemberId };
    removeMember(payload, dispatch);
    setShowDeleteModal(false);
  };

  const moveToTeamMember = (id) => {
    const payload = { id: id };
    restoreMember(payload, dispatch);
  };

  const filerTeamMember = (clientId) => {
    filterMemberList(clientId, tabValue, dispatch);
  };

  const disableTeamMember = (id) => {
    const payload = { id: id };
    disableMember(payload, dispatch);
  };
  const enableTeamMember = (id) => {
    const payload = { id: id };
    enableMember(payload, dispatch);
  };

  const skeleton = isMobile ? <MobileSkeleton /> : <TableSkeleton />;

  const enableDisableRowMember = (value) => {
    const updateMenu = memberAction?.map((val) => {
      if (userAccess().client) {
        if (val.name === "Disable") {
          val.display = value ? false : true;
        } else if (val.name === "Enable") {
          val.display = value ? true : false;
        }
      } else {
        val.display = val.name === "Edit" ? true : false;
      }

      return val;
    });
    setMemberAction(updateMenu);
  };

  const enableDisableRowDeletedMember = (value) => {
    const updateMenu = memberDeletedAction?.map((val) => {
      if (val.name === "Disable") {
        val.display = value ? false : true;
      } else if (val.name === "Enable") {
        val.display = value ? true : false;
      }
      return val;
    });
    setMemberDeletedAction(updateMenu);
  };

  const sortMember = (sort, key) => {
    const pageSize = tabValue === 0 ? memberPageSize : deletedMemberPageSize;
    getSelectPageDeleteMemberList(
      1,
      dispatch,
      "",
      tabValue,
      sort,
      key,
      pageSize,
    );
  };

  const handleModal = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {userAccess().isTeamMember && (
        <Grid container>
          <Grid item xs={12} sm={4} ml={3} mr={3} mt={2}>
            <Search
              isDisabled={true}
              placeHolder="Search by name, client or job title"
              sx={{
                "input:disabled": {
                  cursor: "not-allowed",
                },
              }}
            />
          </Grid>
        </Grid>
      )}
      {!userAccess().isTeamMember && (
        <Box
          mt={1}
          mb={1}
          sx={{ mr: 2, ml: 2 }}
          className={
            isMobile
              ? "mobile-page-header-blue"
              : userAccess().client
                ? "page-header-pink"
                : "page-header-blue"
          }
        >
          <Grid container direction="row" alignItems="center">
            <Grid
              item
              xs={12}
              md={2}
              direction="row"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {isMobile && (
                <Search
                  getSearch={getSearch}
                  closeSearch={() => setShowMobileSearch(!showMobileSearch)}
                />
              )}
              {isMobile && (
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    disableRipple={true}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditColumnClick(e);
                      setIsDesc(!isDesc);
                    }}
                  >
                    <FilterAltIcon />
                  </IconButton>
                </Grid>
              )}
              <Menu
                id="menu"
                anchorEl={anchorEditColumnEl}
                keepMounted
                open={openEditCoulmn}
                onClose={handleEditColumnClose}
                onClick={handleEditColumnClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 15,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {memberGridHeader(userAccess().client)?.map((value) => {
                  return (
                    <MenuItem
                      onClick={() => sortMember(!isDesc, value?.soryKey)}
                    >
                      {value?.name}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Grid>

            {/* @ts-ignore */}
            <Grid item xs={12} md={12} sx={{ display: "flex" }} spacing={2}>
              {!isMobile && <Search getSearch={getSearch} />}

              <DeleteMemberPopUp
                deleteMember={deleteMemberPermanent}
                show={showDeleteModal}
                close={() => setShowDeleteModal(false)}
              />
              {!isMobile && (
                <Grid item xs={12} sm={12} md={12} ml={2}>
                  <FormControl
                    fullWidth
                    sx={{
                      width: "50%",
                    }}
                  >
                    <InputLabel>{STRINGS.employeeType}</InputLabel>
                    <Select
                      label={STRINGS.employeeType}
                      value={selectedClients}
                      onChange={(e) => {
                        setSelectedClients(e.target.value);
                        filerTeamMember(e.target.value);
                      }}
                    >
                      <MenuItem value={"All"}>{"All"}</MenuItem>
                      <MenuItem value={"On Contract"}>{"On Contract"}</MenuItem>
                      <MenuItem value={"Permanent"}>{"Permanent"}</MenuItem>
                      <MenuItem value={"Probation"}>{"Probation"}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {tabValue === 0 && !isMobile && TeamMember_AddMember && (
                <Button
                  sx={{ width: "30%", ml: 1 }}
                  onClick={() =>
                    navigate("/add-member", {
                      state: {
                        clients: clients,
                      },
                    })
                  }
                  variant="contained"
                >
                  {STRINGS.AddNewMember}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      )}

      <Box sx={{ width: "97%", mt: 3, mr: 2, ml: 2 }}>
        {!userAccess().client && isMobile && (
          <Grid item xs={12} sm={12} md={12} sx={{ mr: 4, mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>{STRINGS.employeeType}</InputLabel>
              <Select
                label={STRINGS.employeeType}
                value={selectedClients}
                onChange={(e) => {
                  setSelectedClients(e.target.value);
                  filerTeamMember(e.target.value);
                }}
              >
                <MenuItem value={"All"}>{"All"}</MenuItem>
                <MenuItem value={"On Contract"}>{"On Contract"}</MenuItem>
                <MenuItem value={"Permanent"}>{"Permanent"}</MenuItem>
                <MenuItem value={"Probation"}>{"Probation"}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {userAccess().client && !userAccess().isTeamMember && (
          <Tabs value={tabValue} onChange={handleChange} variant="fullWidth">
            <Tab
              label={STRINGS.CurrentTeamMembers.params({ count: totalMember })}
            />
            <Tab
              label={STRINGS.RemovedTeamMembers.params({
                count: totalDeletedMember,
              })}
              disabled={userAccess().client ? false : true}
            />
          </Tabs>
        )}
      </Box>
      <TabPanel value={tabValue} index={0}>
        {isMemLoading ? (
          skeleton
        ) : isMobile ? (
          <>
            {Showfloat && (
              <Fab
                sx={{
                  position: "fixed",
                  bottom: (theme) => theme.spacing(10),
                  right: (theme) => theme.spacing(3),
                  backgroundColor: "#ccd5fc",
                }}
                onClick={() => scrollToTop(setShowfloat)}
              >
                <ArrowUpwardIcon />
              </Fab>
            )}
            <Box>
              {!userAccess().isTeamMember && (
                <Button
                  disabled={!userAccess().client}
                  sx={{ mt: userAccess().client ? 1 : 0 }}
                  onClick={() => setShowAddModal(true)}
                  variant="contained"
                >
                  {STRINGS.AddNewMember}
                </Button>
              )}
              <MobileDataGrid
                row={memberGridRow(mobileMemberList, userAccess().client)}
                getDetails={getDetails}
                deleteMember={deleteMember}
                actionList={memberAction}
                disableTeamMember={disableTeamMember}
                enableTeamMember={enableTeamMember}
                enableDisableRow={enableDisableRowMember}
              />
              <MemberDetails
                handleModal={handleModal}
                open={open}
                data={details}
              />
            </Box>
          </>
        ) : (
          <>
            <DataGrid
              header={memberGridHeader(userAccess().client)}
              row={memberGridRow(members, userAccess().client)}
              getDetails={getDetails}
              deleteMember={deleteMember}
              actionList={memberAction}
              showPaging={isPaging}
              page={page}
              getPageList={getPageList}
              disableTeamMember={disableTeamMember}
              enableTeamMember={enableTeamMember}
              enableDisableRow={enableDisableRowMember}
              sortTable={sortMember}
              rowPerPage={(row) => {
                getSelectPageRowList(row, dispatch, tabValue);
              }}
              defaultRowPerPageCount={memberPageSize}
              currentPage={memberCurrentPage}
            />
            <MemberDetails
              handleModal={handleModal}
              open={open}
              data={details}
            />
          </>
        )}
        {isPaging && isMobile && mobilePageLoad < page && (
          <Box mt={1} mb={3} textAlign="center">
            <Button
              variant="outlined"
              size="small"
              color="warning"
              onClick={loadList}
            >
              {STRINGS.LoadMore}
            </Button>
          </Box>
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {isDeletedLoading ? (
          skeleton
        ) : isMobile ? (
          <>
            {ShowDeletedfloat && (
              <Fab
                sx={{
                  position: "fixed",
                  bottom: (theme) => theme.spacing(10),
                  right: (theme) => theme.spacing(3),
                  backgroundColor: "#ccd5fc",
                }}
                onClick={() => scrollToTop(setShowDeletedfloat)}
              >
                <ArrowUpwardIcon />
              </Fab>
            )}
            <MobileDataGrid
              row={mobileDeletedMemberList}
              getDetails={getDetails}
              moveToTeamMember={moveToTeamMember}
              actionList={memberDeletedAction}
              disableTeamMember={disableTeamMember}
              enableTeamMember={enableTeamMember}
              enableDisableRow={enableDisableRowMember}
            />
          </>
        ) : (
          <DataGrid
            header={memberDeletedGridHeader()}
            row={deletedMemberGridRow(deletedMember)}
            getDetails={getDetails}
            moveToTeamMember={moveToTeamMember}
            actionList={memberDeletedAction}
            showPaging={isDeletedPaging}
            page={deletedPage}
            getPageList={getPageList}
            disableTeamMember={disableTeamMember}
            enableTeamMember={enableTeamMember}
            enableDisableRow={enableDisableRowDeletedMember}
            sortTable={sortMember}
            rowPerPage={(row) => {
              getSelectPageRowList(row, dispatch, tabValue);
            }}
            defaultRowPerPageCount={deletedMemberPageSize}
            currentPage={deletedMemberCurrentPage}
          />
        )}
        {isDeletedPaging && isMobile && mobileDeletePageLoad < deletedPage && (
          <Box mt={1} mb={3} textAlign="center">
            <Button
              variant="outlined"
              size="small"
              color="warning"
              onClick={loadDeletedList}
            >
              {STRINGS.LoadMore}
            </Button>
          </Box>
        )}
      </TabPanel>
    </Box>
  );
};

export default PeopleList;

const MemberDetails = ({ open, handleModal, data }) => {
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 280 : 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "16px",
    outline: "none",
  };
  const classes = styles();
  return (
    <Modal
      open={open}
      onClose={handleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box className={classes.flexBox}>
          <Box sx={{ display: "flex" }}>
            <Avatar
              alt={data?.firstName}
              sx={{ height: "42px", width: "42px", mr: 2 }}
              src={
                data?.profilePhotoURL
                  ? imageReturnFromZoho(data?.profilePhotoURL)
                  : ""
              }
            />
            <Typography
              sx={{
                fontWeight: 500,
                fontStyle: "Plus Jakarta Sans",
                fontSize: "32px",
              }}
            >{`${data?.firstName} ${data?.lastName}`}</Typography>
          </Box>
          <Box>
            <CloseIcon onClick={handleModal} fontSize="medium" />
          </Box>
        </Box>

        <Box className={classes.flexBox}>
          <Typography className={classes.typographyLabel}>Job Title</Typography>
          <Typography className={classes.typographyContent}>
            {data?.jobTitle ? data?.jobTitle : "-"}
          </Typography>
        </Box>
        <Box className={classes.flexBox}>
          <Typography className={classes.typographyLabel}>
            Work Email
          </Typography>
          <Typography className={classes.typographyContent}>
            {data?.emailAddress ? data?.emailAddress : "-"}
          </Typography>
        </Box>
        <Box className={classes.flexBox}>
          <Typography className={classes.typographyLabel}>
            Experience
          </Typography>
          <Typography className={classes.typographyContent}>
            {data?.experience ? data?.experience : "-"}
          </Typography>
        </Box>
        <Box className={classes.flexBox}>
          <Typography className={classes.typographyLabel}>Skill</Typography>
          <Typography className={classes.typographyContent}>
            {data?.skillSet ? data?.skillSet : "-"}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};
