import { useState, useEffect } from "react";
import axios from "axios";

interface Location {
  county: string;
  city: string;
  state: string;
}

const useGeocoding = (
  latitude?: number,
  longitude?: number,
): Location | null => {
  const [location, setLocation] = useState<Location | null>(null);

  useEffect(() => {
    const fetchLocation = async () => {
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=10&addressdetails=1`;

      try {
        const response = await axios.get(url);
        const address = response.data.address;

        setLocation({
          county: address.county,
          city:
            address.city ||
            address.town ||
            address.village ||
            address.state_district,
          state: address.state,
        });
      } catch (error) {
        console.error("Error fetching the location data", error);
      }
    };

    fetchLocation();
  }, [latitude, longitude]);

  return location;
};

export default useGeocoding;
