import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Skeleton, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { CloseIcon } from "../../shared/components/Icons";
import { useStyles } from "./styles";
import ApplyLeave from "./ApplyLeave/ApplyLeave";
import { applyLeaveType } from "./TeamMemberApprovals.helper";

const ApplyLeaveModal = ({
  open,
  handleOpenClose,
  dateSpecificDataId,
  teamMemberLeaveShortInfo,
  isTeamMemberLeaveShortInfoLoading,
}) => {
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userInfo } = useSelector(
    //@ts-ignore
    (state) => state.login,
  );

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "scroll",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.2)",
    padding: "16px",
    outline: "none",
    width: isMobile ? "300px" : "auto",
    maxWidth: "90vw",
    maxHeight: "90vh",
  };

  useEffect(() => {
    applyLeaveType(dispatch, { email: userInfo.email });
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleOpenClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {dateSpecificDataId !== null ? (
          <ShortInfoPopUp
            isMobile={isMobile}
            handleOpenClose={handleOpenClose}
            classes={classes}
            teamMemberLeaveShortInfo={teamMemberLeaveShortInfo}
            isTeamMemberLeaveShortInfoLoading={
              isTeamMemberLeaveShortInfoLoading
            }
          />
        ) : (
          <ApplyLeave
            isMobile={isMobile}
            handleOpenClose={handleOpenClose}
            classes={classes}
          />
        )}
      </Box>
    </Modal>
  );
};

const ShortInfoPopUp = ({
  classes,
  handleOpenClose,
  isMobile,
  teamMemberLeaveShortInfo,
  isTeamMemberLeaveShortInfoLoading,
}) => {
  const flexJustify = { display: "flex", justifyContent: "space-between" };
  const status = teamMemberLeaveShortInfo?.status;
  const statusColor =
    status === "Approved"
      ? "#00E08C"
      : status === "Rejected"
        ? "#E61A1A"
        : "#F27F0D";
  const approverName = teamMemberLeaveShortInfo?.name;
  const approvedData =
    teamMemberLeaveShortInfo?.approvedRejectedTime?.split(" ")[0];

  return (
    <>
      {!isTeamMemberLeaveShortInfoLoading ? (
        <>
          <Box {...flexJustify} alignItems={"center"} mb={4}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className={classes.leavePopUpHeading}
              sx={{
                color: statusColor,
              }}
            >
              Leave {status}
            </Typography>
            <CloseIcon onClick={handleOpenClose} />
          </Box>
          <Box sx={{ width: isMobile ? "auto" : "415px", display: "grid" }}>
            <Box mb={4}>
              <Box {...flexJustify}>
                <Typography mb={3} className={classes.leavePopUpFonts}>
                  {teamMemberLeaveShortInfo?.dateFromTo.replace(
                    " ",
                    `${" "} To ${" "}`,
                  )}
                </Typography>
                <Typography mb={3}>
                  {teamMemberLeaveShortInfo.leaveType}
                </Typography>
              </Box>
              <Box {...flexJustify}>
                <Box>
                  <Typography className={classes.leavePopUpFonts}>
                    {status} By
                  </Typography>
                  <Typography className={classes.leavePopUpFonts}>
                    {approverName}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    className={classes.leavePopUpFonts}
                    textAlign={"end"}
                  >
                    {status} On
                  </Typography>
                  <Typography className={classes.leavePopUpFonts}>
                    {approvedData}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={{ width: isMobile ? "auto" : "415px", height: "200px" }}>
          <Skeleton animation="wave" height={40} width="100%" />
          <Skeleton animation="wave" height={40} width="100%" />
          <Skeleton animation="wave" height={40} width="100%" />
          <Skeleton animation="wave" height={40} width="100%" />
          <Skeleton animation="wave" height={40} width="100%" />
        </Box>
      )}
    </>
  );
};

export default ApplyLeaveModal;
